/**
 * SocialShare
 *
 * @package frontend-ui
 */
import React, { useContext, useMemo, useRef } from 'react';
import { StringsContext } from '../Contexts/StringsContext';
import { useFocusEffect, useRovingTabIndex, RovingTabIndexProvider } from 'react-roving-tabindex';
import PropTypes from 'prop-types';
import * as ShareButtons from 'react-share';
import { capitalize } from 'lodash';
import { shareLink, uglyLink } from '../../utils/misc';
import getPath from '../../api/relativePath';

/**
 * Base BEM class for elements.
 */
const baseClass = 'social-share';

/**
 * Get url to use in share.
 *
 * @param {String} [url] URL to share
 * @param {Object} [post] Post to share. Prioritized over `url`
 * @param {Number} post.id
 * @param {String} post.type
 * @returns
 */
function getShareUrl(post, url) {
  let shareUrl;

  if (post && post.id && post.type) {
    // Try to get short, ugly post share link
    shareUrl = uglyLink(post.id, post.type);
  }
  if (!shareUrl) {
    // Fallback to pretty post share link if applicable, or the raw link.
    shareUrl = getPath(url).external
      ? url
      : shareLink(url)
  }
  return shareUrl;
}

/**
 * Accessible toolbar containing social share buttons for various platforms.
 */
function SocialShare(props) {
  const strings = useContext(StringsContext);
  const { social: { platforms, prompt_accessible } } = strings;

  // Bail if no social plaforms provided.
  if (!platforms) return false;

  const {
    direction = 'both',
    url = document.location.href,
    title,
    content,
    post
  } = props;

  const shareUrl = getShareUrl(post, url)
  if (!shareUrl) return false;

  const supportedPlatforms = useMemo(() => {
    return platforms.filter(({ platform: { value: slug } }) => Object.prototype.hasOwnProperty.call(ShareButtons, platformToComponent(slug)))
  }, [platforms]);

  return (
    <div role="toolbar" className={`${baseClass}`} aria-label={prompt_accessible}>
      <RovingTabIndexProvider direction={direction}>
        <ul className={`${baseClass}__btn-list`}>
          {/* eslint-disable-next-line no-unused-vars */}
          {supportedPlatforms.map(({ platform: { value: slug, label }, url: _, ...rest }) => (
            <li key={slug} className={`${baseClass}__btn-item`}>
              <SocialButton url={shareUrl} title={title} content={content} platform={{ label, slug }} {...rest} />
            </li>))}
        </ul>
      </RovingTabIndexProvider>
    </div>
  )
}

SocialShare.propTypes = {
  /** Orientation of buttons. Determines which arrow keys are used in roving tabindex. */
  direction: PropTypes.oneOf(['vertical', 'horizontal', 'both']),
  /** Optional URL to share. Defaults to `document.location.href`. */
  url: PropTypes.string,
  /** Optional text for share. Truncated. */
  content: PropTypes.string,
  /** Optional title for share */
  title: PropTypes.string,
  /** Optional post object */
  post: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }),
}

export default SocialShare;

/**
 * Map generic share options to platform-specific react-share props
 *
 * @typedef {'facebook'|'twitter'|'linkedin'} Platform
 *
 * @param {Platform} platform Social platform slug.
 * @param {Object} opts Share options
 * @param {String} opts.title
 * @param {String} opts.content
 * @param {String} opts.url
 */
function mapSocialOpts(platform, opts) {
  const { title, content, url } = opts;

  let message;

  switch (platform) {
    case 'facebook':
      message = [title, content].filter(str => Boolean(str)).join(' — ');

      return {
        quote: message,
        url
      }

    case 'linkedin':
      return {
        summary: content,
        title,
        url
      }

    case 'twitter':
      message = [title, content].filter(str => Boolean(str)).join(' — ');

      return {
        title: message,
        url
      }

    default:
      return {
        url
      }
  }
}

/**
 * Determine name of component for social platform
 *
 * @param {String} slug Platform's slug
 * @returns {String}
 */
function platformToComponent(slug) {
  return `${capitalize(slug)}ShareButton`;
}

/**
 * Button triggering share feature for a social platform.

 * @param {Object} props
 */
const SocialButton = props => {
  const {
    platform: { label, slug },
    prompt,
    prompt_accessible,
    url = document.location.href,
    title,
    content,
    post
  } = props;

  const ShareButton = ShareButtons[platformToComponent(slug)];
  if (!ShareButton) return null;

  const ref = useRef(null);
  const [tabIndex, focused, handleKeydown, rovingHandleClick] = useRovingTabIndex(ref);
  useFocusEffect(focused, ref);

  const shareUrl = getShareUrl(post, url)

  if (!shareUrl) return false;

  return <ShareButton
    windowWidth="600"
    windowHeight="500"
    resetButtonStyle={false}
    ref={ref}
    className={`${baseClass}__btn ${baseClass}__btn--${slug} btn`}
    tabIndex={tabIndex}
    onKeyDown={handleKeydown}
    onClick={rovingHandleClick}
    aria-label={prompt_accessible || label}
    {...mapSocialOpts(slug, { title, content, url: shareUrl })}
  >
    <i className={`${baseClass}__icon icon icon-${slug}`} aria-hidden="true"></i>
    {prompt && <span className={`${baseClass}__prompt`} aria-hidden="true">{prompt}</span>}
  </ShareButton>
}

SocialButton.propTypes = {
  /** Slug of platform, e.g. facebook, linkedin */
  platform: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  /** Text displayed in button */
  prompt: PropTypes.string,
  /** Text for screenreaders */
  prompt_accessible: PropTypes.string.isRequired,
  /** URL to share. Defaults to `document.location.href` */
  url: PropTypes.string,
  /** Optional text for share. Truncated. */
  content: PropTypes.string,
  /** Optional title for share */
  title: PropTypes.string,
  /** Optional post object */
  post: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }),
}
