import React from 'react';
import PropTypes from 'prop-types';
import ParsedWysiwyg from '../../Common/ParsedWysiwyg';
import classNames from 'classnames'

const Columns = ({ width, left, right, gutter }) => {
  if (!left || !right || !width) {
    return null;
	}
	
	let leftWidth = width;
	let rightWidth = 100 - width;

  return (
    <div className={classNames('columns__inner centered', {'columns__inner--gutter': gutter})}>
			<div className="columns__column columns__column--left wysiwyg-content" style={{flexBasis: leftWidth + '%'}}>
				<ParsedWysiwyg htmlString={left} />
			</div>
			<div className="columns__column columns__column--right wysiwyg-content" style={{flexBasis: rightWidth + '%'}}>
				<ParsedWysiwyg htmlString={right} />
			</div>
    </div>
  );
};

Columns.propTypes = {
	gutter: PropTypes.bool,
	width: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
};

export default Columns;
