/**
 * CookiesPage
 *
 * @package ptc-therapeutic
 */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import SkipToMain from '../Common/SkipToMain';
import { Container, Row, Col } from 'reactstrap';
import { Err, PagePropTypes } from '../../utils/misc';
import ErrorMessage from '../Common/ErrorMessage';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { omit as _omit, debounce as _debounce } from 'lodash';
import PageFooter from '../Common/PageFooter';

/**
 * React Component CookiesPage.
 */
const CookiesPage = props => {
  const { acf = {} } = props;
  const { calls_to_action } = acf;

  const [policyReady, setPolicyReady] = useState();
  const policyRef = useRef();
  const policyHtml = useRef();

  useEffect(() => {
    let timeout;
    let observer;

    /**
     * Reformat cookie policy markup.
     */
    function parseHtml() {
      function transform(node, i) {
        let Wrapper;
        let wrapperProps = {};

        // Add class to tables so we can apply custom styles.
        if ('table' === node.name) {
          Wrapper = 'div';
          wrapperProps = {
            className: 'table-wrapper'
          }
        }

        // Remove CookiePro classes/id's so we can override their styles more easily.
        node.attribs = _omit(node.attribs, 'class', 'id');

        return Wrapper ?
          <Wrapper key={i} {...wrapperProps}>{convertNodeToElement(node, 0, transform)}</Wrapper> :
          convertNodeToElement(node, i, transform)
      }

      policyHtml.current = ReactHtmlParser(policyRef.current.innerHTML, { transform });
      setPolicyReady(true);
    }

    /**
     * Get HTML for cookie policy.
     */
    function initCookiePolicy() {
      clearTimeout(timeout);
      window.removeEventListener('ptc.OneTrustGroupsDidUpdate', initCookiePolicy)

      if (!policyRef.current.innerHTML) {
        // Wait for Optanon to finish injecting HTML.
        observer = new MutationObserver(_debounce(() => { observer.disconnect(); parseHtml() }, 250));
        observer.observe(policyRef.current, { childList: true })
        window.Optanon.initializeCookiePolicyHtml();
      } else {
        // It has already finished.
        parseHtml();
      }
    }

    if (window.Optanon) {
      // Optanon SDK is ready, start reformatting cookie policy.
      initCookiePolicy();

    } else {
      // Wait 10 seconds for Optanon SDK to load, then reformat cookie policy or give up.
      window.addEventListener('ptc.OneTrustGroupsDidUpdate', initCookiePolicy);

      timeout = setTimeout(() => {
        window.removeEventListener('ptc.OneTrustGroupsDidUpdate', initCookiePolicy)
        setPolicyReady(new Err({ name: 'Timeout', message: 'The client did not produce a request within the time that the server was prepared to wait.', status: 408 }))
      }, 10000);
    }

    // Cleanup listeners.
    return () => {
      observer && observer.disconnect();
      window.removeEventListener('ptc.OneTrustGroupsDidUpdate', initCookiePolicy);
      clearTimeout(timeout);
    }
  }, [])

  return <>
    <Container className='page-main'>
      <Row className="justify-content-center">
        <Col sm="10">
          <section className="page-content">
            <SkipToMain />
            {policyReady instanceof Error ?
              <ErrorMessage message={policyReady.message} statusText={policyReady.name} {...policyReady} /> :
              policyReady ?
                <div className="wysiwyg-content cookiepro__content">{policyHtml.current}</div> :
                <>
                  <Loading />
                  <div ref={policyRef} id="ot-sdk-cookie-policy" style={{ display: 'none' }} />
                </>
            }
          </section>
        </Col>
      </Row>
    </Container>
    {policyReady && <PageFooter calls_to_action={calls_to_action} />}
  </>
}

CookiesPage.propTypes = {
  ...PagePropTypes,
  isLoading: PropTypes.bool,
}

export default CookiesPage;