/**
 * PDF Grid ACF component.
 *
 * @package ptc-therapeutic
 */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PDF, Language, ArrowDown, Download1 } from '../../Svg';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { match as localeMatch } from '@formatjs/intl-localematcher'


import { StringsContext } from '../../Contexts/StringsContext';
import { sprintf } from 'sprintf-js';

/**
 * React Component PdfGrid.
 */
const PdfGrid = React.forwardRef(function (props, ref) {
  const {
    subtitle: componentSubtitle,
    items,
    className,
  } = props;

  // # Context
  const {
    download: downloadStrings = {}
  } = useContext(StringsContext)

  if (!downloadStrings) return null;

  /** Prompts to download in various languages, keyed by language code */
  const downloadInLanguages = (Array.isArray(downloadStrings.in_language) && 0 < downloadStrings.in_language.length) &&
    downloadStrings.in_language.reduce((acc, { language, string }) => {
      acc[language.replace('_', '-')] = string
      return acc;
    }, {})

  // # Render
  /** Prompt to download file in main language */
  const downloadFile = useMemo(
    () => (downloadStrings.filetype && downloadStrings.filetypes?.pdf) && sprintf(downloadStrings.filetype, { filetype: downloadStrings.filetypes.pdf }),
    [downloadStrings.filetype, downloadStrings.filetypes?.pdf]
  )

  return <ul ref={ref} className={classnames('c-pdf-tile-grid centered', className)}>
    {items.map((item) => {
      const {
        file,
        language_variants
      } = item

      const subtitle = item.subtitle || componentSubtitle
      const title = item.title || file.title

      return <li className="c-pdf-tile o-tile o-tile--with-active" key={file.id}>
        <div className="c-pdf-tile__img-outer o-tile__img-outer">
          <div className="c-pdf-tile__img o-tile__img"><PDF className="c-pdf-tile__img-icon u-svg-currentcolor" /></div>
        </div>

        <div className="c-pdf-tile__body o-tile__body">
          {subtitle &&
            <div className="c-pdf-tile__subtitle a-text-subtitle">{subtitle}</div>
          }

          {title &&
            <div className="c-pdf-tile__title a-text-title">{title}</div>
          }

          <div className="c-pdf-tile__footer">
            <a download className='c-pdf-tile__download btn btn-dark' href={file.url}><Download1 className="c-pdf-tile__download-icon btn__icon" aria-hidden="true" /><span className='c-pdf-tile__download-text'>{downloadFile}</span></a>

            {(language_variants && downloadInLanguages) &&
              <UncontrolledDropdown className="c-dropdown">
                <DropdownToggle tag="button" className="c-pdf-tile__dropdown-toggle c-dropdown__toggle btn btn-green">
                  <span className="sr-only">{downloadStrings.language_select}</span>
                  <Language className="c-dropdown__toggle-icon" aria-hidden="true" />
                  <ArrowDown className="c-dropdown__toggle-caret" aria-hidden="true" />
                </DropdownToggle>

                <DropdownMenu right className="c-dropdown__menu">
                  {language_variants.map((variant) => {
                    const {
                      url,
                      id,
                      acf: {
                        language
                      } = {}
                    } = variant

                    // Bail if the 'main' PDF or no URL
                    if (!url || !id || id === file.id) return null

                    let closestLanguage = localeMatch([language.replace('_', '-')], Object.keys(downloadInLanguages))
                    let downloadPrompt = closestLanguage && downloadInLanguages[closestLanguage];

                    return downloadPrompt
                      ? <DropdownItem className="c-dropdown__item" download key={id} href={url}>{downloadPrompt}</DropdownItem>
                      : null
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            }
          </div>
        </div>
      </li>
    })}
  </ul >
})

PdfGrid.displayName = 'PdfGrid'

PdfGrid.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    file: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      url: PropTypes.string,
      language_variants: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string
  })).isRequired,
}

export default PdfGrid;
