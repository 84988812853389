/**
 * Error
 *
 * @package ptc-therapeutic
 */
import React, { useContext } from 'react';
import { StringsContext } from '../Contexts/StringsContext';
import PageHeader from '../Common/PageHeader';
import { Col, Container, Row } from 'reactstrap';
import SkipToMain from '../Common/SkipToMain';

/**
 * React Component Error.
 */
const Error = React.forwardRef(function () {
  const { strings: { error: {
    header = 'Error',
    copy = 'Sorry, something went wrong.'
  } = {} } = {} } = useContext(StringsContext);

  return <>
    <PageHeader title={header} />

    <Container className="page-main">
      <Row className="justify-content-center">
        <Col sm="10">
          <section className="page-content">
            <SkipToMain />
            <p>{copy}</p>
          </section>
        </Col>
      </Row>
    </Container>
  </>
})

Error.displayName = "Error"

export default Error;