import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isIE } from '../../utils/misc'
import { maybeClassName } from '../../utils/inputs';

const InputWithPlaceholder = React.forwardRef((props, ref) => {
  const { className, value = "", placeholder, onBlur, onFocus, onChange } = props;
  const { children, ...inputProps } = props;
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const checkPlaceholder = ({ target: { value } }) => {
    setShowPlaceholder(!value)
  }

  return <div className="input">
    {isIE() ?
      <>
        <input
          {...inputProps}
          ref={ref}
          value={value}
          className={`input__input${maybeClassName(className)}`}
          data-placeholder-active={showPlaceholder}
          onBlur={e => { checkPlaceholder(e); onBlur && onBlur(e) }}
          onFocus={e => { checkPlaceholder(e); onFocus && onFocus(e) }}
          onChange={e => { checkPlaceholder(e); onChange && onChange(e) }}
        />
        <span
          className={`placeholder input__placeholder${maybeClassName(showPlaceholder && 'active')}`}
        >{placeholder}</span>
      </> :
      /* `value` must be assigned after and apart from the passed props, 
       * otherwise React throws uncontrolled to controlled component conversion error  */
      <input {...inputProps} ref={ref} value={value} className={`input__input${maybeClassName(className)}`} />
    }
    {children}
  </div>
})

InputWithPlaceholder.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

InputWithPlaceholder.displayName = 'InputWithPlaceholder';
export default InputWithPlaceholder;