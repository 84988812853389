import React from 'react';
import PropTypes from 'prop-types';
import Img from '../../Common/Img';
import { isExternal } from "../../../utils/misc";
import ParsedWysiwyg from '../../Common/ParsedWysiwyg';
import { Modal, ModalBody } from "reactstrap";
import LinkSr from '../../Common/LinkSr';
import { withStrings } from '../../Contexts/StringsContext';

class Grid extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.state = { isOpen: null };
	}

	toggle(index) {
		this.setState({
			isOpen: index,
		});
	}

	closeModal() {
		this.setState({
			isOpen: null
		});
	}

	render() {
		const { items, section_title, links_heading, type, item_label, strings } = this.props;
		const modalContent = this.props.items[this.state.isOpen];
		const {
			ui: {
				close
			}
		} = strings

		return (
			<div className="grid centered rich-text">
				<h2 className="section-title">{section_title}</h2>

				<div className="grid__items">
					{items.map((item, index) => {
						return (
							<button className="grid__item" aria-label={`${item_label} ${item.title}`} key={index} onClick={() => this.toggle(index)}>
								<div className="grid__image-container">
									{item.image !== false ?
										<Img className="grid__image" {...item.image} size="grid" />
										: null}
								</div>
								<div className="grid__text-container">
									<div className="grid__title">{item.title}</div>
									<div className="grid__sub-title">{item.sub_title}</div>
								</div>
							</button>
						);
					})}
				</div>

				{this.state.isOpen !== null ?
					<Modal isOpen={this.state.isOpen !== null}
						toggle={this.closeModal}
						size='lg'
						className='grid-modal'
						scrollable>
						<ModalBody className='grid-modal__container'>
							<header className="grid-modal__header">
								<div className="grid-modal__type">{type}</div>
								<div className="grid-modal__title">{modalContent.title}</div>
								<button className="grid-modal__close" onClick={this.closeModal} aria-label={close}>
									<span aria-hidden="true">×</span>
								</button>
							</header>
							<div className='grid-modal__body'>
								<div className="grid-modal__main">
									<div className="grid-modal__sub-title">{modalContent.sub_title}</div>
									<div className="grid-modal__copy">
										<ParsedWysiwyg htmlString={modalContent.content} />
									</div>
								</div>

								<div className="grid-modal__sidebar">
									{modalContent.image !== false ?
										<Img className="grid-modal__image" {...modalContent.image} size="grid" />
										:
										<img className="grid-modal__image grid-modal__image--placeholder" src="/resources/img/bg-grid.png" />
									}

									{modalContent.additional_links !== false ?
										<div className="grid-modal__links">
											<div className="grid-modal__label">{links_heading}</div>
											{modalContent.additional_links.map((item, index) => {
												let external = isExternal(item.link.url);
												return (
													<a className={`grid-modal__link ${external ? "grid-modal__link--external" : null}`} href={item.link.url} target={item.link.target} key={index}>{item.link.title}<LinkSr external={external} target={item.link.target} /></a>
												);
											})}
										</div>
										: null}
								</div>
							</div>
						</ModalBody>
					</Modal>
					: null}
			</div>
		);
	}
}

Grid.propTypes = {
	items: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.object),
		PropTypes.bool
	]),
	item_label: PropTypes.string,
	section_title: PropTypes.string,
	links_heading: PropTypes.string,
	type: PropTypes.string,
	strings: PropTypes.object,
};

Grid.defaultProps = {
	items: [],
	section_title: ""
};

export default withStrings(Grid);