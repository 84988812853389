import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { castArray, pick as _pick } from 'lodash';
import { ariaAttributes } from '../../utils/a11y';
import { htmlAttributes } from '../../utils/misc';
import getPath from '../../api/relativePath';
import { Link } from 'react-router-dom';

const ButtonLink = ({ url, title, target, type, size, arrow, className, ...props }) => {
  if (!url || !title) {
    return null;
  }
  const path = getPath(url);

  const linkProps = {
    ..._pick(props, ariaAttributes, htmlAttributes),
    target: target || undefined,
    className: classnames('btn', castArray(type).map(suffix => `btn-${suffix}`), { [`btn-${size}`]: ['sm', 'lg', 'xl'].includes(size), 'btn-arrow': arrow }, className),
    children: title,
  }
  return path.external ?
    <a href={url} {...linkProps} /> :
    <Link to={path.path} {...linkProps} />
};

ButtonLink.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  size: PropTypes.string,
  arrow: PropTypes.bool,
  className: PropTypes.string,
};

ButtonLink.defaultProps = {
  url: '',
  title: '',
  target: '_self',
  type: 'primary',
  size: '',
  arrow: false,
  className: '',
};

export default ButtonLink;
