import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from "react-html-parser";
import { fetchPtcSettings } from '../../api';
import { range as _range, pick as _pick, uniqueId as _uniqueId } from 'lodash';
import { htmlAttributes } from '../../utils/misc';
import { ariaAttributes } from '../../utils/a11y';
import { RovingTabIndexProvider, useRovingTabIndex, useFocusEffect } from 'react-roving-tabindex';
import { SettingsContext } from '../Contexts/SettingsContext';
import { StringsContext } from '../Contexts/StringsContext';
import { maybeClassName } from '../../utils/inputs';

const HIDDEN_PAGES = 'HIDDEN_PAGES';

const Pagination = props => {
  const {
    current = 1,
    totalRecords = 0,
    perPage: pPerPage = null,
    midSize: pMidSize = null,
    onPageChanged = () => { },
  } = props;

  const [postsPerPage, setPostsPerPage] = useState(null);

  const settings = useContext(SettingsContext);
  const strings = useContext(StringsContext);

  /*------------------------------------------------------------
   * Effects
   *----------------------------------------------------------*/
  /*
   * Get posts_per_page option if needed
   */
  useEffect(() => {
    if (!pPerPage && !postsPerPage) {
      const { posts_per_page } = settings;
      
      if ( posts_per_page ) {
        setPostsPerPage(posts_per_page);
        return;
      }

      fetchPtcSettings('posts_per_page')
        .then(res => res.posts_per_page, () => 10)
        .then(posts_per_page => {
          setPostsPerPage(posts_per_page);
        })
    }

  }, [pPerPage]);

  /*------------------------------------------------------------
   * Variables
   *----------------------------------------------------------*/
  const perPage = pPerPage || postsPerPage;
  if (!perPage) return false;
  const total = Math.ceil(totalRecords / perPage);
  const midSize = pMidSize ? Math.max(0, Math.min(pMidSize, 2)) : null;

  /*------------------------------------------------------------
   * Helpers
   *----------------------------------------------------------*/
  /**
   * Navigate to page number and trigger optional callback.
   * @param {Number} page Page number to go to.
   * @param {Function} cb Callback after page navigation, receives pagination data.
   */
  const gotoPage = (page) => {
    const nCurrent = Math.max(1, Math.min(page, total));

    onPageChanged({
      current: nCurrent,
      total,
      perPage,
      totalRecords
    });
  }

  /*------------------------------------------------------------
   * Render
   *----------------------------------------------------------*/
  /*
   * Calculate page numbers.
   */
  let pageNums = _range(1, total + 1);

  if (null !== midSize) {

    /** totalNumbers: the total page numbers to show on the control */
    const totalNumbers = (midSize * 2) + 3;

    if (total > totalNumbers) {

      const startPage = Math.max(2, current - midSize);
      const endPage = Math.min(total - 1, current + midSize);

      let pages = _range(startPage, endPage + 1);

      /** hasLeftSpill: has hidden pages to the left */
      const hasLeftSpill = startPage > 2;
      /** hasRightSpill: has hidden pages to the right */
      const hasRightSpill = (total - endPage) > 1;

      switch (true) {
        // handle: (1) ... {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          pages = [HIDDEN_PAGES, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} ... (10)
        case (!hasLeftSpill && hasRightSpill): {
          pages = [...pages, HIDDEN_PAGES];
          break;
        }

        // handle: (1) ... {4 5} [6] {7 8} ... (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [HIDDEN_PAGES, ...pages, HIDDEN_PAGES];
          break;
        }
      }

      pageNums = [1, ...pages, total];
    }
  }

  const pastFirstPage = current > 1 && current <= total;
  const beforeLastPage = current < total;
  const { nextText, prevText, hiddenText, className, ...rest } = props;
  const forwardedProps = _pick(rest, htmlAttributes, ariaAttributes);
  const instructionsId = _uniqueId( 'pagination_instructions-' );

  return (
    <RovingTabIndexProvider direction="both">
      <nav role="navigation">
        <span className="sr-only" id={instructionsId}>{strings.ui.roving_tabindex_instructions}</span>
        <ul role="menu" aria-label={strings.pagination.title} aria-describedby={instructionsId} className={`pagination${maybeClassName(className)}`} {...forwardedProps}>
          <PageArrow
            className="pagination__previous"
            inactive={!pastFirstPage}
            onClick={e => { e.preventDefault(); gotoPage(current - 1) }}
            aria-label={strings.pagination.previous_page}
          >
            {typeof prevText == 'string' ? ReactHtmlParser(prevText) : prevText}
          </PageArrow>

          {pageNums.map((page, i) => {
            if (HIDDEN_PAGES === page) {
              return <PageHidden key={`hidden_page-${i}`}>
                {typeof hiddenText == 'string' ? ReactHtmlParser(hiddenText) : hiddenText}
              </PageHidden>
            }

            return <PageNumber
              key={`page-${page}`}
              active={current === page}
              onClick={e => { e.preventDefault(); gotoPage(page); }}
            >
              <span className="sr-only">{strings.pagination.page}</span>
              {page}
            </PageNumber>
          })}

          <PageArrow
            className="pagination__next"
            inactive={!beforeLastPage}
            onClick={e => { e.preventDefault(); gotoPage(current + 1) }}
            aria-label={strings.pagination.next_page}
          >
            {typeof nextText == 'string' ? ReactHtmlParser(nextText) : nextText}
          </PageArrow>
        </ul>
      </nav>
    </RovingTabIndexProvider>
  );
}

const PageNumber = props => {
  const { active, onClick = () => { }, children, ...rest } = props;
  const forwardedProps = _pick(rest, htmlAttributes, ariaAttributes);

  const ref = useRef(null);
  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(
    ref
  );
  useFocusEffect(focused, ref);

  return <li role="none" className={`pagination__item pagination__number${active ? ' active' : ''}`}>
    <a
      {...forwardedProps}
      role="menuitem"
      href="#"
      className="pagination__link"
      onClick={e => { onClick(e); handleClick(e); }}
      ref={ref}
      tabIndex={tabIndex}
      onKeyDown={handleKeyDown}
      aria-disabled={active ? 'true' : undefined}
      aria-current={active ? 'page' : undefined}
    >
      {children}
    </a>
  </li>
}

PageNumber.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

const PageHidden = props => {
  const { children } = props;
  return <li className="pagination__item pagination__dots">{children}</li>
}

PageHidden.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

const PageArrow = props => {
  const { inactive, onClick, className, children, ...rest } = props;
  const forwardedProps = _pick(rest, htmlAttributes, ariaAttributes);

  const ref = useRef(null);
  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(
    ref
  );
  useFocusEffect(focused, ref);

  return <li role="none" className={`pagination__item pagination__arrow${maybeClassName(className)}${maybeClassName('inactive', inactive)}`}>
    <a
      {...forwardedProps}
      role="menuitem"
      ref={ref}
      tabIndex={tabIndex}
      onKeyDown={handleKeyDown}
      href="#"
      onClick={e => { onClick(e); handleClick(e); }}
      className="pagination__link"
      aria-disabled={inactive ? 'true' : undefined}
    >
      {children}
    </a>
  </li>
}

PageArrow.propTypes = {
  className: PropTypes.string,
  inactive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

/**
 * Most props mirror the parameters of WordPress's `paginate_links()`
 * @link https://developer.wordpress.org/reference/functions/paginate_links/
 */
Pagination.propTypes = {
  className: PropTypes.string,
  current: PropTypes.number,
  prevText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  nextText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  midSize: PropTypes.number,
  perPage: PropTypes.number,
  hiddenText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  totalRecords: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func,
};

Pagination.defaultProps = {
  current: 1,
  prevText: <i aria-hidden="true" className="icon icon-arrow-left"></i>,
  nextText: <i aria-hidden="true" className="icon icon-arrow-right"></i>,
  hiddenText: '&hellip;',
  midSize: null,
};

export default Pagination;