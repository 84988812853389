import PageSidebar from '../components/PageTemplates/Sidebar';
import CookiesPage from '../components/Templates/Cookies';
import PostsPage from '../components/Templates/Posts';
import ArchiveEvents from '../components/Templates/ArchiveEvents';
import PageHome from '../components/PageTemplates/Home';
import TOC from '../components/PageTemplates/TOC';

/**
 * Map React components to Wordpress posts, by page template, page type, etc.
 */
export const templateMap = {
  template: {
    'page-template-sidebar': {
      component: PageSidebar,
    },
    'page-template-toc': {
      component: TOC,
    },
    'page-template-archive-events': {
      component: ArchiveEvents
    }
  },
  type: {
    page_for_cookies: {
      component: CookiesPage,
      class: 'page-cookies'
    },
    page_for_posts: {
      component: PostsPage,
      class: 'page-posts',
    },
    page_on_front: {
      component: PageHome,
      class: 'page-home',
    }
  },
}