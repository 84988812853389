import React from "react";
import PropTypes from "prop-types";
import ParsedWysiwyg from '../../Common/ParsedWysiwyg';

const ColumnsCallout = ({ heading, subheading, columns }) => {
  return <div className="columns-callout__inner">
    {heading && <p className="columns-callout__heading">{heading}</p>}
    {subheading && <p className="columns-callout__subheading">{subheading}</p>}

    <div className="columns-callout__columns">
      {columns.map(({ heading, body }, i) =>
        <div
          key={i /* shame */}
          className={`columns-callout__column column column--${i % 2 === 0 ? 'primary' : 'secondary'}`}
        >
          <p className="column__heading">{heading}</p>
          <div className="column__body wysiwyg-content">
            <ParsedWysiwyg htmlString={body} />
          </div>
        </div>)}
    </div>
  </div>
}

ColumnsCallout.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  })).isRequired,
}

export default ColumnsCallout;