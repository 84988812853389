import * as React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import RichText from "./RichText";
import { debounce as _debounce, delay as _delay } from "lodash";
import { withFocusVisible, FocusVisiblePropType } from '../../Contexts/FocusVisibleManager';
import { RadioButtons } from '../../Common/Inputs';
import("../../../utils/a11y").Modality

export class ExternalFundingQualificationFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      for_event: undefined,
      for_program: undefined,
      for_charity: undefined,
      active: "for_event",
      shouldDoTransition: false,
      complete: false,
    }

    this._refs = {
      for_charity: React.createRef(),
      for_event: React.createRef(),
      for_program: React.createRef(),
    }
  }

  componentDidUpdate() {
    const { shouldDoTransition, complete } = this.state;
    const { onComplete } = this.props;

    if (shouldDoTransition) {
      this.transitionField()
    }
    if ( shouldDoTransition && (this.answeredYes() || this.answeredNoToAll()) && !complete) {
      this.setState({ complete: true }, () => onComplete(this.answeredYes()));
    }
  }

  answeredNoToAll = () => ![this.state.for_event, this.state.for_program, this.state.for_charity].some(v => v !== false);
  answeredYes = () => [this.state.for_event, this.state.for_program, this.state.for_charity].includes(true);

  transitionField = () => {
    const { active: prev, for_event, for_program } = this.state;
    let next = prev;
    if (this.answeredYes()) {
      next = "eligible";
    } else {
      next = prev === "for_event" && for_event === false
        ? "for_program"
        : prev === "for_program" && for_program === false
          ? "for_charity"
          : prev === "for_charity" && this.answeredNoToAll()
            ? "ineligible"
            : prev;
    }
    this.setState({ active: next, shouldDoTransition: false }, this.focusInput);
  };

  focusInput = _debounce(() => {
    const { active } = this.state;
    const { hadKeyboardEvent } = this.props.focusVisible;
    if (!active || !hadKeyboardEvent) return;
    switch (active) {
      case 'for_charity':
      case 'for_event':
      case 'for_program':
        this._refs[active].current.focus();
        break;
    }
  }, 400)

  onChange = name => (e, opt) => {
    this.setState({ [name]: opt.value });
    _delay(() => this.setState({ shouldDoTransition: true }), 150)
  };

  render() {
    const { for_event, for_program, for_charity, active, complete } = this.state;
    const { external_funding_form } = this.props.strings;
    const { questionnaire, form_text } = external_funding_form;

    const radio_props = {
      options: [
        { value: true, label: questionnaire.yes },
        { value: false, label: questionnaire.no },
      ],
    };

    const questions = [
      { slug: 'for_event', value: for_event, label: questionnaire.question_1, },
      { slug: 'for_program', value: for_program, label: questionnaire.question_2, },
      { slug: 'for_charity', value: for_charity, label: questionnaire.question_3, },
    ]

    return (
      <section className="eff__qualification" data-complete={complete}>
        <div className="eff__qualification__preface">
          <RichText wysiwyg={form_text.questionnaire_preface_text} />
        </div>
        <form className="centered">
          <div className="eff__qualification__window">
            {questions.map(({ slug, value, label }) => {
              const labelId = `eff_qualification_field-${slug}_desc`;

              return <div
                key={slug}
                className="eff__qualification__field"
                aria-labelledby={labelId}
                role="radiogroup"
                data-question={true}
                data-active={active === slug}
                data-answered={value !== undefined}
              >
                <Label id={labelId}>{label}</Label>
                <RadioButtons
                  horizontal
                  id={slug}
                  ref={this._refs[slug]}
                  name={slug}
                  value={value}
                  onChange={this.onChange(slug)}
                  {...radio_props}
                />
              </div>
            })}
            <div className="eff__qualification__field" role="alert" aria-hidden={!complete} data-result data-active={complete}>
              <RichText wysiwyg={"eligible" === active ? form_text.success_text : ("ineligible" === active ? form_text.failure_text : undefined)} />
            </div>
          </div>
        </form>
      </section>
    );
  }
}
ExternalFundingQualificationFormBase.propTypes = {
  onComplete: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired,
  focusVisible: FocusVisiblePropType.isRequired,
};

export const ExternalFundingQualificationForm = withFocusVisible(ExternalFundingQualificationFormBase);
ExternalFundingQualificationForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  strings: PropTypes.object.isRequired,
};