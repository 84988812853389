import React from 'react';
import PropTypes from 'prop-types';
import ParsedHtml from '../../Common/ParsedWysiwyg';

const RichTextHTML = ({ html }) => {
  if (!html) {
    return null;
  }

  return (
    <div className="wysiwyg-content centered">
      <ParsedHtml htmlString={html} />
    </div>
  );
};

RichTextHTML.propTypes = {
  html: PropTypes.string,
};

export default RichTextHTML;
