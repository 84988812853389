import React from 'react';
import PropTypes from 'prop-types';
import Layout from './Layout';

const FlexibleContent = ({ content }) => {
  if (! ( content && content.length )) {
    return null;
  }

  const items = content.map((layout, key) => <Layout key={key} contentId={`${layout.acf_fc_layout}-${key}`} {...layout} />);

  return (
    <section className="acf-layouts">
      {items}
    </section>
  );
};

FlexibleContent.propTypes = {
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

FlexibleContent.defaultProps = {
  content: [],
};

export default FlexibleContent;
