import * as React from "react";
import PropTypes from "prop-types";
import { InputWrapper, TextInput, RadioButtons } from "../../Common/Inputs";
import {
  EMAIL_REGEX,
  format_phone_number,
  PHONE_PATTERN,
  EMAIL_PATTERN, COUNTRIES,
  maybeClassName
} from "../../../utils/inputs";
import Loading from "../../Common/Loading";
import {postStriveRequestForm} from "../../../api";
import RichText from "./RichText";
import { withFocusVisible, FocusVisiblePropType } from '../../Contexts/FocusVisibleManager';
import Listbox from "../../Common/Listbox";

class StriveRequestFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: {},
      pending: false,
      result: undefined,
      error: undefined,
      invalid_fields: [],
      test: undefined,
    };

    this.focusable = {
      first: React.createRef(),
    }
  }

  componentDidMount() {
    const { hadKeyboardEvent } = this.props.focusVisible;
    if (hadKeyboardEvent && this.focusable.first.current) this.focusable.first.current.focus();
  }

  /**
   * Returns handler for a form field's change event, that lifts field value to this component's state.
   * @param {string} name Name of the field.
   * @param {function} get_value Function to get the value from the event object.
   */
  onChange = (name, get_value = (e => e.target.value)) => event => {
    this.setState({
      form_data: {
        ...this.state.form_data,
        [name]: get_value(event)
      }
    });
  };

  onSelect = name => opt => this.onChange(name, opt => opt.value)(opt);

  validateForm = () => {
    const { fields } = this.props.strings.strive_request_form;
    const { form_data } = this.state;

    const invalid_fields = [];
    Object.entries(fields).forEach(([name, field]) => {
      let valid = true;
      if (field.required && !form_data[name]) {
        valid = false;
      } else if (form_data[name]) {
        if (name === "signatureEmail" || name === "contactEmail") {
          valid = valid && EMAIL_REGEX.test(form_data[name]);
        } else {
          valid = valid && form_data[name].length > 1
        }
      }
      if (!valid) {
        invalid_fields.push(name);
      }
    });
    if (invalid_fields.length > 0) {
      this.setState({ invalid_fields });
      return false;
    }

    return {
      ...form_data,
    };
  };

  onSubmit = e => {
    e.preventDefault();
    const data = this.validateForm();
    if (!data) {
      return false;
    }
    this.setState({ pending: true }, () =>
      postStriveRequestForm(data)
        .then(this.handleRequestResponse)
    )
  };

  handleRequestResponse = res => {
    const { message, invalid_fields = [] } = this.getResponseError(res);
    this.setState({
      pending: false,
      result: res,
      error: message,
      invalid_fields,
    });
  };

  getResponseError = (result = this.state.result) => {
    if (!result || result.status === 200) {
      return {};
    }
    const { error } = result;
    const { strings } = this.props;
    if (!error) {
      return {
        message: strings.form.errors.generic
      };
    }
    const { code, message } = error;
    switch (code) {
      case "invalid_fields":
        return {
          invalid_fields: message,
          message: strings.form.errors.invalid_fields,
        };
      case "invalid_request":
        return {
          message
        };
      default:
        return {
          message: strings.form.errors.generic
        }
    }
  };

  // set attributes for the <input> based on the field name and strings
  make_field = ([name, field], inputProps = {}) => {

    const { invalid_fields } = this.state;
    const invalid = invalid_fields.includes(name);
    /**
     * When an invalid field's value changes, remove it from `state.invalid_fields`.
     *
     * @param {function} func Event handler.
     */
    const with_invalid = func => invalid
      ? (...args) => {
        this.setState({
          invalid_fields: invalid_fields.filter(f => f !== name),
        });
        return func(...args);
      }
      : func;

    let Component = TextInput;
    const instructionsId = field.instructions ? `${name}_instructions` : undefined;
    const titleProps = {
      id: `eff_field_${name}-title`
    }
    let props = {
      name,
      placeholder: field.placeholder || undefined,
      required: field.required,
      value: this.state.form_data[name],
      onChange: with_invalid(this.onChange(name)),
      'aria-labelledby': titleProps.id + maybeClassName(instructionsId),
      'aria-required': field.required || undefined,
    };
    let title = field.title;
    const optionsApplicationCategory = [
        { value: "Transition", label: "Transition to adulthood ($30,000 USD or less)" },
        { value: "Innovative", label: "Innovative ($30,000 USD or less)" },
        { value: "Sustaining", label: "Sustaining ($30,000 USD or less)" },
      ];

    const optionsYesNo = [
      { value: "Yes", label: "Yes" },
        { value: "No", label: "No" }
    ];


    switch (name) {
      case "applicationCategory":
        Component = RadioButtons;
        props = {
          horizontal: true,
          options: optionsApplicationCategory,
          ...props,
        };
        break;
      case "phone":
      case "contactPhone":
      case "signaturePhone":
        props = {
          ...props,
          pattern: PHONE_PATTERN,
          validate: v => !v || !!format_phone_number(v),
          type: "tel"
        };
        break;
      case "contactEmail":
        props = {
          ...props,
          pattern: EMAIL_PATTERN,
          validate: v => EMAIL_REGEX.test(v),
          type: "email"
        };
        break;
      case "signatureEmail":
        props = {
          ...props,
          pattern: EMAIL_PATTERN,
          validate: v => EMAIL_REGEX.test(v),
          type: "email"
        };
        break;
      case "signatureDate":
        props = {
          ...props,
          type: "date"
        };
        break;
      case "country":
        Component = Listbox;
        props = {
          ...props,
          disabled: this.state.pending,
          onChange: with_invalid(this.onSelect(name)),
          options: COUNTRIES,
        };
        break;
      case "previouslyGrantYear":
        props = {
          ...props,
          max: new Date().getFullYear(),
          min: 1960,
          type: "number"
        };
        break;
      case "wasGrantPreviouslyAwarded":
        Component = RadioButtons;
        props = {
          horizontal: true,
          options: optionsYesNo,
          ...props,
        };
        break;
      case "amountRequestUSD":
      case "amountBudgetedUSD":
        props = {
          ...props,
          type: "number"
        };
        break;
    }
    return (
      <InputWrapper key={name} title={title} required={field.required} titleProps={titleProps} className="eff__field" invalid={invalid}>
        {instructionsId && <span className="sr-only" id={instructionsId}>{field.instructions}</span>}
        <Component {...inputProps} {...props} />
      </InputWrapper>
    )
  };

  render() {
    const { method, url, strings } = this.props;
    const { strive_request_form, form } = strings;
    const { pending, result, error } = this.state;
    const success = !pending && result && result.status === 200;
    const fields = Object.entries(strive_request_form.fields).map(this.make_field).filter(f => !!f);
    const firstFocusable = fields.findIndex(field => !field.props.disabled);
    if (-1 !== firstFocusable) fields[firstFocusable] = this.make_field(Object.entries(strive_request_form.fields)[firstFocusable], { innerRef: this.focusable.first });
    const formFieldsTop = [];
    const formFieldsMiddle = [];
    const formFieldsBottom = [];
    const formFieldApplication = [];
    for (let field of fields){
      if(field.key === "signatureName" || field.key === "signatureDate" || field.key === "signatureEmail" || field.key === "signatureAddress" || field.key === "signaturePhone"){
        formFieldsBottom.push(field);
      } else if(field.key === "organizationMission" || field.key === "projectOverview" || field.key === "projectSpecifics" || field.key === "amountRequestUSD" || field.key === "amountBudgetedUSD"){
        formFieldsMiddle.push(field);
      } else if(field.key === "applicationCategory"){
        formFieldApplication.push(field);
      } else{
        formFieldsTop.push(field);
      }
    }
    const halfTop = Math.ceil(formFieldsTop.length / 2);
    const halfBottom = Math.ceil(formFieldsBottom.length / 2);

    return (
      <div className="external-funding-form">
      <form method={method} action={url} className="eff__form" onSubmit={this.onSubmit}>
        <div className="wysiwyg-content centered eff__instructions">
          {success ? <RichText wysiwyg={strive_request_form.form_text.success_text} /> : <p>{form.required_instructions}</p>}
        </div>
        <div><RichText wysiwyg={strive_request_form.form_text.aggreement_text} /></div>
        <fieldset disabled={pending || success}>
          <div className="eff__fields" data-collapse={success}>
            <div className="eff__column_12">
              {formFieldApplication}
            </div>
            <div className="eff__column">
              { formFieldsTop.slice(0, halfTop - 1) }
            </div>
            <div className="eff__column">
              { formFieldsTop.slice(halfTop - 1) }
            </div>
            <div className="eff__column_12">
              {formFieldsMiddle}
            </div>
            <div className="eff__column_12">
              <RichText wysiwyg={strive_request_form.form_text.agreement_text} />
            </div>
            <div className="eff__column">
              { formFieldsBottom.slice(0, halfBottom) }
            </div>
            <div className="eff__column">
              { formFieldsBottom.slice(halfBottom) }
            </div>
            <div className="eff__error-msg">
              {error}
            </div>
          </div>
        </fieldset>
        <div className="eff__submit-bar eff__field--full-width">
          <button hidden={success} data-pending={pending} data-success={!!success} className="eff__submit-btn btn btn-secondary" type="submit">
            <div data-part="text">{form.components.submit}</div>
            <div data-part="pending"><Loading /></div>
            <div data-part="success">{form.components.success}</div>
          </button>
        </div>
      </form>
    </div>
    )
  }
}

StriveRequestFormBase.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  focusVisible: FocusVisiblePropType.isRequired,
};

export const StriveRequestForm = withFocusVisible(StriveRequestFormBase);

StriveRequestForm.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
};
