/**
 * LoopEvent
 *
 * @package frontend-ui
 */
import React from 'react';
import PropTypes from 'prop-types';
import { AllHtmlEntities as Entities } from 'html-entities';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import Img from './Img';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

const decode = (new Entities()).decode;

/**
 * React Component LoopEvent.
 *
 * @param {Object} props Component properties.
 */
const LoopEvent = React.forwardRef((props, ref) => {
  const {
    className,
    data,
    ...forwardProps
  } = props;

  const {
    link: url,
    title: { rendered: title } = {},
    excerpt: { rendered: excerpt } = {},
    acf: {
      banner_image,
      start_date,
      start_time,
      all_day,
      location
    } = {}
  } = data;

  const path = (new URL(url)).pathname

  const showTime = !all_day && start_time

  const startDateTime = new Date(`${start_date}T${start_time || '00:00:00'}Z`)

  return <Link
    {...forwardProps}
    ref={ref}
    to={path}
    className={classNames(className, 'loop-event mg-card mg-card--hover mg-card--border-gold')}
  >
    {banner_image &&
      <div className="loop-event__img-outer mg-card__img-outer embed-responsive embed-responsive-16by9">
        <Img className='loop-event__img mg-card__img embed-responsive-item' {...banner_image} size="section-mobile" />
      </div>
    }
    <div className='loop-event__body mg-card__body'>
      <div className='loop-event__title'><span>{decode(title)}</span></div>
      {start_date && <Moment className='loop-event__date' format={`LL${showTime ? ' @ LT' : ''}`}>{startDateTime}</Moment>}
      {location && <div className="loop-event__location">{location}</div>}
      {excerpt && <div className="loop-event__description wysiwyg-content">{ReactHtmlParser(excerpt)}</div>}
    </div>
  </Link>
})

LoopEvent.displayName = 'LoopEvent'

LoopEvent.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
}

LoopEvent.defaultProps = {
  className: '',
}

export default LoopEvent;