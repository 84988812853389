// 'input:not(:disabled), select:not(:disabled), textarea:not(:disabled), button:not(:disabled), object:not(:disabled), a[href], [tabindex], [contenteditable]'
export const focusableSelector = [
  ...['input', 'select', 'textarea', 'button', 'object'].map(str => `${str}:not(:disabled)`),
  'a[href]', '[tabindex]', '[contenteditable]'
].map(str => `${str}:not([tabindex='-1'])`).join(', ')

/**
 * Find the first focusable node within given node.
 * @param {Element|String} el DOM node or selector
 * @returns {Element} First focusable node within given node, or `null` if none found.
 */
export const firstFocusable = el => {
  let root = el;

  if ('string' === typeof el) {
    root = document.querySelector(el);
    if (!root) return null;
  }

  return root.querySelector(focusableSelector);
}

/**
 * Supported keycodes.
 * @constant {Object}
 * @typedef {'ArrowDown'|'ArrowLeft'|'ArrowRight'|'ArrowUp'|'End'|'Enter'|'Escape'|'Home'|'Space'|'Tab'} KeyCode
 */
const KEYMAP = {
  ArrowDown: {
    code: 'ArrowDown',
    key: 'ArrowDown',
    keycode: 40,
    which: 40,
  },
  ArrowLeft: {
    code: 'ArrowLeft',
    key: 'ArrowLeft',
    keycode: 37,
    which: 37,
  },
  ArrowRight: {
    code: 'ArrowRight',
    key: 'ArrowRight',
    keycode: 39,
    which: 39,
  },
  ArrowUp: {
    code: 'ArrowUp',
    key: 'ArrowUp',
    keycode: 38,
    which: 38,
  },
  End: {
    code: 'End',
    key: 'End',
    keyCode: 35,
    which: 35,
  },
  Enter: {
    code: 'Enter',
    key: 'Enter',
    keyCode: 13,
    which: 13,
  },
  Escape: {
    code: 'Escape',
    key: 'Escape',
    keyCode: 27,
    which: 27,
  },
  Home: {
    code: 'Home',
    key: 'Home',
    keyCode: 36,
    which: 36,
  },
  Space: {
    code: 'Space',
    key: 'Space',
    keyCode: 32,
    which: 32,
  },
  Tab: {
    code: 'Tab',
    key: 'Tab',
    keyCode: 9,
    which: 9,
  }
}

/**
 * User input modality types.
 * @typedef {'keyboard'|'mouse'|'touch'} Modality
 */

/**
 * Properties of an Event object describing which key was pressed.
 */
const KEYPROPS = ['code', 'key', 'keyCode', 'which'];

/**
 * Whether key pressed was specified key(s). Only supports certain keys.
 *
 * @param {Object} keyData Info about key pressed from Event object. 
 *   Should have at least one of the following properties: `code`, `key`, `keyCode`, `which`.
 * @param {KeyCode|KeyCode[]} codes Code(s) of arrow keys to return true for, e.g. `['ArrowUp', 'ArrowDown']`.
 *
 * @returns {Boolean} Whether key represented by `keyData` was one of desired keys represented by the `codes`.
 */
export const isKey = (keyData, codes) => {
  if (!Array.isArray(codes)) {
    codes = [codes];
  }

  for (let i = 0; i < KEYPROPS.length; i++) {
    const prop = KEYPROPS[i];
    if (keyData[prop] && codes.map(code => KEYMAP[code][prop]).includes(keyData[prop]))
      return true;
  }

  return false;
}

/**
 * Identify supported key (see KEYMAP}) based on data.
 *
 * @param {Object} keyData Info about key pressed from Event object.
 * @returns {String|undefined} Key's code if supported key identified, undefined otherwise.
 */
export const whichKey = keyData => Object.keys(KEYMAP).find(key => isKey(keyData, key))

/**
 * Based on `React.AriaAttributes`.
 */
export const ariaAttributes = [
  'aria-activedescendant',
  'aria-atomic',
  'aria-autocomplete',
  'aria-busy',
  'aria-checked',
  'aria-colcount',
  'aria-colindex',
  'aria-colspan',
  'aria-controls',
  'aria-current',
  'aria-describedby',
  'aria-details',
  'aria-disabled',
  'aria-dropeffect',
  'aria-errormessage',
  'aria-expanded',
  'aria-flowto',
  'aria-grabbed',
  'aria-haspopup',
  'aria-hidden',
  'aria-invalid',
  'aria-keyshortcuts',
  'aria-label',
  'aria-labelledby',
  'aria-level',
  'aria-live',
  'aria-modal',
  'aria-multiline',
  'aria-multiselectable',
  'aria-orientation',
  'aria-owns',
  'aria-placeholder',
  'aria-posinset',
  'aria-pressed',
  'aria-readonly',
  'aria-relevant',
  'aria-required',
  'aria-roledescription',
  'aria-rowcount',
  'aria-rowindex',
  'aria-rowspan',
  'aria-selected',
  'aria-setsize',
  'aria-sort',
  'aria-valuemax',
  'aria-valuemin',
  'aria-valuenow',
  'aria-valuetext',
]
