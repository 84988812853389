import { stringify } from 'query-string';
import { isEmpty as _isEmpty, differenceWith as _differenceWith, get as _get, merge as _merge } from 'lodash';

/**
 * Const for promise statuses.
 */
export const PROMISE_STATUS = {
  REJECTED: 2,
  PENDING: 0,
  FULFILLED: 1,
}

/**
 * Parse fetch() response.
 * @param {Response} res Response from fetch.
 * @returns {Promise} Object with post object, error object, and headers.
 */
export const handleResponse = res => {
  return res.json().then(jsonData => {
    if (res.ok) {
      return {
        data: jsonData,
        error: {},
        headers: res.headers
      };
    } else {
      let error = Object.assign({}, jsonData, {
        status: res.status,
        statusText: res.statusText,
        message: res.message || jsonData.message
      });
      return Promise.reject({ data: {}, error, headers: res.headers });
    }
  });
};

/** @typedef {'facetwp'|'jwt'|'menu'|'ptc'|'search'|'settings'|'wordpress'} ApiName */

/**
 * Map of API name to REST namespace.
 * @const {Object.<ApiName, String>}
 */
const apiMap = {
  facetwp: process.env.FWP_API_PATH,
  jwt: process.env.JWT_API_PATH,
  menu: process.env.MENU_API_PATH,
  ptc: process.env.PTC_API_PATH,
  search: process.env.SEARCH_API_PATH,
  settings: process.env.PAGE_SETTINGS,
  wordpress: process.env.API_PATH,
}

/**
 * Generic fetch helper. Prepends base url and path of provided `api` to provided `path`.
 *
 * @param {ApiName} api Name of target api.
 * @param {String} path Path to append to api.
 * @returns {Promise} Response.
 */
export const fetchFrom = (api, path) => {
  const apiPath = apiMap[api];
  if (!apiPath)
    return Promise.reject(`Path for API '${api}' not found.`)

  return fetch(`${process.env.BASE_URL}${apiPath}${path}`).then(handleResponse);
}

/**
 * Fetch custom site settings that apply only to PTCBio
 * @typedef {'permalink_structure'|'home_page'|'search_page'|'title'|'description'|'date_format'|'posts_page'|'posts_per_page'} PtcSetting
 * @param {PtcSetting|PtcSetting[]} include Optionally specify settings to fetch. Defaults to all.
 * @returns {Promise} Object of settings.
 */
export const fetchPtcSettings = (include = []) => {
  let queryString = _isEmpty(include) ? '' : `?${stringify({ include: include }, { arrayFormat: 'comma' })}`;
  return fetch(`${process.env.BASE_URL}${process.env.PAGE_SETTINGS}${queryString}`)
    .then(handleResponse)
    .then(res => res.data);
};

// Returns global site settings.
export const fetchSettings = () => {
  return fetch(`${process.env.BASE_URL}/wp-json`).then(handleResponse);
};

// Returns global strings
export const fetchStrings = () => {
  return fetch(`${process.env.BASE_URL}/wp-json/ptc/v1/strings`).then(handleResponse);
};

// Returns countries
export const fetchCountries = () => {
  return fetch(`${process.env.BASE_URL}/wp-json/ptc/v1/countries`).then(handleResponse);
}

// Returns a list of post types and their endpoint.
export const fetchPostTypes = () => {
  return fetch(`${process.env.BASE_URL}${process.env.API_PATH}/types`)
    .then(handleResponse)
    .then(({ data }) =>
      Object.keys(data).map(typeName => {
        return { name: typeName, endpoint: data[typeName].rest_base };
      })
    );
};

/**
 * Fetch array of posts of a given type.
 * @param {String} type Route for the post type, e.g. 'pages' for post type page.
 * @param {Array} [items=[]] Optional array of post objects to append items to.
 * @param {Number} [page=1] Optional index of first page of results. Defaults to 1.
 * @returns {Promise} Array of post objects.
 */
export const fetchCollection = (type, items = [], page = 1) => {
  return fetch(
    `${process.env.BASE_URL}${process.env.API_PATH}/${type}?page=${page}`
  )
    .then(handleResponse)
    .then(({ data, headers }) => {
      items = items.concat(data);
      if (items.length < Number(headers.get("X-WP-Total"))) {
        return fetchCollection(type, items, page + 1);
      }

      return items;
    });
};

/**
 * Returns a Promise that resolves after given time.
 * @param {Number} ms Milliseconds to wait.
 * @returns {Promise} undefined.
 */
const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Fetch posts.
 * @param {String} type Rest base for desired post type, e.g. posts
 * @param {Object} args Arguments for the request, see the {@link https://developer.wordpress.org/rest-api/reference/posts/#arguments WordPress reference}.
 */
export const fetchPosts = (type, args = {}) => {
  let queryString = stringify(args, { arrayFormat: 'comma' });
  return fetch(`${process.env.BASE_URL}${process.env.API_PATH}/${type}${queryString ? `?${queryString}` : ''}`)
    .then(handleResponse);
}



/**
 * Fetch post's data using a URL ending in the post's slug.
 * @param {string} link Path of post relative to base URL.
 * @param {string} type Route for the post type, e.g. 'pages' for post type page.
 * @returns {Promise} Post object.
 */
export const fetchPostByUrl = async (link, type = "pages") => {
  try {
    const url = new URL(link, process.env.BASE_URL);
    if (url.pathname === "/") {
      // Home page.
      const homePage = await fetchPtcSettings('home_page').then(res => res.home_page);
      return fetch(
        `${process.env.BASE_URL}${process.env.API_PATH}/pages/${homePage}`
      ).then(handleResponse);
    }
    if (url.pathname.match(/^\/search\/?$/)) {
      // Search page; wait for redirect in <PageContent> component.
      await timeout(1000);
    }

    // Get post based on slug.
    const path = url.pathname;
    const pathParts = path.replace(/^\/|\/$/g, "").split("/");
    const slug = pathParts[pathParts.length - 1];
    return fetch(
      `${process.env.BASE_URL}${process.env.API_PATH}/${type}?slug=${slug}`
    )
      .then(handleResponse)
      .then(({ data, ...res }) => {
        if (!Array.isArray(data)) {
          return { data, ...res };
        }
        // If array of posts, find first post with a path matching the current url's path.
        const pathRegExp = new RegExp(`^${path}/?$`);
        const page = data.find(item => {
          const pageUrl = new URL(item.link);
          return pageUrl.pathname.match(pathRegExp);
        });
        return { data: page ? page : {} };
      });
  } catch (error) {
    return { error };
  }
};

/**
 * Fetch menu items for given menu location.
 * @param {String} location Slug of registered menu location.
 * @returns {Promise} Array of menu items objects.
 */
export const fetchMenu = location => {
  return fetch(
    `${process.env.BASE_URL}${process.env.MENU_API_PATH}/menu-locations/${location}`
  ).then(handleResponse);
};

/**
 * Fetch search results based on
 * @param {String} term Search terms (not URL-encoded).
 * @param {Number} number Index of search result page to retrieve.
 * @returns {Promise} Array of resulting post objects.
 */
export const fetchSearchResults = (term, number) => {
  return fetch(
    `${process.env.BASE_URL}${process.env.SEARCH_API_PATH}${encodeURIComponent(term)}&page=${number}`
  ).then(handleResponse);
};

export const postFundingRequestForm = (data) => {
  const form = new FormData();
  Object.entries(data).forEach(([name, value]) => {
    form.append(name, value);
  });

  return fetch(`https://admin.ptcbio.com/en/wp-json/ptc/v1/external-funding-form`, {
    method: "POST",
    body: form,
  })
    .then(async res => {
      return {
        status: res.status,
        data: (res.json && await res.json()) || res.body || (res.text && await res.text())
      }
    }).then(res => {
      const status = res.status === 200 && res.data && res.data.status ? res.data.status : res.status
      if (status !== 200) {
        return {
          error: res.data,
          status
        }
      }
      return ({
        data: res.data,
        status
      })
    }).catch(err => {
      return {
        status: err.status || err.statusCode,
        error: err.message || err
      }
    });
};

export const postInvestigatorForm = (data) => {
  const form = new FormData();
  Object.entries(data).forEach(([name, value]) => {
    form.append(name, value);
  });

  return fetch(`${process.env.BASE_URL}/wp-json/ptc/v1/investigator-form`, {
    method: "POST",
    body: form,
  })
    .then(async res => {
      return {
        status: res.status,
        data: (res.json && await res.json()) || res.body || (res.text && await res.text())
      }
    }).then(res => {
      const status = res.status === 200 && res.data && res.data.status ? res.data.status : res.status
      if (status !== 200) {
        return {
          error: res.data,
          status
        }
      }
      return ({
        data: res.data,
        status
      })
    }).catch(err => {
      return {
        status: err.status || err.statusCode,
        error: err.message || err
      }
    });
};

export const postStriveRequestForm = (data) => {
  const form = new FormData();
  Object.entries(data).forEach(([name, value]) => {
    form.append(name, value);
  });

  return fetch(`${process.env.BASE_URL}/wp-json/ptc/v1/strive-request-form`, {
    method: "POST",
    body: form,
  })
    .then(async res => {
      return {
        status: res.status,
        data: (res.json && await res.json()) || res.body || (res.text && await res.text())
      }
    }).then(res => {
      const status = res.status === 200 && res.data && res.data.status ? res.data.status : res.status
      if (status !== 200) {
        return {
          error: res.data,
          status
        }
      }
      return ({
        data: res.data,
        status
      })
    }).catch(err => {
      return {
        status: err.status || err.statusCode,
        error: err.message || err
      }
    });
};

export const fetchJwt = (username, password) => {
  return fetch(`${process.env.BASE_URL}${process.env.JWT_API_PATH}/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username,
      password
    })
  })
    .then(handleResponse)
}

export const validateJwt = (token) => {
  return fetch(`${process.env.BASE_URL}${process.env.JWT_API_PATH}/token/validate`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(handleResponse)
}

export const fetchTerms = (taxonomy, args = {}) => {
  const query = stringify(args, { arrayFormat: 'comma' });
  return fetch(`${process.env.BASE_URL}${process.env.API_PATH}/${taxonomy}${query ? `?${query}` : ''}`)
    .then(handleResponse)
}

/**
 * Extensions to {@link https://developer.wordpress.org/rest-api/reference/taxonomies/#schema WordPress REST response for a taxonomy}
 *
 * @typedef {Object} ExtendedTaxonomy
 * @property {Boolean} hasAllTerms Whether the terms provided in this taxonomy comprise all the taxonomy terms.
 * @property {Object[]} terms Follows
 * {@link https://developer.wordpress.org/rest-api/reference/categories/#schema WordPress REST response for a taxonomy's terms }
 */

/**
 * Schema for specifying what taxonomy info you need.
 *
 * @typedef {Object} NeededTaxonomy For each property from the
 * {@link https://developer.wordpress.org/rest-api/reference/taxonomies/#schema WordPress REST response for a taxonomy}
 * you need, include as key with value `true`. Note that if you include any of these keys, the response will include all.
 * @property {String[]|Boolean} terms Boolean `true` if all terms, or array of slugs of needed terms.
 */

/**
 * Fetch necessary taxonomy and/or term data depending on needed and possessed data.
 *
 * @param {Object.<string, NeededTaxonomy|Boolean>} need Data needed, keyed by taxonomy slug.
 * Boolean `true` if all data for a taxonomy, otherwise follow schema for NeededTaxonomy.
 * @param {Object.<string, ExtendedTaxonomy>} have Data possessed, keyed by taxonomy slug.
 */
export const fillTaxonomies = async (need = {}, have = {}) => {
  let taxonomies = {};
  let missTerms = {};
  let missTaxonomies = [];

  // Identify missing terms and taxonomies.
  for (const taxSlug in need) {
    const props = _get(need, taxSlug, undefined);
    if (undefined !== props) {
      /*
       * Missing terms
       */
      // eslint-disable-next-line no-unused-vars
      for (const i of Array(1)) { // Use single-iteration loop to allow early bail using break.
        if (!props && !props.terms) break; // Need no terms.

        const haveTerms = _get(have, [taxSlug, 'terms']);

        if (props === true || props.terms === true) { // Need all terms.
          if (!haveTerms || !haveTerms.length || !have[taxSlug].hasAllTerms || have[taxSlug].hasAllTerms !== true) {
            // Not guaranteed to have all terms, so gotta catch 'em all.
            missTerms[taxSlug] = true;
            break;
          }
          break; // Have all terms, good to go.
        }

        if (props.terms && Array.isArray(props.terms) && props.terms.length) { // Need some terms.
          if (!haveTerms || !haveTerms.length) {
            missTerms[taxSlug] = props.terms;
            break;
          }
          missTerms[taxSlug] = _differenceWith(props.terms, have[taxSlug].terms, (a, { slug, id }) => {
            // Identify missing terms.
            switch (typeof a) {
              case 'string':
                return a === slug;
              case 'number':
                return a === id;
              default:
                return true;
            }
          })
          break;
        }
        break;
      }

      /*
       * Missing taxonomies
       */
      const needKeys = Object.keys(props)
      // Do we even need it?
      if (props === true || // Need everything
        needKeys.length > 1 || // Need some things
        needKeys[0] !== 'terms' || // Need something (terms is a different api path than taxonomies)
        Object.keys(missTerms).includes(taxSlug) // Need rest_base
      ) {
        // Is it missing?
        if (!have[taxSlug]) missTaxonomies.push(taxSlug);
        else {
          const haveKeys = Object.keys(have[taxSlug])
          if (
            !haveKeys.length || // Taxonomy empty
            (haveKeys.length === 1 && haveKeys[0] === 'terms') // Taxonomy only has terms
          ) {
            missTaxonomies.push(taxSlug);
          }
        }
      }
    }
  }

  // Fetch taxonomies
  await (missTaxonomies.length ?
    fetch(`${process.env.BASE_URL}${process.env.API_PATH}/taxonomies`)
      .then(handleResponse)
      .then(({ data }) => {
        _merge(taxonomies, data);
        return data;
      })
    : Promise.resolve({}));

  // Fetch terms
  const taxesTerms = await Promise.all(Object.keys(missTerms).map(taxSlug => {
    let rest_base = _get(have, [taxSlug, 'rest_base']);
    if (!rest_base) rest_base = taxonomies[taxSlug].rest_base;

    let taxTerms = Promise.resolve([]);
    const missTaxTerms = _get(missTerms, taxSlug, undefined);

    // eslint-disable-next-line no-unused-vars
    for (const i of Array(1)) { // Use loop to allow early bailing using `break`

      if (undefined === missTaxTerms) break;
      if (true === missTaxTerms) { // All, please
        taxTerms = fetchTerms(rest_base, { per_page: 100 }).then(({ data }) => data);
        break;
      }

      let slugs = [];
      let ids = [];
      missTaxTerms.forEach(termName => {
        switch (typeof termName) {
          case 'string':
            slugs.push(termName)
            break;
          case 'number':
            ids.push(termName);
            break;
          default:
            break;
        }
      });

      taxTerms = Promise.all([
        slugs.length ?
          fetchTerms(rest_base, { slug: slugs })
          : Promise.resolve({ data: [] }),
        ids.length ?
          fetchTerms(rest_base, { include: ids })
          : Promise.resolve({ data: [] })
      ])
        .then(([{ data: slugTerms }, { data: idTerms }]) => [...slugTerms, ...idTerms])

      break; // Break to doubly ensure one iteration.
    }

    return taxTerms.then(terms => ({ [taxSlug]: { terms, ...(true === missTaxTerms ? { hasAllTerms: true } : {}) } }))
  }));

  _merge(taxonomies, ...taxesTerms);
  return taxonomies;
}
