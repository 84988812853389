import { chain, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import getPath from '../api/relativePath';

/**
 * Extract post slug from a URL.
 * @param {String} url URL terminating in slug.
 */
export const slugFromUrl = url => {
  const urlStr = url;
  url = new URL(urlStr, process.env.BASE_URL);
  const path = url.pathname;
  const pathParts = path.replace(/^\/|\/$/g, "").split("/");
  return pathParts[pathParts.length - 1];
}

/**
 * Try to create a `URL` object from a string with graceful failure.
 *
 * @param {String} url A USVString or any other object with a stringifier — including, for example, an <a> or <area>
 * element — that represents an absolute or relative URL. If url is a relative URL, base is required, and will be used
 * as the base URL. If url is an absolute URL, a given base will be ignored.
 *
 * @param {String} base A USVString representing the base URL to use in cases where url is a relative URL. If not
 * specified, it defaults to undefined.
 *
 * @param {*} fallback Return value when `URL` construction fails. Default `undefined`
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/URL/URL#parameters
 *
 * @returns {URL} `URL` object if it could be constructed, `undefined` otherwise.
 */
export function tryURL(url, base, fallback = undefined) {
  try {
    return new URL(url, base);
  } catch (error) {
    return fallback
  }
}

export const COOKIE_CATEGORIES = {
  NECESSARY: 'C0001',
  PERFORMANCE: 'C0002',
  FUNCTIONAL: 'C0003',
  ADVERTISING_TARGETING: 'C0004',
  SOCIAL: 'C0005',
}

export const PagePropTypes = {
  title: PropTypes.shape({
    rendered: PropTypes.string,
  }),
  id: PropTypes.number,
  date: PropTypes.string,
  section: PropTypes.string,
  template: PropTypes.string,
  acf: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  link: PropTypes.string,
  seo: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
  }),
}

export const MenuItemPropTypes = PropTypes.shape({
  ID: PropTypes.number,
  order: PropTypes.number,
  parent: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
  attr: PropTypes.string,
  target: PropTypes.string,
  classes: PropTypes.string,
  xfn: PropTypes.string,
  description: PropTypes.string,
  object_id: PropTypes.number,
  object: PropTypes.string,
  type: PropTypes.string,
  type_label: PropTypes.string,
  children: PropTypes.arrayOf(MenuItemPropTypes),
  acf: PropTypes.object,
  depth: PropTypes.number,
})

const tagToRegex = {
  'year': '([0-9]{4})',
  'monthnum': '([0-9]{1,2})',
  'day': '([0-9]{1,2})',
  'hour': '([0-9]{1,2})',
  'minute': '([0-9]{1,2})',
  'second': '([0-9]{1,2})',
  'postname': '([^\\/]+)',
  'post_id': '([0-9]+)',
  'author': '([^\\/]+)',
  'pagename': '([^\\/]+?)',
  'search': '(.+)',
}

/**
 * Convert WordPress permalink structure into RegEx string.
 * @param {String} structure Permalink structure that can contain WordPress supported tags, e.g. `posts/%year%/%monthnum%/%postname%/`.
 * @returns {String} Permalink structure with tags replaced by RegEx equivalents.
 */
export const permalinkStructureRegex = structure => {
  let newStructure = structure.replace(/%(\w+?)%/g, (match, p1) => `:${p1}${tagToRegex[p1]}`);
  return newStructure;
}

/**
 * Modulus function that ensure non-negative result.
 *
 * @link https://torstencurdt.com/tech/posts/modulo-of-negative-numbers/ Explanation.
 *
 * @param {Number} dividend Dividend.
 * @param {Number} divisor Divisor.
 * @returns {Number} Remainder.
 */
export const mod = (dividend, divisor) => (((dividend % divisor) + divisor) % divisor);

export const handleRef = (ref, el) => {
  if (null === ref || !(el instanceof Element)) return;

  switch (typeof ref) {
    case 'function':
      ref(el);
      return;
    case 'object':
      if (Object.prototype.hasOwnProperty.call(ref, 'current')) {
        ref.current = el;
        return;
      }
      break;
  }
}

/**
 * Assign multiple refs to a single element.
 *
 * @link {https://www.davedrinks.coffee/how-do-i-use-two-react-refs/}
 *
 * @param  {...(Object|Function)} refs Array of refs
 * @returns {Function}
 */
export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];

  return el => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(el);
      } else if (ref) {
        ref.current = el;
      }
    }
  };
};

/**
 * Determine if a link is external, based on the current page's url.
 * @param {String} url URL to determine if external.
 * @returns {Boolean|undefined} Whether or not the link is external, or undefined if unable to determine.
 */
export const isExternal = url => {
  if ('string' !== typeof url) return undefined;

  // Relative links and anchors.
  if (['/', '#'].includes(url.charAt(0))) return false;

  let targetUrl = undefined;

  try {
    targetUrl = new URL(url);
  } catch {
    return undefined;
  }

  return trimW3(targetUrl.hostname) !== trimW3(location.hostname);
}

/**
 * Trim leading subdomain 'www' from a URL.
 * @param {String} url URL to trim.
 */
export const trimW3 = url => url.replace(/^(https?:\/\/)?w{3}\.(?=(?:[a-z\d-]+\.)+[a-z\d-]{2,})/i, '$1');

/**
 * Based on `React.HTMLAttributes`
 */
export const htmlAttributes = [
  'defaultChecked',
  'defaultValue',
  'suppressContentEditableWarning',
  'suppressHydrationWarning',
  'accessKey',
  'className',
  'contentEditable',
  'contextMenu',
  'dir',
  'draggable',
  'hidden',
  'id',
  'lang',
  'placeholder',
  'slot',
  'spellCheck',
  'style',
  'tabIndex',
  'title',
  'translate',
  'radioGroup',
  'role',
  'about',
  'datatype',
  'inlist',
  'prefix',
  'property',
  'resource',
  'typeof',
  'vocab',
  'autoCapitalize',
  'autoCorrect',
  'autoSave',
  'color',
  'itemProp',
  'itemScope',
  'itemType',
  'itemID',
  'itemRef',
  'results',
  'security',
  'unselectable',
  'inputMode',
  'is',
];

/**
 * CSS breakpoints in pixels, from variables `grid-{size}` in file `../style/lib/variables.styl`.
 */
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

/**
 * CSS container max-widths from variables `container-{size}` in file `../style/lib/variables.styl`.
 */
export const containers = {
  sm: '100%',
  md: '100%',
  lg: '100%',
  xl: '1280px',
}

export const headerHeight = 83;

/**
 * Get value of a cookie by name.
 * @param {String} cname Name of cookie to extract.
 * @returns {String} Cookie value if cookie exists, otherwise empty string.
 */
export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * Set a cookie.
 * @param {String} cname Cookie's name.
 * @param {String} cvalue Cookie's value.
 * @param {Number} exdays Days until cookie's expiration.
 */
export const setCookie = (cname, cvalue, exdays) => {
  let d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Lax;secure";
}

/**
 * Delete a cookie.
 * @param {String} cname Cookie's name.
 */
export const deleteCookie = (cname) => {
  if (getCookie(cname)) {
    document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;SameSite=Lax;secure`;
  }
}

/**
 * Extract params from query string.
 * @param {String|String[]} params Params to get from query string.
 * @param {String} search Query string.
 * @returns {String|Object} Requested parameters.
 */
export const getQueryParams = (params, search) => {
  const format = typeof params;
  if (isEmpty(params)) return 'string' === format ? null : {}; // Short-circuit.

  const searchQuery = new URLSearchParams(search);

  let result = {};
  const paramArray = 'string' === format ? [params] : params;

  paramArray.forEach(param => {
    result[param] = searchQuery.get(param);
  });

  return 'string' === format ? result[params] : result;
}

/**
 * Generate function that'll take elements, optionally with modfifiers, and return BEM style classname, for given `block`.
 *
 * @param {String} block Block name to use in class attribute.
 * @returns {Function} Function in which each argument is either:
 * * `element`: string
 * * [`element`: string, ...`modifier`: string]
 *
 * For the array format, if `element` falsy, each `modifier` will apply directly to `block`.
 *
 * Falsy `element`s and `modifier`s aren't output.
 */
export const BEM = block =>
  (...elements) =>
    elements.length ?
      elements.map(
        element => {
          if (Array.isArray(element)) {
            const [el, ...mods] = element;

            const prefix = el ? `${block}__${el}` : block;
            return [prefix, ...mods.map(mod => mod ? `${prefix}--${mod}` : '')].join(' ')
          }
          return element ? `${block}__${element}` : '';
        }
      ).join(' ').trim() :
      block

/**
 * Build string form HTML `srcset` attribute out of WordPress sizes object.
 *
 * @param {Object} sizes Sizes object returned by WordPress api.
 * @returns {String|undefined} The `srcset` string if it could be built, `undefined` otherwise.
 */
export const srcsetStr = (size, sizes) => {
  let sizesArr = chain(sizes)
    .pickBy(isNaN)
    .keys()
    .uniqBy(size => `${sizes[`${size}-width`]}x${sizes[`${size}-height`]}`)
    .reduce((result, compare_size) => {
      if (matchesRatio(sizes[`${size}-width`], sizes[`${size}-height`], sizes[`${compare_size}-width`], sizes[`${compare_size}-height`]))
        result.push(`${sizes[compare_size]} ${sizes[`${compare_size}-width`]}w`);
      return result;
    }, [])
    .value()
  return sizesArr.length ? sizesArr.join(', ') : undefined;
}

/**
 * Helper function to test if aspect ratios for two images match.
 * Replicates
 * {@link https://developer.wordpress.org/reference/functions/wp_image_matches_ratio/ wp_image_matches_ratio}.
 *
 * @param {Number} source_width First image's width in pixels.
 * @param {Number} source_height First image's height in pixels.
 * @param {Number} target_width Second image's width in pixels.
 * @param {Number} target_height Second image's height in pixels.
 * @returns {Boolean} Whether the ratios match.
 */
export const matchesRatio = (source_width, source_height, target_width, target_height) => {
  let constrained_size;
  let expected_size;
  if (source_width > target_width) {
    constrained_size = constrainDimensions(source_width, source_height, target_width);
    expected_size = [target_width, target_height];
  } else {
    constrained_size = constrainDimensions(target_width, target_height, source_width);
    expected_size = [source_width, source_height];
  }

  return Math.abs(constrained_size[0] - expected_size[0]) <= 1 && Math.abs(constrained_size[1] - expected_size[1]) <= 1;
}

/**
 * Calculate new pixel dimensions for an image, maintaining aspect ratio.
 * Replicates functionality of
 * {@link https://developer.wordpress.org/reference/functions/wp_constrain_dimensions/ `wp_constrain_dimensions`}
 *
 * @param {Number} current_width Current image width in pixels.
 * @param {Number} current_height Current image height in pixels.
 * @param {Number} max_width Max width in pixels.
 * @param {Number} max_height Max height in pixels.
 * @returns {Number[]} Width and height in pixels.
 */
export const constrainDimensions = (current_width, current_height, max_width = 0, max_height = 0) => {
  if (!max_width && !max_height) {
    return [current_width, current_height];
  }

  let width_ratio = 1.0;
  let height_ratio = 1.0;
  let did_width = false;
  let did_height = false;

  if (max_width > 0 && current_width > 0 && current_width > max_width) {
    width_ratio = max_width / current_width;
    did_width = true;
  }

  if (max_height > 0 && current_height > 0 && current_height > max_height) {
    height_ratio = max_height / current_height;
    did_height = true;
  }

  // Calculate the larger/smaller ratios.
  let smaller_ratio = Math.min(width_ratio, height_ratio);
  let larger_ratio = Math.max(width_ratio, height_ratio);

  let ratio;
  if (Math.round(current_width * larger_ratio) > max_width || Math.round(current_height * larger_ratio) > max_height) {
    // The larger ratio is too big. It would result in an overflow.
    ratio = smaller_ratio;
  } else {
    // The larger ratio fits, and is likely to be a more "snug" fit.
    ratio = larger_ratio;
  }

  // Very small dimensions may result in 0, 1 should be the minimum.
  let w = Math.max(1, Math.round(current_width * ratio));
  let h = Math.max(1, Math.round(current_height * ratio));

  // Note: did_width means it is possible smaller_ratio == width_ratio.
  if (did_width && w === max_width - 1) {
    w = max_width; // Round it up.
  }

  // Note: did_height means it is possible smaller_ratio == height_ratio.
  if (did_height && h === max_height - 1) {
    h = max_height; // Round it up.
  }

  return [w, h]
}
export const isIE = () => /*@cc_on!@*/false || !!document.documentMode;

export const getDisplayName = Component => Component.displayName ||
  Component.name ||
  typeof Component === 'string' && Component ||
  'Component';

export class Err extends Error {
  /**
   * Extended Error class.
   * @param {Object} opts Error options.
   * @param {String} opts.name Error name for console.
   * @param {String} opts.message Error message.
   * @param {Number} opts.status HTTP Status code.
   * @param {String} opts.statusText HTTP Status text.
   */
  constructor({ name = 'Error', message = '', ...rest }) {
    super(message);
    this.name = name;
    Object.entries(rest).forEach(([key, value]) => {
      this[key] = value
    })
  }
}

/**
 * Determine url to use when sharing a page on social media.
 *
 * @param {String} url URL of shared page.
 * @returns {String}
 */
export function shareLink(url) {
  const { path } = getPath(url);
  return `${process.env.BASE_URL.replace(/\/$/, '')}${path}`;
}

/**
 * Get 'ugly' link to a post
 *
 * @param {String} url URL of shared post.
 * @param {String} type WordPress type of shared post.
 * @returns {String|undefined}
 */
export function uglyLink(id, type) {
  const base = process.env.BASE_URL.replace(/\/$/, '');
  switch (type) {
    case 'post':
      return `${base}?p=${id}`;
    case 'page':
      return `${base}?page_id=${id}`;
  }

  return undefined;
}

/**
 * Determine whether variable is valid date
 * @param {*} d Date to test
 * @returns {boolean}
 */
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

/**
 * Decide whether the embed source is trusted
 * @param {String} src `src` URL
 * @param {Object[]} trustedSources Array of trusted sources
 * @returns {Boolean}
 */
export function isTrustedEmbed(src, trustedSources) {
  let srcUrl
  try {
    srcUrl = new URL(src)
  } catch (error) {
    return false
  }

  return trustedSources && trustedSources.map(({ hostname = '' }) => hostname).includes(srcUrl.hostname)
}
