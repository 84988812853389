import React, { useState, useRef, useContext, forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import {
  Nav,
  NavItem,
} from "reactstrap";
import { AllHtmlEntities as Entities } from 'html-entities';
import { debounce, flatMap } from "lodash";
import { StringsContext } from "../Contexts/StringsContext";
import { whichKey } from '../../utils/a11y';
import { mod, handleRef } from "../../utils/misc";
import LinkSr from "../Common/LinkSr";

const entities = new Entities();

/**
 * Implement w3c's 
 * {@link https://www.w3.org/TR/wai-aria-practices-1.1/examples/menubar/menubar-1/menubar-1.html accessible menubar example},
 * but without support for nested submenus.
 *
 * @param {object} props Props.
 */
const Menu = props => {
  const { children: render, vertical, location, menuItems, menubar, maxDepth, className, dropdown, ...rest } = props;

  const [openedMenu, setOpenedMenu] = useState();

  const strings = useContext(StringsContext);

  const windowPath = window.location.pathname.split('/');

  const { current: linkRefs } = useRef(Array(menuItems.length));

  const [focusedLink, setFocusedLink] = useState();

  useEffect(() => {
    if (linkRefs[focusedLink]) {
      linkRefs[focusedLink].focus();
    }
  }, [focusedLink])

  /*
   * Debounce state change to address mobile devices' firing click immediately after mouseenter, 
   * which nullifies the state change.
   */
  const toggle = useRef(debounce(
    id => setOpenedMenu(id),
    150
  )).current

  return <Nav
    {...rest}
    role={menubar ? 'menubar' : 'menu'}
    vertical={vertical}
    className={classnames(
      'menu',
      'menu-' + location,
      { 'menu--alert-bar-active': strings.alert_banner.enabled },
      location + '-navlinks',
      className,
    )}
    {...menubar && {
      'aria-labelledby': `${location}-menubar-instructions`,
      onKeyDown: e => {
        const { currentTarget } = e;
        switch (whichKey(e)) {
          case 'Escape':
            toggle('');
            linkRefs[focusedLink].focus();
            break;

          case 'ArrowRight':
            setFocusedLink(mod(focusedLink + 1, linkRefs.length));
            toggle('');
            break;

          case 'ArrowLeft':
            setFocusedLink(mod(focusedLink - 1, linkRefs.length));
            toggle('');
            break;

          case 'Tab':
            setTimeout(() => {
              !currentTarget.contains(document.activeElement) && toggle('')
            });
            break;
          default:
            break;
        }
      }
    }}
  >
    {menubar && <span className="sr-only" id={`${location}-menubar-instructions`}>{strings.ui.roving_tabindex_instructions}</span>}
    {menuItems.map((item, i) => {
      return <MenuItem
        ref={el => linkRefs[i] = el}
        menubar={menubar}
        key={item.ID}
        item={item}
        render={render}
        {...dropdown && {
          dropdown: true,
          isOpen: openedMenu === item.ID,
          toggle: () => toggle(item.ID === openedMenu ? '' : item.ID),
          onMouseEnter: () => toggle(item.ID),
          onMouseLeave: () => toggle(''),
        }}
        {...menubar && {
          tabIndex: i === focusedLink ? 0 : -1,
          onKeyDown: e => {
            const keycode = whichKey(e);
            switch (keycode) {
              case 'ArrowRight':
                setFocusedLink(mod(focusedLink + 1, linkRefs.length));
                break;

              case 'ArrowLeft':
                setFocusedLink(mod(focusedLink - 1, linkRefs.length));
                break;

              case 'Home':
                e.preventDefault();
                setFocusedLink(0);
                break;

              case 'End':
                e.preventDefault();
                setFocusedLink(linkRefs.length - 1);
                break;

              default:
                break;
            }
          }
        }}
        args={{ maxDepth, location }}
        className={(windowPath[1] && windowPath[1] === item.path.split('/')[1]) ? 'activeParent' : undefined}
      />
    })}
  </Nav>
}

Menu.propTypes = {
  /** Array of menu item objects. */
  menuItems: PropTypes.array,
  /** Menu orientation. */
  vertical: PropTypes.bool,
  /** Maximum depth of menu items to display, zero-indexed. */
  maxDepth: PropTypes.number,
  /** Whether 0 level menu items are dropdowns. */
  dropdown: PropTypes.bool,
  /** Menu location. */
  location: PropTypes.string,
  /** Whether the menu is in a menubar. */
  menubar: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Render function to render menu item innards. You should render the `link` and `menu` arguments as passed to
   * maintain menu structure and accessibility.
   *
   * Arguments:
   *
   * 1. {ReactNode} link The link element (`<a>`)
   * 2. {ReactNode} menu The (sub)menu element (`<ul>`)
   * 3. {Object} item The menu item object.
   */
  children: PropTypes.func
};

Menu.defaultProps = {
  menuItems: [],
  location: "",
  vertical: false,
  maxDepth: 0,
};

export default Menu;

const MenuItem = forwardRef((props, ref) => {
  const {
    item,
    dropdownItem: isDropdownItem,
    className,
    isOpen,
    onClick = () => { },
    toggle = () => { },
    onKeyDown = () => { },
    menubar,
    tabIndex,
    render,
    dropdown: p_dropdown,
    args = {},
    ...p_itemProps
  } = props;

  const {
    children: p_children,
    external,
    externalNewTab,
    ID,
    title,
    path,
    parent,
    depth,
    classes,
    acf = {},
    ref: itemRef
  } = item;

  const { maxDepth } = args;
  const { menu_item_role: role } = acf;

  const { ui: { submenu_menu_item_description } = {} } = useContext(StringsContext);


  let children = p_children;

  if (maxDepth) {
    if (depth > maxDepth) return;
    if (depth == maxDepth) children = [];
  }

  const hasSubmenu = children.length > 0;
  const isDropdown = p_dropdown && hasSubmenu;

  let sublinkKeyDown = () => { }

  if (isDropdown && menubar && !depth) {
    const iteratee = item => {
      const { acf: { is_heading } = {} } = item;
      let items = [];
      if (!is_heading) {
        items.push(item);
      }
      if (item.children) {
        items.push(...flatMap(item.children, iteratee))
      }
      return items;
    }
    const { current: descendants } = useRef(flatMap(children, iteratee))

    const { current: linkRefs } = useRef(Array(descendants.length));

    // Set refs for all focusable children in a menubar submenu.
    useEffect(() => {
      descendants.forEach((item, i) => { item.ref = el => linkRefs[i] = el });
    }, [])

    const [focusedLink, setFocusedLink] = useState();

    // Focus first/no when opening/closing submenu.
    useEffect(() => {
      if (isOpen) setFocusedLink(0);
      else setFocusedLink();
    }, [isOpen])

    // Focus management.
    useEffect(() => {
      if (linkRefs[focusedLink]) linkRefs[focusedLink].focus();
    }, [focusedLink])

    // Keyboard accessiblity for submenu links.
    sublinkKeyDown = e => {
      const keyCode = whichKey(e);
      if (['Space', 'ArrowUp', 'ArrowDown', 'Home', 'End'].includes(keyCode)) e.preventDefault();
      switch (keyCode) {
        case 'Space':
          e.target.click();
          break;

        case 'ArrowUp':
          setFocusedLink(mod(focusedLink - 1, linkRefs.length));
          break;

        case 'ArrowDown':
          setFocusedLink(mod(focusedLink + 1, linkRefs.length));
          break;

        case 'Home':
          setFocusedLink(0);
          break;

        case 'End':
          setFocusedLink(linkRefs.length - 1);
          break;

        default:
          break;
      }
    };
  }


  /*------------------------------------------------------------
   * Menu
   *----------------------------------------------------------*/
  const Submenu = (children && hasSubmenu && depth != maxDepth) &&
    <Nav
      className={classnames([
        `nav--d${depth + 1}`,
        { 'nav-dropdown-menu': isDropdown },
        { show: isDropdown && isOpen }
      ])}
      role="menu"
      aria-labelledby={`nav_item-${ID}-label`}
    >
      {children.map((item) => <MenuItem
        key={item.ID}
        dropdownItem={isDropdown}
        args={args}
        item={item}
        menubar={menubar}
        render={render}
      />)}
    </Nav>


  /*------------------------------------------------------------
   * Item
   *----------------------------------------------------------*/
  const itemProps = {
    role: 'none',
    className: classnames([
      `nav-item--d${depth}`,
      className,
      ...classes.split(' '),
      { 'nav-dropdown': isDropdown },
      { 'nav-dropdown-item': isDropdownItem },
    ]),
    ...menubar && { onKeyDown: sublinkKeyDown }
  }

  /*------------------------------------------------------------
   * Link
   *----------------------------------------------------------*/
  let MenuLink;
  let linkProps = {
    // Boostrap removes focus from [tabindex="-1"] with !important, so use -2.
    tabIndex: undefined !== tabIndex ? tabIndex : menubar ? -2 : undefined,
    role: 'menuitem',
    className: classnames([
      "nav-link",
      { "dropdown-link": isDropdownItem },
      { "nav-dropdown-toggle": isDropdown },
      { "nav-link-top-level": !depth },
      { 'nav-link-external': external },
      `nav-link--d${depth}`,
    ]),
    children: <>
      <span id={`nav_item-${ID}-label`}>{entities.decode(title)}{external && <LinkSr external blank />}</span>
      {Boolean(parent) && <span className="sr-only" id={`nav_item-${ID}-description`}>{submenu_menu_item_description}</span>}
    </>,
    onClick: e => { e.stopPropagation(); onClick(e) },
    onKeyDown,
    'aria-labelledby': `nav_item-${ID}-label`,
    ...parent && {
      'aria-describedby': `nav_item-${ID}-description nav_item-${parent}-label`,
    }
  }

  /* 
   * Special case for CookiePro link. 
   * Must be a `<button>` because CookiePro's click handler 1) fires first, and 2) stops event propagation,
   * giving us no opportunity to modify the anchor link's behavior.
   */
  if ('cookie_preference_center_open' === role) {
    MenuLink = <button
      {...linkProps}
      aria-labelledby={undefined}
      className={`${linkProps.className} ot-sdk-show-settings`}
    />
  } else {
    if (isDropdown) {
      MenuLink = <a
        ref={el => [ref, itemRef].forEach(ref => handleRef(ref, el))}
        {...linkProps}
        href="#"
        onClick={e => { e.preventDefault(); toggle(e); onClick(e) }}
        aria-expanded={isOpen}
        aria-haspopup="true"
        {...menubar && !depth && {
          // Keyboard accessibility for menubar links/toggles.
          onKeyDown: e => {
            switch (whichKey(e)) {
              case 'ArrowDown':
                e.preventDefault()
                !isOpen && toggle(e);
                break;

              case 'ArrowUp':
                e.preventDefault()
                !isOpen && toggle(e);
                break;

              case 'Space':
                e.preventDefault()
                !isOpen && toggle(e);
                break;

              default:
                break;
            }
            onKeyDown(e);
          }
        }}
      />
    } else if (external) {
      MenuLink = <a
        ref={el => [ref, itemRef].forEach(ref => handleRef(ref, el))}
        {...linkProps}
        {...externalNewTab && {
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
        href={path}
      />
    } else {
      MenuLink = <NavLink innerRef={el => [ref, itemRef].forEach(ref => handleRef(ref, el))} {...linkProps} to={path} />
    }
  }

  return <NavItem {...p_itemProps} {...itemProps}>
    {'function' === typeof render
      ? render(MenuLink, Submenu, item)
      : <>
        {MenuLink}
        {Submenu}
      </>
    }
  </NavItem>
})

MenuItem.displayName = 'MenuItem';

MenuItem.propTypes = {
  /** Standard menu item properties, e.g. title, link. */
  item: PropTypes.object.isRequired,
  /** Whether this item should attempt to use dropdown functionality. */
  dropdown: PropTypes.bool,
  /** Whether this item is in a dropdown menu. */
  dropdownItem: PropTypes.bool,
  /** Whether item's dropdown menu is open. */
  isOpen: PropTypes.bool,
  /** Menu arguments for this menu item and its descendants. */
  args: PropTypes.object,
  /** Handler for item's dropdown toggle. */
  toggle: PropTypes.func,
  /** Whether item is in a menubar. */
  menubar: PropTypes.bool,
  /** Index of menu item in (sub)menu. */
  index: PropTypes.number,
  /** Length of parent (sub)menu. */
  length: PropTypes.number,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  /** Render function for menu item */
  render: PropTypes.func
}