import React from "react";
import Menu from "../Navigation/Menu";
import CountryNav from "./CountryNav";
import SocialNav from "./SocialNav";
import PropTypes from "prop-types";
import {withStrings} from "../Contexts/StringsContext";

class UtilityNav extends React.Component {
    render() {
        const { menu, countries, thisClass } = this.props;

        return (
            <div className={`utility-nav ${thisClass}`}>
                <div className="utility-nav__container container">
                    <Menu menuItems={menu} location='utility' />
                    <CountryNav className="utility-nav__countries" countries={countries} />
                    <SocialNav className="utility-nav__social" />
                </div>
            </div>
        );
    }
}

UtilityNav.propTypes = {
    strings: PropTypes.object,
    menu: PropTypes.array,
    countries: PropTypes.array,
	thisClass: PropTypes.string,
};
  
UtilityNav.defaultProps = {
    menu: [],
    countries: [],
	thisClass: '',
};

export default withStrings( UtilityNav );
