import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";

class Lightbox extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = { isOpen: null };
  }

  toggle(index) {
    this.setState({
        isOpen: index,
    });
  }
  closeModal() {
    this.setState({
        isOpen: null
    });
  }
  render() {
    const { lightbox, section_title } = this.props;
    const modalContent = this.props.lightbox[this.state.isOpen];
    return (
      <div className='lightbox-content centered'>
        <div className={section_title ? 'section-title' : ''}>
            {section_title}
        </div>
        <Row className='lightbox-items-container' >
          {lightbox.map((content, index) =>
            <Col xs='12' sm='6' lg='4' xl='3'
              key={index}
              className='lightbox-item'
              onClick={() => this.toggle(index)}
              >
              <img className='lightbox-item-img'
                   src={content.image.url}
                   alt={content.image.alt ? content.image.alt : content.image.title || ''} />
              <div className='lightbox-item-content'>
                <div className='title'>{content.title},</div>
                <div className='subtitle'>{content.subtitle}</div>
              </div>
            </Col>
          )}
        </Row>
        {this.state.isOpen !== null ?
          <Modal isOpen={this.state.isOpen !== null}
                 toggle={this.closeModal}
                 size='lg'
                 className='lightbox-modal'
                 scrollable >
            <ModalHeader toggle={this.closeModal} />
            <ModalBody className='lightbox-modal-content'>
              <div className='modal-copy'>
                <div className='title'>{modalContent.title}</div>
                <div className='subtitle'>{modalContent.subtitle}</div>
                <div className='description'>{modalContent.long_description}</div>
              </div>
              <div className='modal-img'
                   style={{backgroundImage: `url(${modalContent.image.url})`}}
                   title={modalContent.image.alt ? modalContent.image.alt : modalContent.image.title || ''} />
            </ModalBody>
          </Modal>
        : null }
      </div>
    );
  }
}

Lightbox.propTypes = {
  lightbox: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  section_title: PropTypes.string
};

Lightbox.defaultProps = {
  lightbox: [],
  section_title: ""
};

export default Lightbox;
