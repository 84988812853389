import React from 'react';
import PropTypes from 'prop-types';

const Copy = ({ children, className, tag }) => {
  if (!children) {
    return null;
  }
  const CopyElement = tag;
  return <CopyElement className={className}>{children}</CopyElement>
};

Copy.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  tag: PropTypes.string,
};

Copy.defaultProps = {
  children: '',
  className: 'body-copy',
  tag: 'div',
};

export default Copy;
