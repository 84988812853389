import * as React from "react";
import PropTypes from "prop-types";
import { withStrings } from "../../Contexts/StringsContext";
import Loading from "../../Common/Loading";
import RichText from "./RichText";
import { StriveRequestForm } from "./StriveRequestForm";

class StriveRequestWrapperBase extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.forceUpdate();
  }


  render() {
    const { method, url, strings } = this.props;
    const { strive_request_form } = this.props.strings;

    if (!strive_request_form) {
      return <Loading />;
    }
    return <div className="external-funding-form">
      <div className="eff__preface-text">
        <RichText wysiwyg={strive_request_form.form_text.preface_text} />
      </div>
      <div className="centered">
        <StriveRequestForm {...{ method, url, strings }} />
      </div>
    </div>
  }
}

StriveRequestWrapperBase.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
}

export const StriveRequestFormWrapper = withStrings(StriveRequestWrapperBase);
StriveRequestFormWrapper.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
