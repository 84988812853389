import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import Menu from "../Navigation/Menu";
import SocialNav from "../Navigation/SocialNav";
import ParsedWysiwyg from '../Common/ParsedWysiwyg';
import { withStrings } from "../Contexts/StringsContext";
import { range as _range } from "lodash";
import { sprintf } from "sprintf-js";

const today = new Date()

const FooterContainer = ({ menus, strings }) => {

  return (
    <footer className='site-footer'>
      <Container className='site-footer-container'>
        <Row className="justify-content-center">
          <Col md="10" className="site-footer-col-main">
            <Row className='site-footer-main justify-content-between'>
              <Col lg='9' className="site-footer-col-menu">
                <div className="mx-lg-n3 d-lg-flex justify-content-between flex-nowrap">
                  {_range(0, 3).map(col => {
                    const location = `footer-primary${col ? `-${col}` : ''}`;
                    return <Menu
                      key={location}
                      menuItems={menus[location]}
                      location={location}
                      vertical
                    />
                  }
                  )}
                </div>
              </Col>
              <Col sm="auto" lg="3" className="site-footer-col-social">
                <SocialNav className="site-footer-social-links" alternative />
              </Col>
            </Row>
            <div className='site-footer-site-info'>
              <img src='/resources/img/PTC_logo_footer.png' alt="PTC Therapeutics" />
              <div className='site-footer-site-info--text'>
                <span className='copyright site-footer-copyright'>
                  <ParsedWysiwyg htmlString={sprintf(strings.footer?.copyright, { year: today.getFullYear() })} />
                </span>
                <Menu
                  menuItems={menus["footer-tertiary"]}
                  location='footer-tertiary'
                />
              </div>
            </div>
          </Col>
        </Row>
        {(strings.footer.disclaimer) &&
          <Row className="justify-content-center">
            <Col md="10">
              <div className="site-footer-disclaimer">
                <ParsedWysiwyg htmlString={strings.footer.disclaimer} />
              </div>
            </Col>
          </Row>
        }
      </Container>
    </footer>
  );
};

FooterContainer.propTypes = {
  menus: PropTypes.object,
  strings: PropTypes.object,
};

FooterContainer.defaultProps = {
  menus: {
    "footer-primary": [],
    "footer-primary-1": [],
    "footer-primary-2": [],
    "footer-tertiary": [],
  }
};

export default withStrings(FooterContainer);
