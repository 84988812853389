import { stringify } from 'query-string';
import { isEmpty as _isEmpty, transform as _transform, find as _find, isSet } from 'lodash';
import { getCookie, setCookie, getQueryParams, deleteCookie } from '../utils/misc';
import { handleResponse, fetchJwt } from './index';

/**
 * Fetch FacetWP filters.
 * @param {Object} params Settings for the request.
 * See the {@link https://facetwp.com/introducing-the-facetwp-rest-api/ FacetWP reference} for details.  
 * @param {Object} params.facets Facet names and values.
 * @param {Object} params.query_args Arguments for the WordPress query.
 * @param {Object} params.settings FacetWP settings.
 */
export const fetchFacets = async (params) => {
  // Try using cookie, otherwise get token.
  const token = getCookie('fwp_token') || await getFwpJwt();

  return fetch(`${process.env.BASE_URL}${process.env.FWP_API_PATH}/fetch`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: stringify({ data: JSON.stringify(params) })
  })
    .then(handleResponse)
    .catch((data) => {
      const { error: { code } } = data;
      if ('jwt_auth_invalid_token' === code) {
        deleteCookie('fwp_token');
        return fetchFacets(params);
      } else {
        return Promise.reject(data);
      }
    })
}

/**
 * Fetch FacetWP settings.
 * @typedef {'facets'|'prefix'|'sources'|'sort_options'} Field
 * @param {Field|Field[]} include Optionally specify settings to fetch. Defaults to all.
 * @returns {Promise} Object of settings.
 */
export const fetchFwpSettings = (include = []) => {
  let queryString = _isEmpty(include) ? '' : `?${stringify({ include: include }, { arrayFormat: 'comma' })}`;
  return fetch(`${process.env.BASE_URL}${process.env.PTC_API_PATH}/facetwp${queryString}`)
    .then(handleResponse)
};

/**
 * Get a JSON Web Token for FacetWP. Sets the cookie `fwp_token` to the token.
 * @returns {Promise} String containing valid token.
 */
export const getFwpJwt = async () => {
  return fetchJwt(process.env.FWP_USERNAME, process.env.FWP_PASSWORD)
    .then(({ data: { token } }) => {
      setCookie('fwp_token', token, 7); // JWT tokens last 7 days.
      return token;
    })
}

/**
 * Extract FacetWP parameters from the query string.
 * @param {Array} facets Names of the facets to extract from query string.
 * @param {String} search The query string.
 * @param {String} prefix FacetWP prefix.
 * @returns {Object} Object of facet values keyed by facet name.
 */
export const extractFwpParams = (facets, search, prefix) => {
  // Unable to get prefix
  if (!prefix || !search || !facets.length) return {};

  return _transform(
    getQueryParams(facets.map(facet => `${prefix}${facet}`), search),
    (res, val, key) => {
      if (null === val) return;

      let newKey = key.slice(prefix.length);
      let newVal = val;

      if (-1 !== val.indexOf(',')) newVal = val.split(',');

      res[newKey] = newVal;
    },
    {}
  );
}

/**
 * 
 * @param {Object.<string, String[]>} fields Object of values to apply, keyed by facet name
 * @param {String} prefix FacetWP prefix set in WordPress.
 */
export const buildFwpParams = (fields, prefix) => {
  if (undefined === prefix) return '';
  const paramString = stringify(
    _transform(
      fields,
      (obj, val, key) => {
        if (! val || ('object' === typeof val && _isEmpty(val))) return;

        if (isSet(val)) val = Array.from(val)

        obj[`${prefix}${key}`] = val
      },
      {}
    ),
    { arrayFormat: "comma" }
  );

  return paramString ? `?${paramString}` : '';
}

/**
 * Get names of facets for the filters on the posts page.
 * Usually we'd identify facets by name, but we're allowing editors to change that for translation purposes.
 * Instead, we identify facets by type, source, and context (a custom setting).
 *
 * @param {Object[]} facets Facet settings.
 * @returns {Object.<String, String>} Object where keys are taxonomy slug, values are corresponding facet name.
 */
export const mapTaxonomyFacets = facets => facets && Array.isArray(facets) ?
  Object.assign(
    ...Object.entries({
      // Facet-specific parameters
      'media-type': { key: 'facet_6165a349bf2e4' },
      'category': { key: 'facet_6165a1f5eee98' },
      'therapeutic-area': { key: 'facet_6165a32b94a93' }
    }).map(
      ([taxSlug, facetParameters]) => {
        const facet = _find(facets, facetParameters);
        return facet ? { [taxSlug]: facet.name } : {};
      }
    )
  ) :
  {};