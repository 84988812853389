import React, { createRef } from "react";
import SearchResult from "./SearchResult";
import { fetchSearchResults } from "../../../api";
import Loading from "../../Common/Loading";
import PropTypes from "prop-types";
import smoothscroll from 'smoothscroll-polyfill';
import Pagination from '../../Common/Pagination';
import { withStrings } from "../../Contexts/StringsContext";
import { withRouter } from "react-router-dom";

// Fixes scrolling for IE
smoothscroll.polyfill();

const DEFAULT_PER_PAGE = 10;

class SearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page,
      isLoading: false,
      loaded: false,
    };

    this.firstResult = createRef();
  }

  getResults() {
    this.setState({ isLoading: true });
    const { term } = this.props;
    const { page = 1 } = this.state;

    this.props.onSearching?.({
      term,
      page
    })

    let results;
    if (term) {
      results = fetchSearchResults(term, parseInt(page))
        .then(({ headers, data }) => ({
          results: data,
          pageTotal: +headers.get("X-WP-TotalPages"),
          resultsTotal: +headers.get("X-WP-Total")
        }))
    } else {
      results = Promise.resolve({})
    }

    results.then(({ results, pageTotal, resultsTotal }) => {
      this.setState({
        results,
        pageTotal,
        resultsTotal,
        loaded: true,
        isLoading: false,
      });

      this.props.onSearched?.(resultsTotal, pageTotal);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { term } = this.props;
    const { page } = this.state;
    const { term: p_term } = prevProps;

    // New search term
    if (term !== p_term) {
      this.setState({
        page: 1,
      }, () => {
        this.getResults()
      })
    }

    // New page
    if (page && page !== prevState.page) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      this.firstResult.current && this.firstResult.current.focus();
    }
  }

  componentDidMount() {
    this.getResults();
  }

  handlePageChange = ({ current: pageNum }) => {
    const { history, term } = this.props;
    fetchSearchResults(term, pageNum).then(({ data }) => {
      this.setState({
        results: data,
        page: pageNum
      })
      history.push(`/search?term=${encodeURIComponent(term)}&page=${pageNum}`);
    })
  };

  render() {
    const { results = [] } = this.state
    const { term, strings = {} } = this.props;
    const { search: { no_term, no_term_subtext } = {} } = strings;

    if (!location.pathname.match(/^\/search(\/\?.*)?/)) return;
    if (this.state.isLoading) return <Loading />;

    if (!term && no_term) return <div className="search-results__empty empty-area">
      <div className="empty-area__title">{no_term}</div>

      {no_term_subtext
        && <div className="empty-area__subtitle">
          {no_term_subtext}
        </div>
      }
    </div>

    return results.length > 0 &&
      <div className='search-results'>
        {results.map((result, i) => (
          <SearchResult ref={!i ? this.firstResult : undefined} key={result.id} result={result} />
        ))}
        <Pagination
          className='search-results__pagination'
          current={this.state.page}
          totalRecords={parseInt(this.state.resultsTotal)}
          midSize={2}
          onPageChanged={this.handlePageChange}
          perPage={DEFAULT_PER_PAGE}
        />
      </div>
  }
}
SearchResults.propTypes = {
  term: PropTypes.string,
  searchQuery: PropTypes.string,
  page: PropTypes.number,
  pageTotal: PropTypes.number,
  results: PropTypes.array,
  searchHistory: PropTypes.func,
  /** Callback after search has completed. */
  onSearched: PropTypes.func,
  /** Callback before search starts. */
  onSearching: PropTypes.func,
  strings: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

SearchResults.defaultProps = {
  page: 1,
}

export default withRouter(withStrings(SearchResults));
