/**
 * Components for simple input types.
 *
 * @package frontend-ui
 */
import * as React from "react";
import PropTypes from "prop-types";
import { SvgX } from "./Svgs";
import { InputOptions, maybeClassName, ValueType, setRef } from "../../utils/inputs";
import InputWithPlaceholder from './InputWithPlaceholder';

export const Checkbox = ({ label, icon: Icon = <SvgX />, validate, labelProps = {}, onChange, ...props }) => {
  const { required, value } = props;

  // Assume that value == checked.
  const checked = value;

  // Has the user interacted with this field?
  const [dirty, setDirty] = React.useState(false);

  // Ref for checkbox `<input>`
  const inputRef = React.useRef();

  // Is data valid?
  const dataValid = validate ? validate(value, inputRef.current) : true;

  // Is input valid (passed data validation and other requirements)?
  const inputValid = dataValid &&
    (!required || !(dirty && !checked));

  return <label className="checkbox__container">
    <div className="checkbox__input">
      <input
        {...props}
        ref={inputRef}
        type="checkbox"
        onChange={e => {
          setDirty(true);
          onChange && onChange(e)
        }}
        aria-invalid={!inputValid}
      />
      <div className="checkbox__checkbox checkbox"></div>
      <div className="checkbox__icon">
        {Icon}
      </div>
    </div>
    <div {...labelProps} className={`checkbox__label${maybeClassName(labelProps.className)}`}
      dangerouslySetInnerHTML={{ __html: label }} />
  </label>
}

Checkbox.propTypes = {
  /** Props for element containing `label`. */
  labelProps: PropTypes.object,
  /**
   * Value validation function
   * @param {Event} event Event object.
   * @param {Element} element Ref to element that emitted the event.
   */
  validate: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  label: PropTypes.string,
  icon: PropTypes.node,
}

export const RadioButton = React.forwardRef(({ label, ...props }, ref) =>
  <label className="radio-button__container">
    <input ref={ref} type="radio" {...props} />
    <div className="radio-button__checkbox checkbox"></div>
    <div className="radio-button__label">
      {label}
    </div>
  </label>);

RadioButton.displayName = 'RadioButton';
RadioButton.propTypes = {
  label: PropTypes.string,
}

export const RadioButtons = React.forwardRef(({ horizontal, options, onChange, onKeyDown, value, ...props }, ref) =>
  <div
    className={`radio-buttons__container${horizontal ? ' radio-buttons__container--horizontal' : ''}`}
    onKeyDown={onKeyDown && (e => onKeyDown(e, options.find(opt => value === opt.value)))}
  >
    {options.map((opt, i) => (
      <RadioButton
        key={i}
        ref={0 === i ? ref : undefined}
        {...props}
        onChange={onChange && (e => onChange(e, opt))}
        label={opt.label}
        value={opt.value}
        checked={value === opt.value}
      />
    ))}
  </div>);

RadioButtons.displayName = 'RadioButtons';
RadioButtons.propTypes = {
  horizontal: PropTypes.bool,
  options: InputOptions.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  value: PropTypes.any,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
};

export class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.value = props.value;
    if (props.value != null) {
      this.updateAttributes(props);
    } else {
      this.empty = true;
    }
    this.input = React.createRef();
  }
  static defaultProps = {
    submitOnEnter: true,
  };

  updateAttributes = props => {
    let update = false;
    let { value, validate = this.validate, required } = props;
    value = value == null && this.input.current ? this.input.current.value : value;
    const invalid = validate ? !validate(value, this.input.current) : false;
    const empty = value == null || value === "";
    const dirty = this.dirty || !this.empty;
    const valid = required
      ? !(this.dirty && this.empty) && !invalid
      : this.empty || !invalid;

    if (
      this.value !== value ||
      this.empty !== empty ||
      this.dirty !== dirty ||
      this.valid !== valid
    ) {
      update = true;
    }

    this.value = value;
    this.empty = empty;
    this.dirty = dirty;
    this.valid = valid;
    update && this.forceUpdate();
  };

  onChange = e => {
    this.props.onChange && this.props.onChange(e);
    this.updateAttributes({
      value: e.target.value,
      required: this.props.required
    });
  };

  onKeyDown = e => {
    if (e.key && e.key === "Enter") {
      if (this.props.submitOnEnter) {
        this.props.onSubmitInput && this.props.onSubmitInput(this.props.value);
      } else {
        e.stopPropagation();
        e.preventDefault();
      }
    }
    this.props.onKeyDown && this.props.onKeyDown(e.nativeEvent);
    return this.props.submitOnEnter;
  };

  componentDidUpdate(prevProps) {
    const { value, validate } = this.props;
    const { value: pValue, validate: pValidate } = prevProps;
    if (
      pValue !== value ||
      pValue !== this.value ||
      (validate && validate(value, this.input.current)) !==
      (pValidate && pValidate(pValue, this.input.current))
    ) {
      this.updateAttributes(prevProps);
    }
  }

  render() {
    const {
      value,
      innerRef,
      // eslint-disable-next-line no-unused-vars
      validate, submitOnEnter, onSubmitInput,
      ...props
    } = this.props;
    return (
      <InputWithPlaceholder
        ref={el => { this.input.current = el; setRef(el, innerRef); }}
        data-value={this.value}
        data-empty={this.empty}
        data-valid={this.valid}
        aria-invalid={undefined === this.valid ? false : !this.valid}
        data-dirty={this.dirty}
        value={value}
        {...props}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}
TextInput.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  validate: PropTypes.func,
  onSubmitInput: PropTypes.func,
  submitOnEnter: PropTypes.bool,
  value: ValueType,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};

/**
 * Functional component that renders title, icon, and error elements as siblings to input Element(s) passed in children.
 * @param {Object} props
 */
export const InputWrapper = ({ title, required, invalid, error, className, titleProps, children }) => (
  <div className={`input__wrapper${maybeClassName(className)}`} data-invalid={invalid}>
    {title && <div {...titleProps} className="input__wrapper__title">
      {title}{required ? <span data-required aria-hidden="true">*</span> : ""}
    </div>}
    {children}
    <div className="input__wrapper__icon">
      <SvgX />
    </div>
    <div className="input__wrapper__error">
      <div className="input__wrapper__error__text">{error}</div>
    </div>
  </div>
);
InputWrapper.propTypes = {
  titleProps: PropTypes.object,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
};
