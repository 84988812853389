import React from "react";

const StringsContext = React.createContext({});
StringsContext.displayName = 'Strings';
const { Provider: StringsProvider, Consumer: StringsConsumer } = StringsContext;

// eslint-disable-next-line react/display-name
const withStrings = Component => props => {
  const Wrapped = (strings) => <Component {...props} strings={strings}/>;
  Wrapped.displayName = `withStrings(${Component.displayName})`;
  return <StringsConsumer>{Wrapped}</StringsConsumer>;
};

export { StringsContext, StringsProvider, StringsConsumer, withStrings };
