import React from "react";
import PropTypes from "prop-types";
import BackgroundWrapper from "./BackgroundWrapper";
import { entities } from "../../utils/text";
import { withStrings } from "../Contexts/StringsContext";
import { maybeClassName } from "../../utils/inputs"
/* eslint-disable import/no-unresolved */
import header_shape from '../../resources/img/PTC_shape-header-crop.svg?url'
import { Col, Row } from "reactstrap";

const PageHeader = ({ title, useContainer, className, image, children, section, after }) => {
  return (
    <header className={`page-header page-header--light${maybeClassName(className)}${maybeClassName(!image && 'page-header--default-image')}`}>
      <BackgroundWrapper
        className="page-header__inner"
        {...image !== undefined && (image ?
          {
            background: image,
            size: 'banner',
            srcSet: { banner: "(min-width: 768px)", 'banner-mobile': '' }
          } :
          {
            background: { 'aria-hidden': true, url: header_shape, sizes: { original: header_shape } }
          })
        }
        useContainer={useContainer}
      >
        <Row className="justify-content-center">
          <Col sm="10">
            {children ||
              <>
                {section && <p className="page-header__subheading">{entities.decode(section)}</p>}
                <h1 className='page-header__heading page-title'>{entities.decode(title)}</h1>
              </>
            }
            {after}
          </Col>
        </Row>
      </BackgroundWrapper>
    </header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  section: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  image: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  strings: PropTypes.object,
  useContainer: PropTypes.bool,
  className: PropTypes.string,
  /** Main slot. Default is page section and title. */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** Slot after main slot */
  after: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

PageHeader.defaultProps = {
  title: "",
  section: "",
  useContainer: true,
};

export default withStrings(PageHeader);
