import React from 'react';
import PropTypes from 'prop-types';
import Img from '../../Common/Img';
import Copy from '../../Common/Copy';

const Image = ({ section_title, image }) => {

  if (!image) {
    return null;
  }

  return (
    <figure className="image-content centered">
      {section_title &&
        <div className={section_title ? 'section-title' : ''}>
          {section_title}
        </div>
      }
      <Img {...image} />
      <Copy tag="figcaption" className="figure-caption">{image.caption}</Copy>
    </figure>
  );
};

Image.propTypes = {
  section_title: PropTypes.node,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

export default Image;
