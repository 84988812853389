import React from 'react';
import PropTypes from 'prop-types';
import ParsedWysiwyg from '../../Common/ParsedWysiwyg';
import { maybeClassName } from '../../../utils/inputs';

const RichText = ({ wysiwyg, className, ...rest}) => {
  if (!wysiwyg) {
    return null;
  }

  return (
    <div className={`wysiwyg-content centered${maybeClassName(className)}`}>
      <ParsedWysiwyg {...rest} htmlString={wysiwyg} />
    </div>
  );
};

RichText.propTypes = {
  wysiwyg: PropTypes.string,
  className: PropTypes.string,
};

export default RichText;
