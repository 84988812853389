import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

/**
 * Base React component for rendering SVGs
 */
export default class Icon extends PureComponent {
  render() {
    const { glyph, ...restProps } = this.props;

    return (
      <svg {...restProps}>
        <use xlinkHref={`#${glyph}`} />
      </svg>
    );
  }
}

Icon.propTypes = {
  glyph: PropTypes.string
}

Icon.defaultProps = {
  glyph: ''
};