import React from "react";
import PropTypes from "prop-types";
import ParsedWysiwyg from '../Common/ParsedWysiwyg';
import {withStrings} from "../Contexts/StringsContext";

class AlertBanner extends React.Component {
	render() {
		if( this.props.strings.alert_banner.enabled ) {
			return (
				<div className="alert-banner">
					<ParsedWysiwyg htmlString={this.props.strings.alert_banner.copy} />
				</div>
			);
		} else {
			return false;
		}
  }
}

AlertBanner.propTypes = {
	strings: PropTypes.object,
};

export default withStrings( AlertBanner );
