import PropTypes from "prop-types";
import { uniq as _uniq, without as _without } from 'lodash';

/** Imported from Mindgrub components */

/**
 * Output class with prepended space, or empty string, depending on condition.
 *
 * **Warning**: `arguments.length` doesn't seem to work correctly when this function is a const.
 *
 * @param {String} str Class to possibly output.
 * @param {*} cond Optional condition that determines whether to output `str`. If omitted, `str` itself is used as the condition.
 */
export function maybeClassName(str, cond) {
  return arguments.length == 1 ?
    str ? ` ${str}` : "" :
    cond && str ? ` ${str}` : "";
}

/** Wrap a handler so that it first run any existing handler for the specified
 * prop.  This is useful when creating drop-in replacements for standard
 * components (i.e. ones where certain props have defined semantics). */
export const handle_after = (target, name, handler) => event => {
  if (typeof target.props[name] === "function") target.props[name](event);
  handler.call(target, event);
};

export const project_from_list = (list, query, project) => {
  // find the item in the list that matches the query
  const index = list.findIndex(
    item =>
      Object.entries(query).filter(([key, value]) => item[key] !== value)
        .length === 0
  );
  if (index === -1) return null;

  // if a projection is specified, pick those props, otherwise return the whole object
  return (!project
    ? list[index]
    : Array.isArray(project)
      ? pick(project, list[index])
      : list[index][project]);
};

export const pick = (keys, obj) => {
  let ret = {};
  keys.forEach(k => {
    if (Object.keys(obj).includes(k)) {
      ret[k] = obj[k];
    }
  });
  return ret;
};

export const get_label_from_value = (options, value) =>
  project_from_list(options, { value }, "label");

export const get_value_from_label = (options, label) =>
  project_from_list(options, { label }, "value");

export const onAction = (action, preventDefault) => e => {
  preventDefault && e.preventDefault();
  action && action(e);
};

// https://emailregex.com/
export const EMAIL_PATTERN = "^(([^<>()[\\].,;:\\s@\"]+(\\.[^<>()[\\].,;:\\s@\"]+)*)|(\".+\"))@(([0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3})|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z][a-zA-Z]+))$";
//export const ZIP_PATTERN = "^[0-9]{5}$";
//export const TAX_ID_PATTERN = "^(([0-9]{2}-[0-9]{7})|([0-9]{3}-[0-9]{2}-[0-9]{4}))$";
// https://stackoverflow.com/a/18029630
export const PHONE_PATTERN =
  "^(\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|" +
  "2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|" +
  "4[987654310]|3[9643210]|2[70]|7|1)[./\\- ])?" +
  "(\\d[./\\- ]?){8}(\\d{1,2})$";
// export const DATE_PATTERN = "^\\d{2}([./-])\\d{2}\\1\\d{4}$";

export const EMAIL_REGEX = new RegExp(EMAIL_PATTERN);
//export const ZIP_REGEX = new RegExp(ZIP_PATTERN);
export const PHONE_REGEX = new RegExp(PHONE_PATTERN);
//export const TAX_ID_REGEX = new RegExp(TAX_ID_PATTERN);
// export const DATE_REGEX = new RegExp(DATE_PATTERN);

export const setRef = (el, ref) => {
  const refType = typeof ref;
  switch (refType) {
    case 'function':
      ref(el)
      break;
    case 'object':
      ref.current = el;
  }
}

export const format_phone_number = str => {
  if (!str || !PHONE_REGEX.test(`${str}`)) {
    return null;
  }
  let num = `${str}`;
  let code = num.match(/^\+\d*\W/);
  if (code && code[0]) {
    code = code[0];
    num = num.slice(code.length);
  }
  num = num.replace(/[^0-9]/gi, "");
  return `${code ? `${code.slice(0, -1)} ` : ""}${num}`
};

/**
 * Shorthand to insert or delete value from array based on boolean.
 * Useful for checkboxes, multi-selects.
 *
 * @param {*} value Value to insert/delete from array
 * @param {Array} values Array of values
 * @param {Boolean} add True to insert into array, false to delete from array.
 */
export const updateArray = (value, values, add) => {
  return add ?
    _uniq([...values, value]) :
    _without(values, value);
}

export const ValueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
export const InputOption = PropTypes.shape({
  value: ValueType.isRequired,
  label: PropTypes.string.isRequired
});
export const InputOptions = PropTypes.arrayOf(InputOption);

export const COUNTRIES = [
  "United States",
  "Canada",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegowina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia (Hrvatska)",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "France, Metropolitan",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and Mc Donald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint LUCIA",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia (Slovak Republic)",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "St. Helena",
  "St. Pierre and Miquelon",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zambia",
  "Zimbabwe",
].map(c => ({ value: c.trim(), label: c.trim() }));

export const US_STATES = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
  { value: "AS", label: "American Samoa" },
  { value: "GU", label: "Guam" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "PR", label: "Puerto Rico" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "VI", label: "Virgin Islands" },
];

export const CANADIAN_PROVINCES = [
  { value: "AB", label: "Alberta" },
  { value: "BC", label: "British Columbia" },
  { value: "MB", label: "Manitoba" },
  { value: "NB", label: "New Brunswick" },
  { value: "NL", label: "Newfoundland and Labrador" },
  { value: "NS", label: "Nova Scotia" },
  { value: "ON", label: "Ontario" },
  { value: "PE", label: "Prince Edward Island" },
  { value: "QC", label: "Quebec" },
  { value: "SK", label: "Saskatchewan" },
  { value: "NT", label: "Northwest Territories" },
  { value: "NU", label: "Nunavut" },
  { value: "YT", label: "Yukon" },
];

export const DESIGNATION = [
  {value: "PhD", label: "PhD"},
  {value: "MD", label: "MD"},
  {value: "PharmD", label: "PharmD"},
  {value: "Other", label: "Other"}
];

