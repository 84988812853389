/**
 * PageWrapper
 *
 * Implements standard Page behaviors:
 * - add `<PageHeader>`
 * - perform DocCheck authentication.
 * - load appropriate page template.
 *
 * @package ptc-therapeutic
 */
import React from 'react';
import PropTypes from 'prop-types';
import PageDefault from '../PageTemplates/Default';
import { Container, Row, Col } from 'reactstrap';
import DocCheck from './DocCheck';
import Loading from './Loading';
import PageHeader from './PageHeader';
import PageNotFound from '../Templates/NotFound';
import { templateMap } from '../../utils/templates';
import { omit, get } from 'lodash';
import { parse, stringify } from 'query-string';

/**
 * React Component PageWrapper.
 */
class PageWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      template: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;
    if (!isLoading && prevProps.isLoading !== isLoading) {
      this.getTemplate()
    }
  }

  /**
   * Reset template upon props.location change BEFORE render, 
   * otherwise PageWrapper attempts to render old page template using new page data.
   * 
   * @param {object} props Incoming props
   * @param {object} state Current state
   */
  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.pathname) {
      return {
        pathname: props.location.pathname,
        template: false,
      }
    }
    return null;
  }

  getTemplate() {
    const { template, page_type } = this.props;
    this.setState({
      template: get(templateMap, `type.${page_type}.component`) ||
        get(templateMap, `template.${template.replace(/\.\w+$/, '')}.component`) ||
        PageDefault,
    })
  }

  render() {
    const { location, history, loaded, id, title = {}, acf = {}, section, error, isLoading } = this.props;
    const { banner_image, dc_embed, dc_content } = acf;
    const { template } = this.state;

    const homepage = location.pathname === "/";

    const urlParams = new URLSearchParams(location.search);
    const dc = urlParams.get('dc');

    // Remove authorization query parameters added by DocCheck, for semblance of security.
    if (null !== dc) {
      const newSearch = stringify(omit(parse(location.search), 'dc', 'dc_timestamp'));
      history.replace(
        `${location.pathname}${newSearch ? `?${newSearch}` : ''}${location.hash}`,
        {
          ...location.state,
          dc_auth: dc,
          dc_timestamp: urlParams.get('dc_timestamp') || undefined,
        }
      )
    }
    const dc_auth = location.state && location.state.dc_auth;
    const showDocCheck = dc_embed && (!dc_auth || dc_auth != 1);

    if (loaded && !id) {
      return <PageNotFound />;
    }

    const Template = template;

    return <>
      {!homepage && <PageHeader
        title={title.rendered}
        image={banner_image}
        location={location}
        section={section}
      />}

      {isLoading || !template ?
        <Loading /> :
        showDocCheck ? // DocCheck enabled and unauthorized
          <Container className="page-main">
            <Row className="justify-content-center">
              <Col sm="10">
                <section className="page-content">
                  <DocCheck
                    className="centered"
                    embed={dc_embed}
                    content={dc_content}
                  />
                </section>
              </Col>
            </Row>
          </Container>
          :
          <Template
            {...this.props}
            error={error}
            isLoading={isLoading}
            loaded={loaded}
            location={this.props.location}
          />
      }
    </>
  }
}

PageWrapper.propTypes = {
  acf: PropTypes.object,
  error: PropTypes.object,
  history: PropTypes.object,
  id: PropTypes.number,
  isLoading: PropTypes.bool,
  loaded: PropTypes.bool,
  location: PropTypes.object.isRequired,
  page_type: PropTypes.string,
  section: PropTypes.string,
  template: PropTypes.string,
  title: PropTypes.object,
}

export default PageWrapper;