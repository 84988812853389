import React from "react";
import PropTypes from "prop-types";
import ReactCountryFlag from "react-country-flags";
import classnames from 'classnames';
import { withStrings } from "../Contexts/StringsContext";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { intersectionWith } from 'lodash'

const Flag = props => {
    const { country } = props;

    return <ReactCountryFlag
        preserveAspectRatio={['it', 'ie'].includes(country) ? "none": undefined}
        {...props}
    />;
}
Flag.propTypes = {
    country: PropTypes.string.isRequired,
}

class CountryNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }
    render() {
        const { countries, className, strings = {} } = this.props;
        const { country_navigation: { toggle_label, sites } = {}, ui: { toggle_menu } = {} } = strings;
        const { isOpen } = this.state;

        if ( 2 <= countries.length ) {
            const current = countries.filter(country => country.current == true).shift();
            const displayedCountries = intersectionWith(countries, sites, (a, b) => (a.id ?? a) == (b.id ?? b))

            if ( displayedCountries.length && current ) {
                return (
                    <Dropdown
                        isOpen={isOpen}
                        toggle={() => this.setState({ isOpen: !isOpen })}
                        onMouseEnter={() => this.setState({ isOpen: true })}
                        onMouseLeave={() => this.setState({ isOpen: false })}
                        cssModule={{ 'dropdown': classnames(className, 'country-nav') }}
                    >
                        <DropdownToggle cssModule={{btn: "country-nav__dropdown", 'btn-secondary': ' '}}>
                            <span className="sr-only">{toggle_menu}</span>
                            <span className="country-nav__label">{toggle_label}</span>
                            <span className={`country-nav__flag country-nav__flag--${current.code}`}><Flag country={current.code} /></span>
                        </DropdownToggle>
                        <DropdownMenu cssModule={{ 'dropdown-menu': "country-nav__countries" }}>
                            {displayedCountries.map((country, index) =>
                                <DropdownItem tag="a" key={index} cssModule={{ 'dropdown-item': "country-nav__country" }} href={country.url}>
                                    <span className={`country-nav__flag country-nav__flag--${country.code}`}><Flag country={country.code} /></span>
                                    <span className="country-nav__name">{country.name}</span>
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                );
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}

CountryNav.propTypes = {
    strings: PropTypes.object,
    countries: PropTypes.array,
    className: PropTypes.string,
};

CountryNav.defaultProps = {
    countries: [],
};

export default withStrings(CountryNav);
