import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

const Gallery = ({ gallery, section_title }) => {
  if (!gallery) {
    return null;
  }

  const items = gallery.map(({ sizes = {}, url, alt = '', caption }) => {
    return (
      {
        original: sizes.section ? sizes.section : url,
        thumbnail: sizes['section-mobile'] ? sizes['section-mobile'] : url,
        originalAlt: alt,
        thumbnailAlt: alt,
        description: caption,
      }
    );
  });

  const renderItem = (item) => {
    return (
      <div className='image-gallery-image'>
        {
          item.imageSet ?
            <picture>
              {
                item.imageSet.map((source, index) => (
                  <source
                    key={index}
                    media={source.media}
                    srcSet={source.srcSet}
                    type={source.type}
                  />
                ))
              }
              <img
                alt={item.originalAlt}
                src={item.original}
              />
            </picture>
          :
            <img
              src={item.original}
              alt={item.originalAlt}
              srcSet={item.srcSet}
              sizes={item.sizes}
              title={item.originalTitle}
            />
        }

        {
          item.description &&
            <figcaption>
              {item.description}
            </figcaption>
        }
      </div>
    );
  };

  return (
    <div className='gallery-container'>
        <div className={section_title ? 'section-title centered' : ''}>
            {section_title}
        </div>
        <ImageGallery
          items={items}
          showFullscreenButton={false}
          showPlayButton={false}
          renderItem={renderItem}
          renderLeftNav={(onClick, disabled) => {
            return (
              <button
                className='btn image-gallery-left-nav'
                disabled={disabled}
                onClick={onClick}
              >
                <span className="icon-arrow" aria-hidden="true" />
              </button>
            )
          }}
        />
    </div>
  );
};

Gallery.propTypes = {
  gallery: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  section_title: PropTypes.string
};

Gallery.defaultProps = {
  section_title: ""
};


export default Gallery;
