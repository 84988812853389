import * as React from "react";
import { ExternalFundingFormWrapper } from "./ExternalFundingFormWrapper";
import { InvestigatorFormWrapper } from "./InvestigatorFormWrapper";
import { StriveRequestFormWrapper } from "./StriveRequestFormWrapper";
import PropTypes from 'prop-types';

const FORM_TYPES = {
  external_funding_form: ExternalFundingFormWrapper,
  investigator_form: InvestigatorFormWrapper,
  strive_request_form: StriveRequestFormWrapper
}

const FormLayout = ({ form_type, ...props }) => {
   const Component = FORM_TYPES[form_type];
   return <Component {...props} />
};

FormLayout.propTypes = {
  /** Which form Component to load. */
  form_type: PropTypes.string.isRequired,
}

export default FormLayout;
