import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { pick } from "lodash";
import { ariaAttributes } from '../../utils/a11y';

const Img = ({
  url,
  width,
  size,
  sizes,
  srcSet,
  className,
  background,
  style,
  ...rest
}) => {
  if (!url) {
    return null;
  }

  const getImage = (sizeName) => {
    if (sizeName === 'original') {
      return {
        url,
        width,
      }
    }

    if (sizes[sizeName]) {
      return {
        url: sizes[sizeName],
        width: sizes[`${sizeName}-width`],
        height: sizes[`${sizeName}-height`],
      }
    }

    return null;
  };

  const sizeNames = Object.keys(srcSet);
  const defaultImage = size ? getImage(size) : getImage('original');

  const imgAttr = {
    ...pick(rest, ariaAttributes),
    className: classnames(
      "img",
      `img--size-${size ? size : "original"}`,
      className
    ),
    ...background ?
      {
        style: background ? { ...style, backgroundImage: `url("${defaultImage.url}")` } : style,
        src: `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${defaultImage.width}' height='${defaultImage.height}'%3E%3C/svg%3E`,
        'data-src': defaultImage.url,
      } :
      {
        src: defaultImage.url,
      }
  };

  if (!sizeNames.length) {
    return <img {...imgAttr} />;
  }

  const sources = Object.keys(srcSet).map((sizeName, index) => {
    if (sizes[sizeName]) {
      return (
        <source
          key={index}
          srcSet={sizes[sizeName]}
          media={srcSet[sizeName] || undefined}
          type="image/jpeg"
        />
      );
    }

    return null;
  });

  return (
    <picture>
      {sources}
      <img {...imgAttr} />
    </picture>
  );
};

Img.propTypes = {
  /** URL of the original size image. */
  url: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  /**
   * Default image size, used as the `<img>` tag's `src`.
   * Displays if `<picture>` tag isn't supported, or none of the `<source>`'s media queries apply.
   */
  size: PropTypes.string,
  /**
   * URLs of the image sizes for this image.
   * Key is image size slug, value is URL of image at that size.
   */
  sizes: PropTypes.object,
  /**
   * Determines `<source>` tags in `<picture>` element.
   * Key is an image size slug, value is the media query at which to display the image size.
   */
  srcSet: PropTypes.object,
  className: PropTypes.string,
  /** Whether to add image as background-image instead of src. Used to polyfill object-fit. */
  background: PropTypes.bool,
  style: PropTypes.object,
};

Img.defaultProps = {
  size: "",
  sizes: {},
  srcSet: {},
};

export default Img;
