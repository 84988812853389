import React from "react";
import PropTypes from 'prop-types';

const SettingsContext = React.createContext({});
SettingsContext.displayName = 'Settings';
const { Provider: SettingsProvider, Consumer: SettingsConsumer } = SettingsContext;

// eslint-disable-next-line react/display-name
const withSettings = (Component, consumeRef = true) => {
  const ConsumerWithComponent = props => {
    var {wrappedComponentRef, ...wrappedComponentProps} = props;
    
    const Wrapped = settings => <Component settings={settings} {...(consumeRef ? { ref: wrappedComponentRef, ...wrappedComponentProps } : props)} />;
    return <SettingsConsumer>{Wrapped}</SettingsConsumer>;

  }

  ConsumerWithComponent.displayName = `withSettings(${Component.displayName || Component.name})`;
  ConsumerWithComponent.propTypes = {
    wrappedComponentRef: PropTypes.func,
  }

  return ConsumerWithComponent;
};

export { SettingsContext, SettingsProvider, withSettings, SettingsConsumer };
