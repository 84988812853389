import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ParsedWysiwyg from '../../../Common/ParsedWysiwyg';
import { srcsetStr, trimW3, tryURL } from '../../../../utils/misc';
import { uniqueId, kebabCase } from 'lodash';
import LinkSr, { useLinkSr } from '../../../Common/LinkSr';

/**
 * Transform coordinate string into object
 * @param {String} coordStr Comma-separated coordinate percentages
 * @returns {Object} X and Y coordinates as numbers
 */
const extractCoords = coordStr => {
	const [x, y] = coordStr.split(',').map(coord => Number(coord.slice(0, -1)))
	return { x, y }
}


/**
 * Sort locations by y-coordinate ascending.
 */
const sortCoords = ({ location: locationA }, { location: locationB }) => extractCoords(locationA).y - extractCoords(locationB).y

const Locations = ({ locations, map }) => {
	if (!(locations && locations.length && map)) {
		return null;
	}

	// Setup IDs
	const { current: baseId } = useRef(uniqueId('locations-'));
	const ids = {
		image: `${baseId}-image`,
		card: `${baseId}-card`,
		cardTitle: `${baseId}-card-title`
	}

	const externalLinkCopy = useLinkSr({ external: true, blank: true })

	/**
	 * Locations sorted by y-coordinate ascending, to match HTML spec for order of a `<map>` element's `<area>` elements.
	 *
	 * Also helps with `z-index` management.
	 * @link https://html.spec.whatwg.org/multipage/image-maps.html#image-map-processing-model
	 */
	const sortedLocations = locations.map(({ location }, index) => ({ location, index })).sort(sortCoords)

	return (
		<div className="locations__container centered rich-text">
			<div className="locations__map mb-7">
				<div aria-role="group" aria-labelledby={ids.image} className="locations__hotspots">
					{sortedLocations.map(({ index: rawIndex }, index) => {
						const { location, name } = locations[rawIndex];

						const [xCoord, yCoord] = location.split(',');

						return <a
							key={index}
							style={{ left: xCoord, top: yCoord, '--x-coord': xCoord, '--y-coord': yCoord }}
							className="locations__hotspot"
							href={`#${ids.card}-${kebabCase(name)}`}
							aria-label={name}
						/>
					})}
				</div>
				<img
					className="locations__map-image"
					src={map.image.sizes.large}
					srcSet={srcsetStr('large', map.image.sizes)}
					alt={map.label}
					id={ids.image}
				/>
			</div>

			<div className="locations__locations">
				{locations.map(({ image, name, content, link, video }, index) => {

					// Set link text using series of fallbacks
					let linkText;
					if (link.url) {
						if (link.text) {
							linkText = link.text
						} else {
							const urlObj = tryURL(link.url)
							linkText = urlObj
								? trimW3(urlObj.host)
								: link.url
						}
					}

					return <article
						className='location-v2 mg-card mg-card--focus mg-card--border-gold-h'
						key={index}
						tabIndex="-1"
						id={`${ids.card}-${kebabCase(name)}`}
						aria-labelledby={`${ids.cardTitle}-${index}`}
					>
						<div className="mg-card__img-outer embed-responsive embed-responsive-16by9">
							<img className="embed-responsive-item mg-card__img location__img" alt={image.alt} src={image.sizes.medium_large} />
						</div>
						{video &&
							<a className="location__teaser" href={video.url} target="_blank" rel="noopener noreferrer">
								<i className="location__teaser-icon icon icon-play" aria-hidden="true"></i>
								<div className="location__teaser-heading">{video.data.title}</div>
								<LinkSr external blank />
							</a>
						}
						<div className="mg-card__body">
							<span id={`${ids.cardTitle}-${index}`} className="location__title">{name}</span>
							{content && <div className="location__content wysiwyg-content"><ParsedWysiwyg htmlString={content} /></div>}
							{link?.url &&
								<a className="location__link" href={link.url} rel="noopener noreferrer" target="_blank">{linkText}<i aria-label={externalLinkCopy} className='icon icon-external-link'></i></a>
							}
						</div>
					</article>
				})}
			</div>
		</div>
	);
};

Locations.propTypes = {
	locations: PropTypes.arrayOf(PropTypes.shape({
		address: PropTypes.string,
		contact: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
			type: PropTypes.oneOf(['phone', 'email'])
		})),
		image: PropTypes.object,
		link: PropTypes.shape({
			title: PropTypes.string,
			url: PropTypes.string
		}),
		/** Comma-separated, percentage-based coordinates for hotspot */
		location: PropTypes.string,
		name: PropTypes.string,
		/** ACF embed object */
		video: PropTypes.shape({
			/** oembed data */
			data: PropTypes.object,
			/** Embed HTML string */
			embed: PropTypes.string,
			/** Entered URL */
			url: PropTypes.string,
		}),
	})),
	/** ACF image object for hotspot image */
	map: PropTypes.object
};

export default Locations;
