import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash.uniqueid';
import classNames from 'classnames'

const Quote = ({ quote, style, author: { name, description, image } = {} }) => {
  if (!quote) return null;

  const {current: baseId } = useRef(uniqueId('quote-'));

  return <figure className={classNames('quote__inner centered', {[`quote__inner--${style}`]: style})}>
    <blockquote className="quote__text"><p>{quote}</p></blockquote>
    {name &&
      <figcaption className="quote__author">
        {image && <img className="quote__image" aria-labelledby={`${baseId}-author-text`} src={image.sizes.thumbnail} />}
        <span className='quote__author-text' id={`${baseId}-author-text`}>
          <span className="quote__name">{name}</span>
          {description && <span className="quote__description">{description}</span>}
        </span>
      </figcaption>
    }
  </figure>
};

Quote.propTypes = {
  quote: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.image,
  })
};

export default Quote;
