import React from 'react';
import PropTypes from 'prop-types';
import HtmlParser from 'react-html-parser';
import { pick as _pick } from 'lodash';
import { htmlAttributes } from '../../utils/misc';
import { ariaAttributes } from '../../utils/a11y';
import classnames from 'classnames';
import ParsedWysiwyg from './ParsedWysiwyg';

const DocCheck = props => {
  const { embed, content, className, ...rest } = props;

  return embed ?
    <div className={classnames(['doccheck', className])} {..._pick(rest, htmlAttributes, ariaAttributes)}>
      <div className="doccheck__embed embed">
        {HtmlParser(embed)}
      </div>
      {content && <div className="doccheck__content wysiwyg-content">
        <ParsedWysiwyg htmlString={content} />
      </div>}
    </div>
    :
    null;
}

DocCheck.propTypes = {
  /** HTML for the embed. */
  embed: PropTypes.string,
  /** Short text. */
  content: PropTypes.string,
  className: PropTypes.string,
}

export default DocCheck;