/**
 * SingleEvent
 *
 * @package ptc-therapeutic
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../Common/PageHeader';
import { Col, Container, Row } from 'reactstrap';
import Loading from '../Common/Loading';
import FlexibleContent from '../ACF/FlexibleContent';
import { StringsContext } from '../Contexts/StringsContext';
import SkipToMain from '../Common/SkipToMain';
import { SettingsContext } from '../Contexts/SettingsContext';
import { Clipboard } from '../Svg';
import ParsedWysiwyg from '../Common/ParsedWysiwyg';
/* eslint-disable import/no-unresolved */
import '../../resources/svg/gradient-green-horizontal.svg?sprite'
import { isValidDate } from '../../utils/misc';

/**
 * Format event date/time for display
 * @param {Object} param0
 * @returns {String}
 */
function formatTime({ start_date, start_time, end_date, end_time, time_zone, all_day }) {
  let start, end, includeTime;

  start = new Date(`${start_date}T${start_time || '00:00:00'}Z`)

  if (!isValidDate(start)) return null

  if (end_date) {
    end = new Date(`${end_date}T${end_time || '00:00:00'}Z`)
    if (!isValidDate(end)) return null

    includeTime = Boolean(start_time && end_time)
  } else {
    includeTime = Boolean(start_time)
  }

  if (all_day) {
    includeTime = false
  }

  const dateTimeFormatter = Intl.DateTimeFormat(process.env.DEFAULT_LANGCODE, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    ...includeTime && {
      hour: 'numeric',
      minute: 'numeric'
    },
    ...time_zone && {
      timeZone: time_zone
    }
  })

  const zoneFormatter = Intl.DateTimeFormat(process.env.DEFAULT_LANGCODE, {
    timeZoneName: 'short',
    ...time_zone && {
      timeZone: time_zone
    }
  })

  let str = end
    ? dateTimeFormatter.formatRange(start, end)
    : dateTimeFormatter.format(start)

  const zone = zoneFormatter.formatToParts(start).find(({ type }) => 'timeZoneName' === type)?.value

  if (zone) str = `${str} ${zone}`

  return str
}

const SingleEvent = props => {
  // # Context
  const {
    event: {
      single: single_labels = {},
      registration: registrationDefaults = {}
    } = {}
  } = React.useContext(StringsContext)

  const {
    event_breadcrumb
  } = React.useContext(SettingsContext);

  const {
    title = {},
    isLoading,
    acf = {}
  } = props;

  let section;
  if (event_breadcrumb?.length) {
    section = event_breadcrumb[0].title
  }

  const {
    content,
    location,
    time_zone,
    banner_image,
    registration_type,
    registration_heading = registrationDefaults.heading,
    registration_sections,
  } = acf

  // # Event Details
  const localString = formatTime(acf)
  const zonedString = time_zone && formatTime(acf)

  let details = [
    {
      term: single_labels.name,
      desc: title.rendered
    },
    {
      term: single_labels.date,
      desc: isLoading
        ? null
        : <>
          {localString}
          {zonedString === localString
            ? null
            :
            <>
              <br />
              {zonedString}
            </>
          }
        </>
    }
  ]

  if (location) details.push({
    term: single_labels.location,
    desc: location
  })

  return <>
    <PageHeader
      title={title.rendered}
      image={banner_image}
      section={section}
      useContainer
    />
    <Container className="page-main">
      <Row className="justify-content-center">
        <Col sm="10">
          <SkipToMain />
          {isLoading
            ? <Loading />
            : <Row className="justify-content-between">
              <Col xl={registration_type ? '6' : ''}>
                <section className="page-content">
                  <h2 className="single-event__heading heading--2">{single_labels.heading}</h2>
                  <dl className="single-event__details dlist mb-4">
                    {details.map(({ term, desc }) => <div className="row gx-2 gy-1 dlist__item" key={term}>
                      <dt className="dlist__term col-auto col-md-2">{term}:</dt>
                      <dd className="dlist__desc col">{desc}</dd>
                    </div>)}
                  </dl>

                  {Array.isArray(content) && <FlexibleContent content={content} />}
                </section>
              </Col>

              {registration_type &&
                <Col xl="5">
                  <section className="page-sidebar">
                    <div className="sidebar sidebar--border-orange">
                      {registration_heading &&
                        <div className="sidebar__title sidebar__title--lg"><Clipboard className="sidebar__title-icon" aria-hidden="true" />{registration_heading}</div>
                      }
                      {registration_sections.map((layout, index) => {
                        let inner;
                        switch (layout.acf_fc_layout) {
                          case 'cta':
                            inner = <a
                              className="btn btn-gradient"
                              href={layout.cta.url}
                              target={layout.cta.target}
                              {...layout.cta.target !== '_blank' && { rel: "noopener noreferrer" }}
                            >{layout.cta.title || registrationDefaults.cta_text}<i aria-hidden="true" className="btn__icon icon icon-external-link" /></a>
                            break;
                          case 'download':
                            inner = <a
                              className="btn btn-gradient btn-arrow"
                              href={layout.file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >{layout.label || registrationDefaults.download_label}</a>
                            break;
                          case 'wysiwyg':
                            inner = <div className="wysiwyg-content"><ParsedWysiwyg htmlString={layout.wysiwyg} /></div>
                            break;
                          default:
                            break;
                        }
                        return inner && <div className="mt-3" key={index}>{inner}</div>
                      })}
                    </div>
                  </section>
                </Col>
              }
            </Row>
          }
        </Col>
      </Row>
    </Container >
  </>
}

SingleEvent.propTypes = {
  title: PropTypes.shape({ rendered: PropTypes.string }),
  isLoading: PropTypes.bool,
  acf: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    start_date: PropTypes.string,
    start_time: PropTypes.string,
    end_date: PropTypes.string,
    end_time: PropTypes.string,
    location: PropTypes.string,
    banner_image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  })
}

export default SingleEvent;
