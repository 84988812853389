/**
 * LoopPost
 *
 * @package frontend-ui
 */
import React, { useState, useEffect, useContext } from 'react';
import { TaxonomiesContext } from '../Contexts/TaxonomiesContext';
import PropTypes from 'prop-types';
import { find as _find, reject as _reject, get as _get, merge as _merge } from 'lodash';
import { AllHtmlEntities as Entities } from 'html-entities';
import { Link } from 'react-router-dom';
import { BEM } from '../../utils/misc';
import { maybeClassName } from '../../utils/inputs';
import Moment from 'react-moment';
import Img from './Img';
import { fillTaxonomies } from '../../api';
import { isFunction } from 'reactstrap/lib/utils';


const decode = (new Entities()).decode;

/**
 * React Component LoopPost.
 *
 * @param {Object} props Component properties.
 */
const LoopPost = React.forwardRef((props, ref) => {
  const {
    className,
    data,
    mediaTypes: pMediaTypes = undefined,
    badge: pBadge,
    ...forwardProps
  } = props;

  const {
    'media-type': mediaTypeIds = [],
    link: url,
    title: { rendered: title },
    date,
    acf: { banner_image } = {}
  } = data;

  const [taxonomies, setTaxonomies] = useContext(TaxonomiesContext);

  /*------------------------------------------------------------
   * State
   *----------------------------------------------------------*/
  const [ready, setReady] = useState(false);

  /*------------------------------------------------------------
   * Effects
   *----------------------------------------------------------*/
  /*
   * Get needed media types on mount.
   */
  const mediaTypes = _get(taxonomies, 'media-type.terms') || pMediaTypes;
  useEffect(() => {
    if (mediaTypes && mediaTypes.length) return;

    fillTaxonomies({ 'media-type': { terms: true } }, taxonomies)
      .then(nTaxonomies => {
        setTaxonomies(_merge(nTaxonomies, taxonomies))
        return nTaxonomies;
      })
  }, [])

  /*
   * All dependencies loaded.
   */
  const deps = [mediaTypes];
  useEffect(() => {
    if (!_reject(deps).length) {
      setReady(true);
    }
  }, deps);

  /*------------------------------------------------------------
   * Render
   *----------------------------------------------------------*/
  if (!ready) return false;

  const cls = BEM('post');
  const mediaType = _find(mediaTypes, ['id', mediaTypeIds[0]]);

  const iconSlug = mediaType && mediaType.acf.media_type_icon;
  const badge = undefined !== pBadge
    // Determine badge from prop
    ? (isFunction(pBadge)
      ? pBadge({ icon: iconSlug }) // Render function
      : pBadge // Node
    )
    // Default icon determination
    : (iconSlug && <i aria-hidden="true" className={`post__badge post__icon icon icon-${iconSlug}`}></i>)

  return <Link
    ref={ref}
    {...forwardProps}
    className={`${cls(['', 'loop', badge && 'badge', iconSlug && `icon-${iconSlug}`, banner_image && 'image'])}${maybeClassName(className)}`}
    to={(new URL(url)).pathname}
  >
    {banner_image && <Img className={cls('img')} {...banner_image} size="section-mobile" background />}
    <div className={cls('copy')}>
      <div className={cls('subheading')}>
        {date && <Moment className={cls('date')} locale={process.env.DEFAULT_LANGCODE} format="LL">{date}</Moment>}
        {badge}
      </div>
      <div className={cls('title')}><span>{decode(title)}</span></div>
    </div>
  </Link>
})

LoopPost.displayName = 'LoopPost'

LoopPost.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  /**
   * Badge element
   */
  badge: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node
  ]),
  mediaTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      acf: PropTypes.oneOfType([
        PropTypes.shape({
          media_type_icon: PropTypes.string
        }),
        PropTypes.array,
      ]).isRequired
    })
  )
}

LoopPost.defaultProps = {
  className: '',
}

export default LoopPost;