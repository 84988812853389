import React, { forwardRef } from "react";
import ReactHtmlParser from "react-html-parser";
import getPath from "../../../api/relativePath";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import PDF from '../../../resources/svg/pdf.svg'
import { get } from "lodash";

const TYPE_ICON_MAP = {
  post: {
    attachment: {
      application: {
        pdf: PDF
      }
    }
  }
}

const SearchResult = forwardRef((props, ref) => {
  const { result } = props

  if (!result) return;

  // Link to display
  const pageLink = result.url;
  const baseHref = pageLink.replace(/^https?:\/\/(.*?)\/.+$/, '$1');
  const currentPage = window.location.host;
  const newPageLink = pageLink.replace(baseHref, currentPage);

  const path = getPath(pageLink);

  let AnchorComponent, AnchorProps, Icon;

  /** BEM class modifiers for search-result */
  let classModifiers = [
    result.type,
    result.subtype
  ];

  /** Path to get icon from icon type map */
  let iconPath = [
    result.type,
    result.subtype
  ];

  if (result.attachment) {
    AnchorComponent = 'a';
    AnchorProps = {
      href: result.attachment.url
    };

    classModifiers.push(result.attachment.type, result.attachment.subtype)
    iconPath.push(result.attachment.type, result.attachment.subtype)

  } else if (['page', 'post'].includes(result.subtype)) {
    AnchorComponent = Link;
    AnchorProps = {
      to: path.path
    }
  }

  // Determine icon
  Icon = get(TYPE_ICON_MAP, iconPath)

  // Construct modifier classes
  classModifiers = classModifiers
    .reduce((classes, modifier) => {
      if (modifier) classes.push(`search-result--${modifier}`)
      return classes
    }, [])

  return (
    <article className={classNames('search-result', classModifiers)}>
      <h3 className='search-result__title heading heading--3'>
        {Icon &&
          <Icon className='search-result__icon' aria-hidden='true' />
        }
        
        <AnchorComponent ref={ref} {...AnchorProps} className="search-result__title-link">{ReactHtmlParser(result.title)}</AnchorComponent>
      </h3>

      {result.excerpt &&
        <div className='search-result__description'>{ReactHtmlParser(result.excerpt)}</div>
      }

      <AnchorComponent {...AnchorProps} className="search-result__link">{newPageLink}</AnchorComponent>
    </article>
  );
});

SearchResult.propTypes = {
  result: PropTypes.object.isRequired
}

SearchResult.displayName = 'SearchResult';

export default SearchResult;
