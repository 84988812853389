import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AllHtmlEntities as Entities } from 'html-entities';
const entities = new Entities();

const MainContent = ({ tag: Tag = 'section', className = 'main-content', title, bodyClasses, children, isLoading, seo, onRendered }) => {

  return (
    <Tag className={className || undefined}>
      <Helmet
        onChangeClientState={onRendered}
      >
        {seo.title && <title>{entities.decode(seo.title)}</title>}
        {seo.title && <meta name="og:title" content={entities.decode(seo.title)} />}
        {seo.title && <meta name="twitter:title" content={entities.decode(seo.title)} />}
        {seo.url && <meta name="og:url" content={seo.url} />}
        {seo.url && <link rel="canonical" href={seo.url} />}
        {seo.image && <meta name="og:image" content={seo.image} />}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
        {seo.description && <meta name="description" content={entities.decode(seo.description)} />}
        {seo.description && <meta name="og:description" content={entities.decode(seo.description)} />}
        {seo.description && <meta name="twitter:description" content={entities.decode(seo.description)} />}
        {seo.type && <meta name="og:type" content={seo.type} />}
        <body className={classnames(bodyClasses.concat({ 'is-loading': isLoading }))} />
      </Helmet>
      {children}
    </Tag>
  );
};

/**
 * First parameter of react-helmet onChangeClientState callback.
 *
 * @typedef {Object} NewState
 * @property {Array} baseTag
 * @property {Object} bodyAttributes
 * @property {Boolean} defer
 * @property {Boolean} encode
 * @property {Object} htmlAttributes
 * @property {Object[]} linkTags
 * @property {MetaTag[]} metaTags
 * @property {Array} noscriptTags
 * @property {Function} onChangeClientState
 * @property {ScriptTag[]} scriptTags
 * @property {Array} styleTags
 * @property {String} title
 * @property {Object} titleAttributes
 */

/**
 * @typedef {Object} MetaTag
 * @property {String} name
 * @property {String} content
 */

/**
 * @typedef {Object} ScriptTag
 * @property {String} innerHtml
 */

/**
 * @typedef {Object} AddedTags
 * @property {Array} baseTag
 * @property {HTMLLinkElement[]} linkTags
 * @property {HTMLMetaElement[]} metaTags
 * @property {Array} noscriptTags
 * @property {HTMLScriptElement[]} scriptTags
 * @property {HTMLStyleElement[]} styleTags
 */

MainContent.propTypes = {
  title: PropTypes.string,
  bodyClasses: PropTypes.array,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  seo: PropTypes.object,
  tag: PropTypes.string,
  className: PropTypes.string,
  /**
   * Callback after tags have been updated.
   * 
   * Arguments:
   * 
   * 1: {NewState} newState
   * 2: {AddedTags} addedTags
   * 3: {Object} removedTags
   */
  onRendered: PropTypes.func
};

MainContent.defaultProps = {
  isLoading: false,
  bodyClasses: [],
  seo: {},
};

export default MainContent;
