import { Link, NavLink } from "react-router-dom";
import React from 'react'
import PropTypes from 'prop-types'
import { isExternal, trimW3 } from "../utils/misc";

const baseUrl = new URL(process.env.BASE_URL);
export const baseHost = baseUrl.hostname;

/**
 * @typedef {Object} URLData
 * @property {Boolean} external Whether this URL leads outside this site.
 * @property {Boolean} externalNewTab Whether this URL should open in a new tab.
 * @property {String} path A full URL for external URLs, relative path for internal URLs.
 */

/**
 * Based on a URL, determine:
 * - If it leads to an external site
 * - If it should open in a new tab
 * - The full URL if external, the relative path if internal.
 * @param {String} url URL to parse.
 * @returns {URLData|undefined} URL data, or undefined if unable to parse.
 */
const getPath = url => {
  if ('string' !== typeof url) return undefined;

  let external = isExternal(url);
  if (external === undefined) return undefined;

  let externalNewTab, path, urlObj;
  const baseHostname = trimW3(baseHost);

  if (true === external) {
    urlObj = new URL(url);
    const urlHostname = trimW3(urlObj.hostname);

    // Non-ptcbio sites open in new tab
    externalNewTab = !([baseHostname].includes(urlHostname) || urlHostname.includes('ptcbio'));
    path = url;
  } else {
    externalNewTab = false;

    // Account for anchors
    if (url.charAt(0) === '#') path = url;
    else {
      // Account for relative links.
      try { urlObj = new URL(url.charAt(0) === '/' ? `${location.origin}${url}` : url) }
      catch { return undefined }

      path = urlObj.href.slice(urlObj.origin.length);
    }
  }

  return ({
    external,
    externalNewTab,
    path,
  })
}

export default getPath;

export const BaseLink = React.forwardRef(({ href, nav, ...props }, ref) => {
  const pathinfo = getPath(href)

  if (!pathinfo) {
    return <a ref={ref} {...props} />
  }

  const {
    external,
    externalNewTab,
    path
  } = pathinfo

  if (external) {
    return <a target={externalNewTab ? '_blank' : undefined} {...props} ref={ref} href={path} />
  }

  const Component = nav ? NavLink : Link
  return <Component {...props} to={path} ref={ref} />
})

BaseLink.displayName = 'BaseLink'
BaseLink.propTypes = {
  href: PropTypes.string.isRequired,
  nav: PropTypes.bool
}