import React from 'react';
import PropTypes from 'prop-types';
import Headline from '../../Common/Headline';
import Copy from '../../Common/Copy';
import ButtonLink from '../../Common/ButtonLink';
import { uniqueId as _uniqueId } from 'lodash';

const Cta = ({ cta }) => {
  const { background, button, copy, headline } = cta;
  const headingId = _uniqueId( 'cta-heading-' );
  if (!background && !button && !copy && !headline) {
    return null;
  }
  return (
    <div className="cta">
        <Headline id={headingId} className="cta__headline">{headline}</Headline>
        <Copy className="cta__copy" tag="p">{copy}</Copy>
        <ButtonLink aria-labelledby={headline ? headingId : false} {...button} type={['gradient', 'arrow', 'on-dark']} />
    </div>
  );
};

Cta.propTypes = {
  cta: PropTypes.object,
};

Cta.defaultProps = {
  cta: {},
};

export default Cta;
