import React from 'react';

const Loading = () => {
  return (
    <div className="spinner">
      <div className="loader" />
    </div>
  );
};
export default Loading;
