import * as React from "react";

export const SvgCaret = () =>
  <svg className="inline-svg select__caret__icon" width={36} height={36} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 36 36">
    <path strokeWidth={1.5} fillRule="nonzero" d="M9.45 24.14L8 22.764 18.225 12 28.45 22.763 27 24.141l-8.775-9.237z"/>
  </svg>;

export const SvgX = () =>
  <svg className="inline-svg" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 36 36">
    <path fillRule="nonzero" d="M9,9 L27,27 M27,9 L9,27"/>
  </svg>;
