import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import ReactHtmlParser from "react-html-parser";

class ToggleContent extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }
  toggleId() { return `${this.props.contentId}-${this.props.index}__toggle` }
  bodyId() { return `${this.props.contentId}-${this.props.index}__body` }
  toggle() {
    this.props.toggle(this.props.index);
  }
  render() {
    return (
      <div className="accordion__item" data-expanded={this.props.isOpen}>
        <button className="accordion__toggle" aria-expanded={this.props.isOpen} aria-controls={this.bodyId()} onClick={this.toggle} id={this.toggleId()}>
          {this.props.title}
          {this.props.isOpen ? (
            <span className="accordion__indicator icon icon-close" />
          ) : (
            <span className="accordion__indicator icon icon-plus" />
          )}
        </button>

        <Collapse className="accordion__copy" id={this.bodyId()} role="region" isOpen={this.props.isOpen}>
          <div className="accordion__copy-wrapper wysiwyg-content">
            {ReactHtmlParser(this.props.copy)}
          </div>
        </Collapse>
      </div>
    );
  }
}

ToggleContent.propTypes = {
  toggle: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  copy: PropTypes.string,
  title: PropTypes.string,
  contentId: PropTypes.string
};

ToggleContent.defaultProps = {
  title: "",
  contentId: ''
};

export default ToggleContent;
