import React from 'react';
import { UncontrolledAlert } from 'reactstrap';
import PropTypes from 'prop-types';
const ErrorMessage = error => {
  if (error.status >= 400 || error.status < 200) {
    return (
      <UncontrolledAlert color="info">
        <p>{error.status} - {error.statusText}</p>
        <p>{error.message}</p>
      </UncontrolledAlert>
    );
  }
  return null;
};

ErrorMessage.propTypes = {
  status: PropTypes.number,
  statusText: PropTypes.string,
  message: PropTypes.string,
};
export default ErrorMessage;
