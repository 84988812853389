/**
 * Listbox
 *
 * @package ptc-therapeutic
 */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { get_label_from_value, maybeClassName } from '../../utils/inputs';
import { Popped, Pop } from './Popper';
import { SvgCaret } from './Svgs';
import { whichKey, isKey } from '../../utils/a11y';
import { mod } from '../../utils/misc';

/**
 * React Component Listbox.
 */
const Listbox = props => {
  const {
    name,
    placeholder,
    required,
    value,
    'aria-labelledby': ariaLabelledby,
    // eslint-disable-next-line no-unused-vars
    'aria-required': ariaRequired,
    disabled,
    onChange,
    options: p_options,
    className,
    ...rest
  } = props;

  const options = required ? p_options : [{ value: "", label: "" }, ...p_options];

  const toggle = useRef();
  const menu = useRef();
  const items = useRef([]);

  const [focussed, setFocussed] = useState(0);
  const [isOpen, setOpen] = useState(false);

  /**
    * Toggle menu open/closed and adjust focus.
    * @param {boolean} openMenu `true` to open, `false` to close, omit to toggle.
    */
  const toggleMenu = (openMenu = !isOpen) => {
    setOpen(openMenu);
    openMenu ? menu.current.focus() : toggle.current.focus();
  }

  /**
   * Scroll option into view.
   * @param {number} i Index of option.
   */
  const scrollMenu = i => {
    // Scroll item into view.
    const item = items.current[i];
    if (menu.current.scrollHeight > menu.current.clientHeight) {
      const scrollBottom = menu.current.clientHeight + menu.current.scrollTop;
      const itemBottom = item.offsetTop + item.offsetHeight;

      if (itemBottom > scrollBottom) {
        menu.current.scrollTop = itemBottom - menu.current.clientHeight;
      }
      else if (item.offsetTop < menu.current.scrollTop) {
        menu.current.scrollTop = item.offsetTop;
      }
    }
  }

  /*------------------------------------------------------------
   * Handlers
   *----------------------------------------------------------*/
  const navigateMenu = e => {
    const key = whichKey(e);
    let newFocussed = undefined;
    if (['ArrowDown', 'ArrowUp', 'Home', 'End', 'Enter'].includes(key)) e.preventDefault();
    switch (key) {
      case 'ArrowDown':
        newFocussed = mod(focussed + 1, options.length)
        break;

      case 'ArrowUp':
        newFocussed = mod(focussed - 1, options.length)
        break;

      case 'Home':
        newFocussed = 0
        break;

      case 'End':
        newFocussed = options.length - 1
        break;

      case 'Escape':
        toggleMenu(false)
        break;

      case 'Enter':
        toggleMenu()
        break;

      default:
        break;
    }

    if (undefined !== newFocussed) {
      scrollMenu(newFocussed);
      setFocussed(newFocussed)
      onChange(options[newFocussed], e)
    }
  }

  /*------------------------------------------------------------
   * Render
   *----------------------------------------------------------*/
  const optId = value => `listbox_${name}-option_${value}`;

  return <div
    {...rest}
    className={`select__container pseudo-select${maybeClassName(className)}`}
    onBlur={({ currentTarget }) => {
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) setOpen(false)
      });
    }}
  >
    <select
      onChange={onChange}
      name={name}
      value={value}
      tabIndex={-1}
      {...required && {
        required: true,
        'aria-required': true,
      }}
      {...disabled && {
        disabled: true,
        'aria-disabled': true,
      }}
      className="pseudo-select__hidden-input"
    >
      {placeholder && <option disabled>{placeholder}</option>}
      {options.map(({ label, value: oValue }) => (
        <option key={oValue} value={oValue}>{label}</option>
      ))}
    </select>
    <Pop
      className="pseudo-select__input select__input input"
      ref={toggle} onClick={() => toggleMenu()}
      aria-labelledby={`${ariaLabelledby} listbox_${name}-selected`}
      aria-haspopup="listbox"
      aria-expanded={isOpen}
      aria-required={required || undefined}
      aria-disabled={disabled || undefined}
      // `<button>`'s are `type="submit"` by default.
      type="button"
      onKeyDown={e => { if (isKey(e, ['ArrowDown', 'ArrowUp'])) { e.preventDefault(); toggleMenu(true); } }}
    >
      <span id={`listbox_${name}-selected`}>{get_label_from_value(options, value) || placeholder}</span>
    </Pop>
    <Popped
      {...isOpen && {
        options: { placement: 'bottom-start' },
        // By not passing referenceElement, we destroy the Popper instance and improve performance.
        referenceElement: toggle.current,
      }}
      className="select__options"
      data-open={isOpen}
    >
      <ul
        role="listbox"
        className="select__options__list"
        tabIndex="-1"
        aria-activedescendant={optId(options[focussed].value)}
        aria-labelledby={ariaLabelledby}
        ref={menu}
        onKeyDown={navigateMenu}
        aria-required={required || undefined}
        aria-disabled={disabled || undefined}
      >
        {options.map((option, i) => {
          const { value: oValue, label } = option;
          return <li
            ref={el => items.current[i] = el}
            role="option" key={oValue}
            id={optId(oValue)}
            aria-selected={oValue === value}
            data-text={label}
            data-active={i === focussed}
            data-selected={oValue === value}
            onMouseEnter={() => isOpen && setFocussed(i)}
            onClick={e => { setOpen(false); onChange(option, e) }}
          >
            {label}
          </li>
        })}
      </ul>
    </Popped>
    <span
      className="select__caret"
      onClick={e => { e.preventDefault(); toggleMenu() }}
    >
      <SvgCaret />
    </span>
  </div>
}

Listbox.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  'aria-labelledby': PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  'aria-required': PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['true', 'false'])
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  className: PropTypes.string,
}

export default Listbox;