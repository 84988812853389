import React, { createRef } from "react";
import PropTypes from "prop-types";
import LoopPost from '../Common/LoopPost';
import { Container, Row, Col } from "reactstrap";
import { fetchPosts } from '../../api';
import ButtonLink from '../Common/ButtonLink';
import PageFooter from "../Common/PageFooter";
import ParsedWysiwyg from '../Common/ParsedWysiwyg';
import Moment from 'react-moment';
import classnames from 'classnames';
import { withStrings } from "../Contexts/StringsContext";
import SkipToMain from "../Common/SkipToMain";

class PageHome extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			posts: undefined,
			tweets: undefined,
			hero_paused: false,
		};

		['toggleVideo'].forEach(binding => this[binding] = this[binding].bind(this));

		this.videoRef = createRef();
	}

	componentDidMount() {
		fetchPosts('posts', { per_page: 2 })
			.then(({ data: posts }) => {
				this.setState({
					posts
				});
			});

		fetchPosts('social-media-posts', { per_page: 3 })
			.then(({ data: tweets }) => {
				this.setState({
					tweets
				});
			});
	}

	toggleVideo = () => {
		const { videoRef: { current: videoRef } } = this;
		const { hero_paused } = this.state;

		if (!videoRef) return;

		if (hero_paused) videoRef.play()
		else videoRef.pause()

		this.setState({ hero_paused: !hero_paused })
	}

	render() {
		const {
			strings = {},
			acf: {
				hero_foreground_image,
				hero_background_image,
				hero_background_video,
				hero_copy,
				hero_ctas_enabled,
				hero_patients_button,
				hero_hcp_button,
				introduction_headline,
				introduction_copy,
				introduction_call_to_action,
				newsroom_tease_enabled,
				newsroom_tease_headline,
				newsroom_tease_call_to_action,
				sections_enabled,
				sections,
				video_callout_enabled,
				video_callout_quote,
				video_callout_quotee,
				video_callout_enable_cta,
				video_callout_call_to_action,
				video_callout_background_image,
				twitter_feed_enabled,
				twitter_feed_call_to_action,
				calls_to_action,
				footer_wysiwyg: { content: footer_wysiwyg_content } = {},
			} = {},
		} = this.props;

		const { posts, tweets, hero_paused } = this.state;
		const { media: { play_video, pause_video } = {} } = strings;

		return <>
			<SkipToMain />
			<div className="hero">
				<div className="hero__video-container">
					<video className="hero__video" ref={this.videoRef} playsInline autoPlay muted loop poster={hero_background_image.sizes.xxl}>
						<source src={hero_background_video.webm.url} type="video/webm" />
						<source src={hero_background_video.mp4.url} type="video/mp4" />
					</video>
				</div>
				<Container className="hero__content">
					<h1 className="hero__image">
						<img src={hero_foreground_image.url} alt={hero_foreground_image.alt} />
					</h1>
					<div className="hero__copy">{hero_copy}</div>
					<button aria-live="polite" className={`hero__pause js-${hero_paused ? 'paused' : 'playing'}`} onClick={this.toggleVideo}>
						<i aria-hidden="true" className={`hero__pause-icon icon icon-${hero_paused ? 'play' : 'pause'}`}></i>
						<span aria-atomic="true" className="sr-only">{hero_paused ? play_video : pause_video}</span>
					</button>
					{hero_ctas_enabled &&
						<div className="hero__buttons">
							<ButtonLink className="hero__button" url={hero_patients_button.url} title={hero_patients_button.title} target={hero_patients_button.target} size="xl" type="solid-green" arrow />
							<ButtonLink className="hero__button" url={hero_hcp_button.url} title={hero_hcp_button.title} target={hero_hcp_button.target} size="xl" type="solid-gold" arrow />
						</div>
					}
				</Container>
			</div>

			<Container className="introduction">
				<Row className="justify-content-center">
					<Col md="10" lg="8">
						<h2 className="introduction__headline">{introduction_headline}</h2>
						<div className="introduction__copy">{introduction_copy}</div>
						<ButtonLink className="introduction__button" url={introduction_call_to_action.url} title={introduction_call_to_action.title} target={introduction_call_to_action.target} size="xl" type="link" arrow />
					</Col>
				</Row>
			</Container>

			{newsroom_tease_enabled &&
				<Container className="newsroom-tease">
					<Row className="justify-content-center">
						<Col className="newsroom-tease__inner" md="10">
							<header className="newsroom-tease__header">
								<h2 className="newsroom-tease__headline">{newsroom_tease_headline}</h2>
								<ButtonLink className="newsroom-tease__button" url={newsroom_tease_call_to_action.url} title={newsroom_tease_call_to_action.title} target={newsroom_tease_call_to_action.target} size="sm" type="red" />
							</header>
							{Array.isArray(posts) && posts.length > 0 &&
								<div className="newsroom-tease__posts">
									{posts.map((post => <LoopPost key={post.ID} className="newsroom-tease__post" data={post} />))}
								</div>
							}
						</Col>
					</Row>
				</Container>
			}

			{sections_enabled &&
				<div className="sections">
					{sections.map((section, index) =>
						<div key={index} className="section">
							<Container className="section__container">
								<Row className="justify-content-center">
									<Col className="section__inner" md="10">
										<div className="section__text">
											<h2 className="section__headline">{section.headline}</h2>
											<div className="section__copy">{section.copy}</div>
											{section.call_to_action.url && (
											<ButtonLink className="section__button" url={section.call_to_action.url} title={section.call_to_action.title} target={section.call_to_action.target} size="xl" type="link" arrow />
											)}
										</div>
										<div className="section__circle">
											{section.circle.type === 'number' &&
												<div className="section__number">{section.circle.number}</div>
											}
											{section.circle.type === 'icon' &&
												<div className={classnames('section__icon', `icon-${section.circle.icon}`)}></div>
											}
											<div className="section__caption">{section.circle.caption}</div>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					)}
				</div>
			}

			{video_callout_enabled &&
				<div className="video-callout" style={{ backgroundImage: 'url(' + video_callout_background_image.sizes.section + ')' }}>
					<Container className="video-callout__container">
						<Row className="justify-content-center">
							<Col className="video-callout__inner" md="10">
								<div className="video-callout__text">
									<div className="video-callout__quote">{video_callout_quote}</div>
									<div className="video-callout__quotee">{video_callout_quotee}</div>
								</div>
								{video_callout_enable_cta &&
									<a className="video-callout__button" href={video_callout_call_to_action.url} target={video_callout_call_to_action.target}>{video_callout_call_to_action.title}</a>
								}
							</Col>
						</Row>
					</Container>
				</div>
			}

			{twitter_feed_enabled && Array.isArray(tweets) && tweets.length > 0 &&
				<div className="twitter-feed">
					<Container className="twitter-feed__container">
						<Row className="justify-content-center">
							<Col className="twitter-feed__inner" md="10">
								<div className="twitter-feed__tweets">
									{tweets.map(tweet =>
										<div key={tweet.ID} className="twitter-feed__tweet">
											<header className="twitter-feed__header">
												<a className="twitter-feed__user" href={'https://www.twitter.com/' + tweet.user} target="_blank" rel="noopener noreferrer">@{tweet.user}</a> · <span className="tweet-feed__date"><Moment format="MMM D">{tweet.date}</Moment></span>
											</header>
											<div className="twitter-feed__content">
												<ParsedWysiwyg htmlString={tweet.content.rendered} linkify />
												{tweet.image &&
													<img className="twitter-feed__image" src={tweet.image.sizes.medium_large} alt={tweet.image.alt} />
												}
											</div>
										</div>
									)}
								</div>
								<footer className="twitter-feed__footer">
									<ButtonLink className="twitter-feed__button" url={twitter_feed_call_to_action.url} title={twitter_feed_call_to_action.title} target={twitter_feed_call_to_action.target} size="sm" type="red" />
								</footer>
							</Col>
						</Row>
					</Container>
				</div>
			}

			<PageFooter calls_to_action={calls_to_action} />

			{footer_wysiwyg_content &&
				<Container className="footer-wysiwyg">
					<Row className="justify-content-center">
						<Col md="10">
							<ParsedWysiwyg className="wysiwyg-content" htmlString={footer_wysiwyg_content} />
						</Col>
					</Row>
				</Container>
			}
		</>
	}
}

PageHome.propTypes = {
	acf: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	strings: PropTypes.object.isRequired,
};

PageHome.defaultProps = {
	acf: {
		hero_foreground_image: [],
		hero_background_image: [],
		hero_background_video: [],
		hero_copy: "",
		hero_ctas_enabled: true,
		hero_patients_button: [],
		hero_hcp_button: [],
		introduction_headline: "",
		introduction_copy: "",
		introduction_call_to_action: [],
		newsroom_tease_enabled: false,
		newsroom_tease_headline: "",
		newsroom_tease_call_to_action: [],
		sections_enabled: false,
		sections: [],
		video_callout_enabled: false,
		video_callout_quote: "",
		video_callout_quotee: "",
		video_callout_enable_cta: true,
		video_callout_call_to_action: [],
		video_callout_background_image: [],
		calls_to_action: [],
		twitter_feed_enabled: false,
		twitter_feed_call_to_action: [],
		banner_image: {},
		footer_wysiwyg: {},
	}
};

export default withStrings(PageHome);
