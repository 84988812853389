
/**
 * Faceter
 *
 * @package ptc-therapeutic
 */

import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from 'prop-types';
import { buildFwpParams, fetchFacets, fetchFwpSettings } from '../../api/facetwp';
import { useLocation, useHistory } from "react-router-dom";
import {
  mapValues as _mapValues,
  fromPairs as _fromPairs,
  castArray,
  mapValues,
  isPlainObject
} from 'lodash';
import { SettingsContext } from "../Contexts/SettingsContext";
import { Err } from "../../utils/misc";

const Context = React.createContext({
  facets: {},
  posts: [],
  page: [],
})

/**
 * Reducer for `fields` state.
 *
 * @param {Object} state Previous fields. An object of Sets.
 * @param {Object} dispatch Dispatch settings.
 * @param {'delete'|'insert'|'reset'|'set'} dispatch.type Action to perform on payload.
 * @param {Object|String[]|String} dispatch.payload Payload containing updates. Value depends on type  
 * * delete: {Object} field: Values to delete keyed by facet name.
 * * insert: {Object} field: Values to insert keyed by facet name.
 * * reset: {String[]|String|undefined} Facet name(s) of to reset, or omit to reset all.
 * * set: {Object}: State to merge into existing state
 * @param {*} dispatch.value Value to update state with. Operation depends on `dispatch.type`.
 */
export const reducer = (state, { type, payload }) => {
  if (
    ['delete', 'set', 'insert',].includes(type) &&
    Array.isArray(payload) &&
    2 === payload.length
  ) {
    payload = {
      [payload[0]]: payload[1]
    }
  }
  if (isPlainObject(payload)) {
    payload = mapValues(payload, castArray)
  }

  switch (type) {
    case 'reset':
      if (payload) {
        // Reset some
        const facets = castArray(payload);
        return {
          ...state,
          ...facets.reduce((obj, facet) => {
            obj[facet] = new Set
            return obj;
          }, {})
        }
      } else {
        // Reset all
        return mapValues(state, () => new Set)
      }
    case 'delete':
      return {
        ...state,
        ...mapValues(payload, (values, facet) => {
          let newSet = new Set(state[facet])
          values.forEach(value => newSet.delete(value))
          return newSet
        })
      }
    case 'set':
      return {
        ...state,
        ...mapValues(payload, (values) => new Set(values.filter(Boolean)))
      }
    case 'insert':
      return {
        ...state,
        ...mapValues(payload, (values, facet) => {
          let newSet = new Set(state[facet])
          values.forEach(value => newSet.add(value))
          return newSet
        })
      }
  }
}

/**
 * Handles FacetWP logic, e.g. updates to facet fields, page, and post query.
 * Provides context containing FacetWP results: facets, pagination data, and posts.
 *
 * @param {Object} props 
 * @param {Object} props.facets Facet values keyed by facet name.
 * @param {Object} props.query Custom query used when fetching results.
 * @param {Function} props.onChange Callback after sucessfully fetching results.
 * @param {Function} props.onError Callback after failing to fetch results.
 * 
 * @returns {Object}
 */
export const useFaceter = props => {
  const { updateUrl = true, facets: fields, page, sort, query = {}, onChange = () => { }, onError = () => { } } = props;

  /*------------------------------------------------------------
   * Internals
   *----------------------------------------------------------*/
  const prefix = useRef(null);
  const sortOpts = useRef({});

  /*------------------------------------------------------------
   * Hooks
   *----------------------------------------------------------*/
  const location = useLocation();
  const history = useHistory();
  const { posts_per_page } = useContext(SettingsContext);

  /*------------------------------------------------------------
   * State
   *----------------------------------------------------------*/
  /** Facet settings from FacetWP api. */
  const [facets, setFacets] = useState({});
  /** Post IDs from FacetWP api. */
  const [posts, setPosts] = useState(undefined);
  /** Pager from FacetWP api. */
  const [pager, setPager] = useState({});

  /*------------------------------------------------------------
   * Effects
   *----------------------------------------------------------*/
  /*
   * Initialization.
   */
  useEffect(() => {
    // Get FacetWP settings.
    fetchFwpSettings(['prefix', 'sort_options'])
      .then(({ data: { prefix: fPrefix, sort_options } }) => {
        prefix.current = fPrefix;
        sortOpts.current = sort_options;
      }, ({ error }) => {
        const err = new Err({ name: 'PluginError', ...error });
        prefix.current = err;
        sortOpts.current = err;
        onError(err);
      })
  }, [])

  /*
   * Send request to FacetWP when the query, fields, page, sort, or facets to use are updated.
   */
  useEffect(() => {
    const isInitial = undefined === posts;

    // Construct api args.
    const facetArgs = {
      facets: {
        ..._fromPairs(Object.keys(fields).map(slug => [slug, null])), // Defaults
        ...fields // Values
      },
      query_args: {
        posts_per_page,
        ...query,
        ...(sortOpts.current?.[sort]?.query_args ?? {}),
        paged: page,
      }
    }

    fetchFacets(facetArgs)
      .then(({ data }) => {
        const { facets, pager, results } = data;
        const { page } = pager;

        setFacets(facets);
        setPager(pager);
        setPosts(results);

        // Update URL according to field values.
        if (updateUrl && !isInitial) {
          // Prefix fields, filter out empty fields, and url encode.
          const urlFields = buildFwpParams(
            {
              ..._mapValues(facets, 'selected'),
              paged: 1 === page ? null : page,
              sort: 'default' === sort ? null : sort,
            },
            prefix.current
          )

          history.replace({
            location: location.pathname,
            search: urlFields,
          });
        }

        onChange(data);
      })
      .catch(({ error }) => {
        onError(error)
      })
  }, [page, sort, JSON.stringify(fields), JSON.stringify(query)])

  return {
    facets,
    posts,
    pager
  }
}

export const FwpFaceter = ({ children: render, ...config }) => {
  const context = useFaceter(config);

  return render(context)
}

FwpFaceter.propTypes = {
  /** Children to render. */
  children: PropTypes.func,
  /** Facets */
  facets: PropTypes.object.isRequired,
  /** WP_Query to use. */
  query: PropTypes.object,
  /**
   * Callback when new facets are fetched. Receives FacetWP api response object, 
   * see 'Reponse data' section in {@link https://facetwp.com/introducing-the-facetwp-rest-api/ FacetWP API docs}.
   */
  onChange: PropTypes.func,
  /** Callback when error occurs. Receives array of Err objects. */
  onError: PropTypes.func,
};

/**
 * Return specific facet's settings and setter.
 *
 * @param {String} facetSlug Slug of facet to get settings for.
 * @returns {Array} Facet data for `facetSlug` (see `facets` in 'Reponse data' section in 
 *   {@link https://facetwp.com/introducing-the-facetwp-rest-api/ FacetWP API docs}) 
 *   and a setter function that takes facet's value (see 'Full example' section in 
 *   {@link https://facetwp.com/introducing-the-facetwp-rest-api/ FacetWP API docs}).
 */
export const useFacet = facetSlug => {
  const { facets: { [facetSlug]: facet = {} }, setFields = () => { } } = useContext(Context);
  return [facet, (type, value) => setFields({ type, field: facetSlug, value })];
}

/**
 * Component with access to a facet's settings and setter.
 *
 * @param {Object} props Component properties.
 * @param {String} props.facet Facet to get data for.
 * @param {Function} props.render Function will be passed facet data for `props.facet` (see `facets` in 'Reponse data'
 *   section in  {@link https://facetwp.com/introducing-the-facetwp-rest-api/ FacetWP API docs}) 
 *   and a setter function that takes facet's value (see 'Full example' section in 
 *   {@link https://facetwp.com/introducing-the-facetwp-rest-api/ FacetWP API docs}).
 */
export const FwpFacet = ({ facet: facetSlug, render }) => {
  const [facet, setFacet] = useFacet(facetSlug);
  return render(facet, setFacet);
}

/**
 * Component with access to context.
 *
 * @param {Object} props props.
 * @param {Function} props.render Render function, passed the fields setter.
 */
export const FwpContext = ({ render }) => {
  const context = useContext(Context);
  return render(context);
}

/**
 * Return pager data and setter for current page.
 *
 * @returns {Array} Pager data (see `pager` in 'Reponse data' section in 
 *   {@link https://facetwp.com/introducing-the-facetwp-rest-api/ FacetWP API docs}) 
 *   and a setter function that takes the page number.
 */
export const usePager = () => {
  const { pager = [{}, () => { }] } = useContext(Context);
  return pager;
}

/**
 * Component with access to pagination settings and setter.
 *
 * @param {Object} props Component properties.
 * @param {Function} props.render Function will be passed pager data (see `pager` in 'Reponse data' section in 
 *   {@link https://facetwp.com/introducing-the-facetwp-rest-api/ FacetWP API docs}) 
 *   and a setter function that takes the page number.
 */
export const FwpPager = ({ render }) => {
  const [pager, setPage] = usePager();
  return render(pager, setPage);
}

/**
 * Return current posts.
 *
 * @returns {Number[]} Array of post ids (see `results` in 'Reponse data' section in 
 *   {@link https://facetwp.com/introducing-the-facetwp-rest-api/ FacetWP API docs}).
 */
export const usePosts = () => {
  const { posts = [] } = useContext(Context);
  return posts;
}

/**
 * Component with access to post data.
 *
 * @param {Object} props Component properties.
 * @param {Function} props.render Function will be passed array of post ids.
 */
export const FwpTemplate = ({ render }) => {
  const posts = usePosts();
  return render(posts);
}