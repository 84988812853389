import React from "react";
import PropTypes from "prop-types";
import { Form, Label, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withStrings } from "../Contexts/StringsContext";
import ParsedWysiwyg from '../Common/ParsedWysiwyg';
import InputWithPlaceholder from '../Common/InputWithPlaceholder';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
    };
  }

  handleChange = e => {
    this.setState({ input: e.target.value });
  };

  handleSearch = e => {
    const { history } = this.props;
    const { input } = this.state;

    e.preventDefault();
    e.stopPropagation();

    let menu = document.querySelector(".navbar-collapse");
    let menuToggle = document.querySelector(".navbar-toggler");
    menu.classList.remove("show");
    menuToggle.classList.remove("expanded");
    menuToggle.classList.add("collapsed");

    history.push(input ? `/search?term=${encodeURIComponent(input)}&page=1` : '/search')
    this.setState({ input: '' })
  };

  render() {
    const { strings = {} } = this.props;
    const { search: { placeholder } = {} } = strings;
    const { input } = this.state;

    return (
      <div className='searchbar-wrapper' >
        <Form className='search-form' onSubmit={this.handleSearch}>
          <Label className='sr-only' for='autocomplete'>
            <ParsedWysiwyg htmlString={placeholder} />
          </Label>
          <InputWithPlaceholder
            id="autocomplete"
            onChange={this.handleChange}
            onSubmit={this.handleSearch}
            className='search-form-input'
            value={input}
            placeholder={placeholder}
          >
            <i className="search-form-icon icon icon-search" />
          </InputWithPlaceholder>
          <Button
            type='submit'
            onClick={this.handleSearch}
            className='search-form-button'
            aria-label="Submit search"
          />
        </Form>
      </div>
    );
  }
}

SearchBar.propTypes = {
  strings: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(withStrings(SearchBar));
