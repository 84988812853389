/**
 * Alert.
 *
 * @package frontend-ui
 */

import React from 'react'
import PropTypes from 'prop-types';
import * as Exclamations from '../../Svg/Exclamation'
import { pascalCase } from '../../../utils/strings'

/**
 * Simple alert box.
 *
 * @param {Object} props 
 * @param {Boolean} props.icon
 * @param {String} props.title
 * @param {String} props.color
 * @param {String} props.content
 */
function Alert({ icon, content, title, color }) {
  // eslint-disable-next-line import/namespace
  const Exclamation = icon ? Exclamations[pascalCase(color)] : undefined;

  return <div className={`centered alert__body alert__body--${color}`}>
    {title && <div className="alert__header">{title}</div>}
    <div className="alert__main">
      {Exclamation && <Exclamation className="alert__icon" />}
      <div className="alert__content">{content}</div>
    </div>
  </div>
}

Alert.propTypes = {
  icon: PropTypes.bool,
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
}

export default Alert;