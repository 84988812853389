/**
 * Compiled search templates
 *
 * @package ptc-therapeutic
 */

import { template, reduce } from "lodash";
import { useContext, useMemo } from "react";
import { StringsContext } from "../components/Contexts/StringsContext";

/**
 * Compile lodash templates for search strings.
 * 
 * @returns {Object}
 */
function useSearchTemplates() {
  const { search: { results_header = {} } = {} } = useContext(StringsContext);

  return reduce(['none', 'singular', 'plural'], function (templates, key) {
    const str = results_header[key]

    templates[key] = useMemo(function () {
      return str
        ? template(str, {
          interpolate: /%\(([\w\d]+)\)[a-z]/g,
          evaluate: false
        })
        : null
    }, [str])

    return templates
  }, {})
}

export default useSearchTemplates;