import * as React from "react";
import PropTypes from "prop-types";
import { withStrings } from "../../Contexts/StringsContext";
import Loading from "../../Common/Loading";
import RichText from "./RichText";
import { InvestigatorForm } from "./InvestigatorForm";

class InvestigatorWrapperBase extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.forceUpdate();
  }


  render() {
    const { method, url, strings } = this.props;
    const { investigator_form } = this.props.strings;

    if (!investigator_form) {
      return <Loading />;
    }
    return <div className="external-funding-form">
      <div className="eff__preface-text">
        <RichText wysiwyg={investigator_form.form_text.preface_text} />
      </div>
      <div className="centered">
        <InvestigatorForm {...{ method, url, strings }} />
      </div>
    </div>
  }
}

InvestigatorWrapperBase.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
}

export const InvestigatorFormWrapper = withStrings(InvestigatorWrapperBase);
InvestigatorFormWrapper.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
