import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Col, Row } from "reactstrap";
import SearchResults from "../ACF/Layouts/SearchResults";
import PageHeader from "../Common/PageHeader";
import ErrorMessage from "../Common/ErrorMessage";
import Loading from "../Common/Loading";
import SkipToMain from '../Common/SkipToMain';
import SearchBar from "../Common/SearchBar";
import { withStrings } from "../Contexts/StringsContext";
import { PagePropTypes } from "../../utils/misc";
import { maybeClassName } from "../../utils/inputs";
import useSearchTemplates from "../../hooks/useSearchTemplates";
import { isInteger } from "lodash";

function SearchPage(props) {
  const { title, acf, location, error, isLoading } = props;

  const urlParams = new URLSearchParams(location.search);
  const searchQuery = urlParams.get('term');

  const [resultsTotal, setResultsTotal] = useState()
  const [isSearchLoading, setSearchLoading] = useState(true)

  const result_header_templates = useSearchTemplates()

  // Sets state from SearchResults to retrieve latest result count and number of pages to
  // feed into the PageHeader -> SearchHeader component
  function handleSearch(resultsTotal) {
    setResultsTotal(resultsTotal)
    setSearchLoading(false)
  }

  /*
   * Translate header with placeholders
   */
  let results_header_text, results_header_template

  if (!isSearchLoading && isInteger(resultsTotal)) {
    switch (resultsTotal) {
      case 0:
        results_header_template = result_header_templates.none
        break;

      case 1:
        results_header_template = result_header_templates.singular
        break;

      default:
        results_header_template = result_header_templates.plural
    }
  }

  if (results_header_template) {
    results_header_text = results_header_template({
      term: searchQuery,
      results: resultsTotal
    })
  }

  return <>
    <PageHeader
      title={title.rendered}
      image={acf.banner_image}
      section={title.rendered}
      after={<SearchBar className='search-form--full' />}
    />

    {isLoading ?
      <Loading />
      :
      <div className="page-main">
        <div className={`page-content${maybeClassName(resultsTotal && 'js-has-search-results')}`}>
          <SkipToMain />
          <Container className='layouts-container'>
            <Row className="justify-content-center">
              <Col sm="10">
                <ErrorMessage error={error} />

                {results_header_text && (
                  resultsTotal > 0
                    ? <h2 className="heading heading--2 mb-4">{results_header_text}</h2>
                    : <div className="empty-area">
                      <h2 className="empty-area__title">{results_header_text}</h2>
                    </div>
                )}

                <SearchResults
                  term={searchQuery}
                  page={parseInt(urlParams.get('page')) || 1}
                  onSearching={() => { setSearchLoading(true) }}
                  onSearched={handleSearch}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    }
  </>
}


SearchPage.propTypes = {
  ...PagePropTypes,
  page: PropTypes.object,
  error: PropTypes.object,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  strings: PropTypes.shape({
    search: PropTypes.shape({
      placeholder: PropTypes.string
    })
  }),
};

SearchPage.defaultProps = {
  title: {
    rendered: ""
  },
  section: '',
  acf: {
    content: [],
    calls_to_action: [],
    banner_image: {}
  },
  isLoading: false,
};

export default withStrings(SearchPage);
