/**
 * ArchiveEvents
 *
 * @package ptc-therapeutic
 */
import React, { useContext, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Container, Row } from 'reactstrap';
import SkipToMain from '../Common/SkipToMain';
import { useFaceter, reducer } from '../FacetWp/Faceter';
import { SettingsContext } from '../Contexts/SettingsContext';
import { chain, keyBy, map, pick, transform } from 'lodash';
import { StringsContext } from '../Contexts/StringsContext';
import classNames from 'classnames';
import { buildFwpParams, extractFwpParams } from '../../api/facetwp';
import { useHistory } from 'react-router';
import { fetchPosts } from '../../api';
import LoopEvent from '../Common/LoopEvent';
import Pagination from '../Common/Pagination';
import Loading from '../Common/Loading';
import Sticky, { STATUS_FIXED } from 'react-stickynode';
import { breakpoints, headerHeight } from '../../utils/misc';
import useMediaMatch from '@rooks/use-media-match';
import useStickyFreeze from '../../hooks/useStickyFreeze';
import ParsedWysiwyg from '../Common/ParsedWysiwyg'
import FeaturedEvent from '../Common/FeaturedEvent';

/**
 * Map of facet keys to English names.
 *
 * We identify facets by custom argument `key`, since we expect the `name` to be translated by the user.
 */
const FACET_KEYS = {
  // Audience
  facet_616d73b3acd1c: {
    name: 'audience',
    default: []
  },
  // Language,
  facet_5ece4797eaf5e: {
    name: 'language',
    default: []
  },
  // Date range
  facet_616eddafe9f88: {
    name: 'date_range',
    default: 'next'
  },
};

const TODAY = new Date()

/**
 * String for upcoming events.
 *
 * FacetWP separates the date and time with a hyphen for minimum date.
 */
const NEXT_STR = `${TODAY.toISOString().slice(0, 10)}-${TODAY.toISOString().slice(11, -5)}`

/**
 * String for upcoming events.
 *
 * FacetWP separates the date and time with a space for maximum date.
 */
const PREV_STR = `${TODAY.toISOString().slice(0, 10)} ${TODAY.toISOString().slice(11, -5)}`

/** Wrapping breakpoint name. */
const BREAKPOINT_WRAP = 'lg'

/** Media query string for large breakpoint */
const mediaQueryLg = `(min-width: ${breakpoints[BREAKPOINT_WRAP]}px)`

/**
 * Translate date_range between facet value and field/url value.
 *
 * @param {String[]|String} val Date range formatted as facet (array) or field/url (string).
 * @param {'field'|'facet'} to Format to convert to.
 * @returns {String[]|String}
 */
function transformDateRange(val, to = undefined) {
  const from = 'string' === typeof val
    ? 'field'
    : 'facet'

  if (undefined === to) {
    to = 'facet' == from
      ? 'field'
      : 'facet'
  }

  if (to === from) return val

  switch (to) {
    case 'facet':
      return 'prev' === val
        ? ['', PREV_STR]
        : [NEXT_STR, '']

    case 'field':
      if (val.length && !val[0]) {
        return 'prev'
      }
      return 'next'
  }
}

/**
 * React Component ArchiveEvents.
 */
const ArchiveEvents = props => {
  const {
    acf: {
      content,
      featured_post,
      featured_cta_content,
      featured_badge_content,
    } = {},
  } = props;

  const {
    events_per_page,
    fwp: {
      facets: allFacets,
      prefix
    } = {}
  } = useContext(SettingsContext);

  const {
    filters: {
      clear_all,
      no_results,
      apply,
      filters,
      sidebar_title
    } = {},
    event: {
      filter: {
        date_range: dateRangeLabels
      } = {}
    } = {}
  } = useContext(StringsContext);

  /** Sticky element */
  const stickyRef = useRef()

  /** Post list */
  const postsRef = useRef()

  const history = useHistory()
  const { location } = history

  const isBreakpointLg = useMediaMatch(mediaQueryLg)

  const { shouldFreeze, enabled } = useStickyFreeze(isBreakpointLg, stickyRef)

  /* Filters sidebar */
  const [sidebarOpen, setSidebarOpen] = useState(false)

  /** Whether a scroll to posts is pending */
  const isScrollQueued = useRef(false)

  /** Posts store */
  const [posts, setPosts] = useState(undefined)

  /** Sticky element state */
  const [isStuck, setStuck] = useState(false)

  /** Compute selected facet configurations keyed by English name */
  const selectedFacets = useMemo(() => {
    return chain(allFacets)
      .keyBy('key')
      .pick(Object.keys(FACET_KEYS))
      .transform((facets, facet, key) => {
        const config = FACET_KEYS[key];
        facets[config.name] = {
          ...facet,
          defaults: config.defaults
        }
      })
      .value()
  }, [])

  /** Compute facets rendered as checkboxes. */
  const checkboxFacets = useMemo(() => {
    return pick(selectedFacets, ['audience', 'language'])
  }, [])

  /** Compute initial facet values from defaults and URL */
  const initial = useMemo(() => {
    const facetNames = map(selectedFacets, 'name')

    const extracted = extractFwpParams(
      [...facetNames, 'page'],
      location.search,
      prefix
    )

    let initialCheckboxes = transform(checkboxFacets, (initial, facet) => {
      let value = extracted[facet.name] || facet.defaults

      initial[facet.name] = new Set(Array.isArray(value) ? value : [])
    })

    let initial = {
      ...initialCheckboxes,
      page: extracted.page || 1
    }

    if (selectedFacets.date_range) {
      initial[selectedFacets.date_range.name] = transformDateRange(
        extracted[selectedFacets.date_range.name] || 'next',
        'facet'
      )
    }

    return initial;
  }, [])

  const {
    page: initialPage,
    ...initialFields
  } = initial

  /* Applied facets */
  const [appliedFields, setAppliedFields] = useState(initialFields)

  /* Field values */
  const [fields, setFields] = useState(initialFields)

  /* Pagination number */
  const [page, setPage] = useState(initialPage)

  /** Date range field configuration */
  const dateRanges = dateRangeLabels && {
    name: 'date_range',
    label: dateRangeLabels.label,
    options: [
      {
        value: 'next',
        label: dateRangeLabels.next
      },
      {
        value: 'prev',
        label: dateRangeLabels.prev
      }
    ]
  }

  /** @type {String} Compute string equivalent of date_range facet (an array of representing the date range) */
  const dateRange = useMemo(
    () => (selectedFacets.date_range ?
      transformDateRange(appliedFields[selectedFacets.date_range.name], 'field')
      : 'next'
    ),
    [JSON.stringify(appliedFields[selectedFacets.date_range?.name])]
  )

  const orderDir = 'prev' === dateRange ? 'DESC' : 'ASC'

  const { facets: currentFacets, posts: currentIds, pager } = useFaceter({
    facets: appliedFields,
    query: {
      post_type: 'event',
      meta_query: {
        relation: 'AND',
        start_date: {
          key: 'start_date',
          compare: 'EXISTS'
        },
        start_time: {
          key: 'start_time',
          compare: 'EXISTS'
        },
      },
      orderby: {
        start_date: orderDir,
        start_time: orderDir,
      },
      posts_per_page: events_per_page,
    },
    updateUrl: false,
    page,
  })

  const isLoading = (
    undefined === currentFacets ||
    undefined === posts
  )

  /** Scroll posts into view */
  const scrollToPosts = useCallback(() => {
    if (postsRef.current) {
      let scrollTo = window.pageYOffset + postsRef.current.getBoundingClientRect().top - headerHeight;
      if (isBreakpointLg && stickyRef?.current?.outerElement) {
        scrollTo -= stickyRef.current.outerElement.clientHeight
      }
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  }, [postsRef?.current, stickyRef?.current?.outerElement, isStuck])

  // Fetch new posts
  useEffect(() => {
    let timeout

    if (currentIds) {
      if (currentIds.length) {
        fetchPosts('event', {
          include: currentIds
        })
          .then(({ data: posts }) => {
            posts = keyBy(posts, 'id')
            setPosts(currentIds.map(id => posts[id]))
          })
          .catch(() => {
            setPosts([])
          })
          .finally(() => {
            if (isScrollQueued.current) {
              timeout = setTimeout(() => {
                isScrollQueued.current = false
                scrollToPosts()
              }, 500);
            }
          })
      } else {
        setPosts([])
      }
    }

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [currentIds ? JSON.stringify(currentIds) : currentIds])

  // Update URL
  useEffect(() => {
    if (isLoading) return

    const urlFields = buildFwpParams(
      {
        ...appliedFields,
        // Add date range, if facet exists
        ...(
          selectedFacets.date_range
            ? { [selectedFacets.date_range.name]: transformDateRange(appliedFields[selectedFacets.date_range.name], 'field') }
            : false
        ),
        page: pager.page
      },
      prefix
    )

    history.replace({
      location: location.pathname,
      search: urlFields,
    })
  }, [JSON.stringify(appliedFields), pager.page])

  return <>
    <Container className='page-main'>
      <SkipToMain />

      <Row className="justify-content-center">
        <Col className="py-4 page-content" sm="10">
          <Row className="mb-3">
            <Col>
              {featured_post &&
                <FeaturedEvent
                  data={featured_post}
                  className='mb-4'
                  cta={featured_cta_content}
                  badge={{
                    icon: 'page',
                    content: featured_badge_content
                  }}
                />
              }
              {content && <div className="wysiwyg-content mb-2"><ParsedWysiwyg htmlString={content} /></div>}
              {/* Filter: date_range  */}
              {dateRanges &&
                <Sticky
                  innerClass="sticky-bar sticky-bar--col10"
                  innerActiveClass="is-stuck"
                  shouldFreeze={() => shouldFreeze}
                  enabled={enabled}
                  top={headerHeight}
                  innerZ={1}
                  ref={stickyRef}
                  onStateChange={({ status }) => { setStuck(STATUS_FIXED === status) }}
                >
                  <div className="sticky-bar__container py-2">
                    <fieldset role="radiogroup" aria-labelledby={`filters-${dateRanges.name}-title`}>
                      <legend className="sr-only" id={`filters-${dateRanges.name}-title`}>{dateRanges.label}</legend>
                      <div className="pills">
                        <div className='pills__flex'>
                          {dateRanges.options.map(({ value, label }) => {
                            return <label
                              key={value}
                              className={classNames('pills__pill', { active: dateRange === value })}
                            >
                              <input
                                checked={dateRange === value}
                                value={value}
                                onChange={() => {
                                  const newFields = {
                                    ...appliedFields,
                                    [selectedFacets.date_range.name]: transformDateRange(value, 'facet')
                                  }
                                  setFields(newFields)
                                  setAppliedFields(newFields)
                                  setPage(1)
                                }}
                                type="radio"
                                name='date_range'
                                className='pills__radio sr-only'
                              />
                              <span className='pills__text'>{label}</span>
                            </label>
                          })}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </Sticky>
              }
              {isLoading && <Loading />}
            </Col>
          </Row>

          {!isLoading &&
            <>
              <Row className="gx-2">
                <Col {...{ [BREAKPOINT_WRAP]: 3 }} className={`mb-2 mb-${BREAKPOINT_WRAP}-0`}>
                  <div className="sidebar sidebar--collapse">
                    <button className="sidebar__toggle" onClick={() => setSidebarOpen(!sidebarOpen)} aria-expanded={sidebarOpen} aria-controls="sidebar-filters">{filters}</button>
                    <Collapse id="sidebar-filters" className="sidebar__collapse" isOpen={sidebarOpen}>
                      <div className="sidebar__body">
                        {/* Filters */}
                        <div className="sidebar__title d-none d-lg-block mb-2">{sidebar_title}</div>
                        <div className="filters">
                          {Object.values(checkboxFacets).map(({ name }) => {
                            if (!currentFacets[name]) return;

                            const {
                              choices = [],
                              label
                            } = currentFacets[name];

                            const labelId = `filter-${name}-title`;
                            const selected = fields[name]

                            return <fieldset key={name} role="group" aria-labelledby={labelId} className={`filters__filter filter filter--checkboxes filter--${name}`}>
                              <legend className="filter__title" id={labelId}>{label}</legend>
                              <div className="checkboxes">
                                {choices.map(({ label, value, count }) => {
                                  const checked = selected && selected.has(value);
                                  /*
                                   * Only disable options...
                                   * * which will yield no results
                                   * * when there's any results
                                   */
                                  const disabled = 0 === count && currentIds.length;

                                  return <label
                                    key={value}
                                    className={classNames("checkbox__wrapper", { 'js-filled': checked, 'js-disabled': disabled })}
                                  >
                                    <div className="checkbox__element">
                                      <input
                                        className="sr-only"
                                        type="checkbox"
                                        checked={checked}
                                        name={name}
                                        disabled={disabled}
                                        onChange={() => {
                                          setFields(reducer(fields, {
                                            type: checked ? 'delete' : 'insert',
                                            payload: { [name]: value },
                                          }))
                                        }}
                                      />
                                      <div className="checkbox__decorator icon-"></div>
                                    </div>
                                    <span className="checkbox__label">{label}</span>
                                  </label>
                                })}
                              </div>
                            </fieldset>
                          })}
                        </div>

                        <div className="mt-2">
                          <div className="d-flex flex-wrap m-n1">
                            {/* Apply filters */}
                            <button
                              className="m-1 btn btn-dark"
                              onClick={() => {
                                setPage(1)
                                setAppliedFields(fields)
                              }}
                            >
                              {apply}
                            </button>
                            {/* Clear filters */}
                            <button
                              className="m-1 btn btn-link-alt"
                              onClick={() => {
                                const resetFields = reducer(fields, {
                                  type: 'reset',
                                  payload: map(checkboxFacets, 'name')
                                });
                                setPage(1)
                                setFields(resetFields)
                                setAppliedFields(resetFields)
                              }}
                            >
                              {clear_all}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </Col>

                <Col {...{ [BREAKPOINT_WRAP]: 9 }}>
                  {/* Posts */}
                  {posts.length < 1
                    ? no_results && <p className="my-5 text-center">{no_results}</p>
                    : <div ref={postsRef} className="posts">
                      {posts.map((post) =>
                        <LoopEvent
                          key={post.id}
                          data={post}
                        />)}
                    </div>
                  }
                </Col>
              </Row>

            </>
          }
          {!isLoading &&
            <Row>
              <Col>
                {/* Pager */}
                <Pagination
                  className="pagination--small mt-4"
                  current={pager.page}
                  totalRecords={parseInt(pager.total_rows)}
                  midSize={3}
                  perPage={pager.per_page}
                  onPageChanged={({ current }) => {
                    isScrollQueued.current = true
                    setPage(current)
                  }}
                />
              </Col>
            </Row>
          }
        </Col>
      </Row>
    </Container>
  </>
}

ArchiveEvents.propTypes = {
  acf: PropTypes.shape({
    /** Main page content */
    content: PropTypes.string
  })
}

export default ArchiveEvents;