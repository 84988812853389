import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Copy from '../../Common/Copy';
import ReactHtmlParser, { convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useSrc } from '../../../utils/cookies';
import { COOKIE_CATEGORIES } from '../../../utils/misc';
import { maybeClassName } from '../../../utils/inputs';
import { StringsContext } from '../../Contexts/StringsContext';

const Video = ({ caption, video, section_title }) => {
  if (!video) {
    return null;
  }

  let isYouTube = false;
  let embed_url;

  if ( video.url.includes("youtube") || video.url.includes("youtu.be") ) {
    isYouTube = true;

    const url = new URL(video.url);
    const params = new URLSearchParams(url.search);

    embed_url = `https://www.youtube-nocookie.com/embed/${params.get('v')}?rel=0&enablejsapi=1`

  } else {
    embed_url = htmlparser2.parseDOM(video.embed)[0].attribs['data-src'];
  }

  const cookie_category = ( isYouTube ) ? COOKIE_CATEGORIES.NECESSARY : COOKIE_CATEGORIES.ADVERTISING_TARGETING;
  const src = useSrc(embed_url, cookie_category);
  const strings = useContext(StringsContext);
  const { cookies: { embed_disabled } } = strings;

  return (
    <figure className="video-content">
      <div className={section_title ? 'section-title' : ''}>{section_title}</div>
      <div className="hytPlayerWrapOuter">
        <div className={`embed-responsive embed-responsive-16by9${maybeClassName(isYouTube && 'hytPlayerWrap')}`}>
          {isYouTube ?
            <iframe
              className="optanon-category-C0001"
              title={video.data.title}
              width="640" height="360"
              data-src={embed_url}
              src={src}
              frameBorder="0"
              allowFullScreen
            />
            :
            ReactHtmlParser(video.embed, {
              transform: (node, i) => {
                if (node.type === 'tag' && node.name === 'iframe') {
                  node.attribs.src = src;
                  return convertNodeToElement(node, i);
                }
              }
            })
          }
          {!src && !isYouTube && embed_disabled && <div className="embed__disabled">{ReactHtmlParser(embed_disabled)}</div>}
        </div>
      </div>
      <Copy className="figure-caption video-caption" tag="figcaption">
        {caption}
      </Copy>
    </figure>
  );
};

Video.propTypes = {
  caption: PropTypes.string,
  video: PropTypes.object,
  section_title: PropTypes.string
};

Video.defaultProps = {
  section_title: ""
};

export default Video;
