import React from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';
import {withStrings} from "../Contexts/StringsContext";
import { withSettings } from "../Contexts/SettingsContext";

class SocialNav extends React.Component {
    render() {
        const { strings, className, alternative, settings = {} } = this.props;
        const platforms = strings.social.platforms;
        const { name } = settings;

        if ( platforms.length ) {
            return (
                <nav className={classnames( className, 'social-nav' )}>
                    {platforms.map((platform, index) => {
                        return (
                            <a key={index} className={classnames('social-nav__platform', `social-nav__platform--${platform.platform.value}`, { 'social-nav__platform--alternative': alternative })} href={platform.url} target="_blank" rel="noopener noreferrer">
                                <span className="sr-only">{`${name} ${platform.platform.label}`}</span>
                            </a>
                        );
                    })}
                </nav>
            );
        } else {
            return false;
        }
    }
}

SocialNav.propTypes = {
    strings: PropTypes.object,
    className: PropTypes.string,
    alternative: PropTypes.bool,
    settings: PropTypes.object.isRequired,
};

export default withSettings(withStrings( SocialNav ));
