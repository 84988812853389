import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import ErrorMessage from "../Common/ErrorMessage";
import FlexibleContent from "../ACF/FlexibleContent";
import SkipToMain from '../Common/SkipToMain';
import { Link } from "react-router-dom";
import getPath from "../../api/relativePath";
import PageFooter from "../Common/PageFooter";
import { PagePropTypes } from "../../utils/misc";
import { AllHtmlEntities as Entities } from 'html-entities';

const decode = (new Entities()).decode;

const PageSidebar = props => {
  /*------------------------------------------------------------
   * Props, State, Context
   *----------------------------------------------------------*/
  const {
    acf: {
      content,
      calls_to_action,
      related_posts_posts,
      related_posts_heading,
      related_posts_link,
      'cta-link_link': { title: cta_link_title, url: cta_link_url, target: cta_link_target } = {},
      'cta-link_subheading': cta_subheading,
      'related-links_heading': related_links_heading,
      'related-links_links': related_links,
    },
    error,
  } = props;

  /*------------------------------------------------------------
   * Render
   *----------------------------------------------------------*/
  let cta_props = {};
  let cta_path = {};

  if (cta_link_url) {
    cta_path = getPath(cta_link_url);
    const { externalNewTab } = cta_path;

    cta_props = {
      target: cta_link_target || (externalNewTab ? '_blank' : undefined),
      className: "cta-link btn btn-gradient",
      children: <>
        {cta_link_title && <p className="cta-link__heading">{decode(cta_link_title)}</p>}
        {cta_subheading && <p className="cta-link__subheading">{cta_subheading}</p>}
      </>
    }
  }

  return <>
    <Container className="page-main">
      <Row className="justify-content-center">
        <Col sm="10">
          <Row className="justify-content-between">
            <Col xs="12" lg="8" className="pr-lg-4">
              <section className='page-content'>
                <SkipToMain />
                <ErrorMessage error={error} />
                <div className="layouts-container">
                  <FlexibleContent content={content} />
                </div>
              </section>
            </Col>

            <Col className="pl-lg-4">
              <section className="page-sidebar">
                {cta_link_url && (
                  cta_path.external ?
                    <a href={cta_path.path} {...cta_props} /> :
                    <Link to={cta_path.path} {...cta_props} />
                )}

                {Boolean(related_links && related_links.length > 0) && <div className="related-links">
                  {related_links_heading && <p className="related-links__heading">{related_links_heading}</p>}
                  <ul className="related-links__links">
                    {related_links.map(({ link: { title, target, url } }, i) => {
                      const { external, externalNewTab, path } = getPath(url);
                      const link_props = {
                        children: decode(title),
                        className: "related-links__link",
                        target: target || externalNewTab ? '_blank' : undefined
                      }
                      return <li key={i /* SHAME */} className="related-links__links-item">
                        {external ?
                          <a {...link_props} href={path} /> :
                          <Link {...link_props} to={path} />
                        }
                      </li>
                    })}
                  </ul>
                </div>}
              </section>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>

    <PageFooter
      social_share
      related_posts={{ posts: related_posts_posts, link: related_posts_link, heading: related_posts_heading }}
      calls_to_action={calls_to_action}
    />
  </>
}

PageSidebar.propTypes = {
  ...PagePropTypes,
  page: PropTypes.object,
  error: PropTypes.object,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
};

PageSidebar.defaultProps = {
  title: {
    rendered: ""
  },
  section: '',
  acf: {
    content: [],
    calls_to_action: [],
    banner_image: {}
  },
  isLoading: false,
};

export default PageSidebar;