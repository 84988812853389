import React from "react";
import PropTypes from "prop-types";
import { Form } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withStrings } from "../Contexts/StringsContext";
import InputWithPlaceholder from './InputWithPlaceholder';
import classNames from "classnames";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
    };
  }

  handleChange = e => {
    this.setState({ input: e.target.value });
  };

  handleSearch = e => {
    const { history } = this.props;
    const { input } = this.state;

    e.preventDefault();
    e.stopPropagation();

    history.push(input ? `/search?term=${encodeURIComponent(input)}&page=1` : '/search')
  };

  render() {
    const { strings = {}, className } = this.props;
    const { search: { placeholder, submit } = {} } = strings;
    const { input } = this.state;

    return (
      <Form className={classNames('search-form u-reset-form', className)} onSubmit={this.handleSearch}>
        <div className='search-form__field mg-field'>
          <div className="mg-field__wrapper">
            <label className='mg-field__label sr-only' htmlFor='autocomplete'>{placeholder}</label>
            <div className='mg-field__element mg-field-search'>
              <InputWithPlaceholder
                id="autocomplete"
                onChange={this.handleChange}
                onSubmit={this.handleSearch}
                className='mg-field-search__input'
                value={input}
                placeholder={placeholder}
                type='search'
              >
                <i className="mg-field-search__icon icon icon-search" />
              </InputWithPlaceholder>
            </div>
          </div>
        </div>
        <button
          type='submit'
          onClick={this.handleSearch}
          className='search-form__submit btn btn-gradient btn-arrow'
        >
          <span className="search-form__submit-label">{submit}</span>
        </button>
      </Form>
    );
  }
}

SearchBar.propTypes = {
  strings: PropTypes.object,
  history: PropTypes.object,
  className: PropTypes.string,
};

export default withRouter(withStrings(SearchBar));
