import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { keyBy } from 'lodash';
import { breakpoints } from '../../utils/misc';
import { handleResponse, fetchPosts } from '../../api';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, CarouselContext } from 'pure-react-carousel';

import LoopPost from './LoopPost';
import useMediaMatch from '@rooks/use-media-match';

const breakAt = 'lg';

const FeaturedPosts = props => {
  const {
    posts: pPosts = [],
    mediaTypes: pMediaTypes = undefined,
    strings = {},
    ...forwardProps
  } = props;

  const { carousel_title, badge_label } = strings;

  /*------------------------------------------------------------
   * State
   *----------------------------------------------------------*/
  const [mediaTypes, setMediaTypes] = useState(pMediaTypes);
  const [posts, setPosts] = useState(undefined);

  // Hooks
  const matchesBreakpointLg = useMediaMatch(`(min-width: ${breakpoints[breakAt]}px)`)

  /*------------------------------------------------------------
   * Effects
   *----------------------------------------------------------*/
  /*
   * Get media types on mount, if needed.
   */
  useEffect(() => {
    if (Array.isArray(mediaTypes)) return;
    fetch(`${process.env.BASE_URL}/wp-json/wp/v2/media-type`)
      .then(handleResponse)
      .then(({ data: mediaTypes }) => {
        setMediaTypes(mediaTypes);
        return mediaTypes;
      })
  }, [])

  /*
   * Get post objects if needed.
   */
  useEffect(() => {
    // Bail if not posts or no new posts.
    if (!pPosts.length) return;

    // Passed IDs and objects
    let ids = [], objs = {}
    pPosts.forEach(post => {
      if ('number' === typeof post) {
        ids.push(post)
      } else {
        objs[post.id] = post
      }
    })

    // IDs that need fetching
    let need = [], have = {}
    if (posts?.length) {
      ids.forEach(id => {
        const found = posts.find(({ id: hadId }) => id === hadId)
        if (undefined !== found) {
          have[id] = found
        } else {
          need.push(id)
        }
      });
    } else {
      need = ids
    }

    if (!need.length) {
      // We have all posts, source and sort them appropriately.
      setPosts(pPosts.map(post => {
        const id = 'number' === typeof post ? post : post.id
        return objs[id] ?? have[id]
      }))
      return;
    }

    fetchPosts('posts', { include: need })
      .then(({ data: nPosts }) => {
        // We have all posts, source and sort them appropriately.
        nPosts = keyBy(nPosts, 'id')
        setPosts(pPosts.map(post => {
          const id = 'number' === typeof post ? post : post.id
          return nPosts[id] ?? (objs[id] ?? have[id])
        }));
      })
  }, [JSON.stringify(pPosts.map(post => post?.id ?? post).sort())])

  /*------------------------------------------------------------
   * Render
   *----------------------------------------------------------*/
  if (!(mediaTypes?.length && posts?.length)) return null;

  const secondaryPosts = posts.slice(1, 4);

  const primaryPost = <LoopPost
    mediaTypes={mediaTypes}
    className="post--featured"
    data={posts[0]}
    badge={({ icon }) =>
      <span className="post__badge">
        <span className="post__type">{badge_label}</span>
        {icon && <i aria-hidden="true" className={`icon icon-${icon}`}></i>}
      </span>
    }
  />

  return <section {...forwardProps} className="featured-posts" aria-label={carousel_title}>
    {matchesBreakpointLg
      ? <ul className="featured-posts__list">
        <li className="featured-posts__item">
          {primaryPost}
        </li>
        {secondaryPosts.map((data) => {
          return <li key={data.id} className="featured-posts__item">
            <LoopPost
              mediaTypes={mediaTypes}
              className="post--inner post--horizontal"
              data={data}
            />
          </li>
        })}
      </ul>
      : <>
        {primaryPost}
        <CarouselProvider className="featured-posts__carousel" isIntrinsicHeight={true} totalSlides={secondaryPosts.length} orientation="horizontal">
          <Carousel strings={strings} mediaTypes={mediaTypes} posts={secondaryPosts} />
        </CarouselProvider>
      </>
    }

  </section>
}

/**
 * Contains Carousel elements that require react-pure-carousel state, e.g. current slide index
 * @link https://www.npmjs.com/package/pure-react-carousel#hooks-and-usecontext
 */
const Carousel = (props) => {
  const {
    posts,
    mediaTypes,
    strings: { next_slide, previous_slide, slide_title } = {},
  } = props;

  // Get state updates from react-pure-carousel
  const carouselContext = useContext(CarouselContext);

  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  const [totalSlides, setTotalSlides] = useState(carouselContext.state.totalSlides);

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
      setTotalSlides(carouselContext.state.totalSlides)
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return <>
    <Slider >
      {posts.map((data, index) =>
        <Slide aria-labelledby={`slide-${data.id}-label`} index={index} key={data.id}>
          <span id={`slide-${data.id}-label`} className="sr-only">{slide_title} {1 + index}</span>
          <LoopPost
            tabIndex={currentSlide === index ? undefined : -1}
            mediaTypes={mediaTypes}
            className="post--inner post--horizontal"
            data={data}
          />
        </Slide>
      )}
    </Slider>
    <div className="featured-posts__controls">
      <ButtonBack className="featured-posts__control featured-posts__prev">
        <span className="sr-only">{previous_slide}</span>
        <i className="icon icon-arrow-left" aria-hidden="true"></i>
      </ButtonBack>
      <span className="featured-posts__position">{currentSlide + 1} / {totalSlides}</span>
      <ButtonNext className="featured-posts__control featured-posts__next">
        <span className="sr-only">{next_slide}</span>
        <i className="icon icon-arrow-right" aria-hidden="true"></i>
      </ButtonNext>
    </div>
  </>
}

Carousel.propTypes = {
  mediaTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    acf: PropTypes.oneOfType([
      PropTypes.shape({
        media_type_cta: PropTypes.string.isRequired,
      }),
      PropTypes.array,
    ]).isRequired,
  })),
  strings: PropTypes.shape({
    next_slide: PropTypes.string.isRequired,
    previous_slide: PropTypes.string.isRequired,
    slide_title: PropTypes.string.isRequired,
    carousel_title: PropTypes.string.isRequired,
  }).isRequired,
  posts: PropTypes.arrayOf(PropTypes.object)
}

FeaturedPosts.propTypes = {
  mediaTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    acf: PropTypes.oneOfType([
      PropTypes.shape({
        media_type_cta: PropTypes.string.isRequired,
      }),
      PropTypes.array,
    ]).isRequired,
  })),
  strings: PropTypes.shape({
    next_slide: PropTypes.string.isRequired,
    previous_slide: PropTypes.string.isRequired,
    slide_title: PropTypes.string.isRequired,
    carousel_title: PropTypes.string.isRequired,
  }).isRequired,
  posts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.object]))
}

export default FeaturedPosts;