import React, { Component, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Container,
  NavItem,
  Nav,
  NavLink as BsNavLink,
  Dropdown as BsDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { camelCase, upperFirst, isEmpty, debounce } from 'lodash';
import Fade from "react-reveal/Fade";
import SearchBar from "./SearchBar";
import classnames from 'classnames';
import { Link, withRouter } from "react-router-dom";
import { withFocusVisible, FocusVisiblePropType } from "../Contexts/FocusVisibleManager";
import * as Icons from '../Svg/Menu'
import { StringsContext, withStrings } from "../Contexts/StringsContext";
import LogoWhite from '../../resources/svg/logo-white.svg'
import { AllHtmlEntities as Entities } from 'html-entities';
import { useHoverIntent } from 'react-use-hoverintent';
/* eslint-disable import/no-unresolved */
import '../../resources/svg/gradient-green-horizontal.svg?sprite'
import { BaseLink } from "../../api/relativePath";
import { breakpoints, MenuItemPropTypes } from "../../utils/misc";
import { ArrowDown } from '../Svg';

const entities = new Entities();

/**
 * Breakpoint at which to expand the menu
 */
const menuBreakpoint = 'lg';
const queryMenuBreakpoint = window.matchMedia(`(min-width: ${breakpoints[menuBreakpoint]}px)`)

class HeaderNav extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.togglerRef = React.createRef();

    this.handleScroll = debounce(this.handleScroll.bind(this), 10);
    this.updateMenuVisible = debounce(this.updateMenuVisible.bind(this), 50);
    this.handleMenuBreakpoint = this.handleMenuBreakpoint.bind(this)

    this.state = {
      isOpen: false,
      visibleMenu: undefined,
      isDesktopMenu: queryMenuBreakpoint.matches,
      isScrolled: false,
      headerOriginalTopBound: 0,
      menus: null,
    };
  }

  updateMenuVisible(ID) {
    this.setState({ visibleMenu: ID })
  }

  toggle(e) {
    this.setState({
      isOpen: !this.state.isOpen
    });
    e.preventDefault();
    //logic for locking body when menu is open on mobile views
    const toggler = document
      .querySelector(".navbar-toggler")
      .classList.contains("collapsed");
    document.querySelector(".page").classList.toggle("locked", !!toggler);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    queryMenuBreakpoint.addEventListener("change", this.handleMenuBreakpoint)
    this.setState({ headerOriginalTopBound: this.getHeaderBoundTop() });
  }

  handleMenuBreakpoint(mediaQuery) {
    this.setState({ isDesktopMenu: mediaQuery.matches })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    queryMenuBreakpoint.removeEventListener("change", this.handleMenuBreakpoint)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ isOpen: false });
    }
  }

  handleScroll = () => {
    let isScrolled = true;

    if (document.documentElement.scrollTop < this.state.headerOriginalTopBound) {
      isScrolled = false;
    }

    // Reduce number of re-renders by only updating state when the isScrolled value has changed
    if (isScrolled != this.state.isScrolled) {
      return this.setState({ isScrolled });
    }
  };

  getHeaderBoundTop() {
    const header = document.querySelector('.header');
    const headerBounds = header.getBoundingClientRect();

    return headerBounds.top;
  }

  render() {
    const { siteName, menu, focusVisible, strings = {} } = this.props;
    const { hadKeyboardEvent } = focusVisible;
    const { ui: { skip_to_content, toggle_menu } = {} } = strings;
    const { visibleMenu, isDesktopMenu } = this.state;

    return (
      <header className={classnames('header', { 'header--scrolled': this.state.isScrolled, 'has-open-menu': Boolean(visibleMenu) }, { 'header--open': this.state.isOpen })}>
        <Fade top>
          <a
            href='#main-content'
            className='skip-to-main sr-only sr-only-focus-visible'
            onFocus={({ target }) => { if (hadKeyboardEvent) { target.classList.add('focus-visible'); target.setAttribute('data-focus-visible-added', ''); } }}
          >
            {skip_to_content}
          </a>
          <Navbar
            aria-label="Main Menu"
            className={this.state.isScrolled ? "isScrollingNav" : ''}
            color='transparent'
            expand={menuBreakpoint}
          >
            <Container>
              <NavbarToggler
                onClick={this.toggle}
                className={`btn ${this.state.isOpen ? "expanded" : "collapsed"}`}
              >
                <span className='sr-only'>{toggle_menu}</span>
                <span className='navbar-toggler-bars' aria-hidden='true'>
                  <span className='navbar-toggler-bar' />
                  <span className='navbar-toggler-bar' />
                  <span className='navbar-toggler-bar' />
                  <span className='navbar-toggler-bar' />
                </span>
              </NavbarToggler>
              <NavbarBrand tag='div'>
                <Link to='/' className='navbar-brand-link'>
                  <LogoWhite
                    title={siteName}
                    className={
                      this.state.isScrolled
                        ? "navbar-brand-logo smallEnter"
                        : "navbar-brand-logo"
                    }
                  />
                </Link>
              </NavbarBrand>

              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="menu-header nav-menu" navbar onMouseLeave={() => { this.setState({ visibleMenu: null }) }}>
                  {menu.map((menuItem) => {
                    const { ID } = menuItem

                    if (hasChildren(menuItem)) {
                      return <Dropdown
                        key={ID}
                        isOpen={visibleMenu === ID}
                        menuItem={menuItem}
                        nav inNavbar
                        onHover={(isHovered) => { if (isDesktopMenu && isHovered) this.updateMenuVisible(ID) }}
                        toggle={() => { this.setState({ visibleMenu: ID === visibleMenu ? null : ID }) }}
                      />
                    }

                    return <MenuItem key={ID} menuItem={menuItem} />
                  })}
                </Nav>
              </Collapse>

              <SearchBar transparent location={location} />
            </Container>
          </Navbar>
        </Fade>
      </header>
    );
  }
}

/**
 * Determine whether a menu item has children
 * @param {Object} param0 WordPress menu item.
 * @returns {Boolean}
 */
function hasChildren({ children }) {
  return Boolean(children.length)
}

/**
 * Bootstrap NavLink using BaseLink as component/tag.
 */
const NavLink = React.forwardRef((props, ref) => <BsNavLink {...props} ref={ref} tag={BaseLink} />)

NavLink.displayName = 'NavLink'

/**
 * Recursive menu item.
 */
const MenuItem = React.forwardRef(({ menuItem = {}, className, inDropdown, ...props }, ref) => {
  const {
    title,
    url,
    depth,
    acf: {
      icon
    } = {}
  } = menuItem

  let iconName, Icon;

  if (!isEmpty(icon)) {
    const { ID } = icon;
    iconName = ID.substr(1 + ID.indexOf('-'));

    const componentName = upperFirst(camelCase(iconName));

    // eslint-disable-next-line import/namespace
    Icon = Icons[componentName];
  }
  if (!Icon) {
    Icon = Icons.Dash
    iconName = 'dash';
  }

  let Link, linkProps = {
    children: entities.decode(title),
    href: url
  }

  let itemIcon;

  if (inDropdown) {
    Link = <DropdownItem {...linkProps} tag={NavLink} />
  } else {
    Link = <NavLink {...linkProps} />
  }

  if ( 1 == depth ) {
    itemIcon = <div className={ "nav-item__svg-wrap" }>
                  <Icon className={classnames(["nav-item__icon", "nav-item__icon", 'svg', `svg-${iconName}`])} />
               </div>
  } else {
    itemIcon = null
  }

  return <NavItem {...props} className={classnames(className, `nav-item--d${depth}`)} ref={ref}>
    <div className={ "nav-item__sub-wrap" }>
      {itemIcon}
      {Link}
    </div>
    {hasChildren(menuItem) && <SubMenu className="nav-menu" inDropdown menuItem={menuItem} />}
  </NavItem>
})

MenuItem.displayName = 'MenuItem'
MenuItem.propTypes = {
  menuItem: MenuItemPropTypes.isRequired,
  className: PropTypes.string,
  inDropdown: PropTypes.bool
}

/**
 * Dropdown toggle and menu.
 */
const Dropdown = React.forwardRef(({ menuItem, className, onHover, ...props }, ref) => {
  const { title, children, url, depth } = menuItem

  const { ui: { toggle_menu } = {} } = useContext(StringsContext);
  const [isHovered, hoverRef] = useHoverIntent();

  useEffect(() => {
    onHover(isHovered, menuItem)
  }, [isHovered])

  return <BsDropdown className={classnames(className, `nav-item--d${depth}`)} {...props} ref={ref}>
    <div className="nav-item-item" ref={hoverRef}>
      <NavLink href={url} tag={BaseLink}>{entities.decode(title)}</NavLink>
      <DropdownToggle tag="button" nav className="dropdown-toggle">
        <span className="sr-only">{toggle_menu}</span>
        <ArrowDown aria-hidden="true" className="dropdown-icon u-svg-currentcolor" />
      </DropdownToggle>
    </div>
    <DropdownMenu className="nav-menu" tag="ul">
      {children.map(menuItem => {
        const {
          ID
        } = menuItem

        return <MenuItem key={ID} inDropdown menuItem={menuItem} />
      })}
    </DropdownMenu>
  </BsDropdown>
})

Dropdown.displayName = 'Dropdown'
Dropdown.propTypes = {
  menuItem: MenuItemPropTypes.isRequired,
  className: PropTypes.string,
  onHover: PropTypes.func
}

/**
 * Submenu
 */
const SubMenu = React.forwardRef(({ menuItem, inDropdown, ...props }, ref) => {
  const {
    children
  } = menuItem

  return <ul {...props} ref={ref}>
    {children.map((menuItem) => {
      const { ID } = menuItem
      return <MenuItem key={ID} inDropdown={inDropdown} menuItem={menuItem} />
    })}
  </ul>
})

SubMenu.displayName = 'Submenu'
SubMenu.propTypes = {
  menuItem: MenuItemPropTypes.isRequired,
  inDropdown: PropTypes.bool
}

HeaderNav.propTypes = {
  siteName: PropTypes.string,
  menu: PropTypes.array,
  location: PropTypes.object,
  strings: PropTypes.object,
  focusVisible: FocusVisiblePropType.isRequired,
  utilityMenu: PropTypes.object,
};

HeaderNav.defaultProps = {
  siteName: "",
  menu: []
};

export default withStrings(withFocusVisible(withRouter(HeaderNav)));
