import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Container, Col, Row } from "reactstrap";
import PageHeader from "../Common/PageHeader";
import SkipToMain from '../Common/SkipToMain';
import { StringsContext } from "../Contexts/StringsContext";

const PageNotFound = () => {
  const strings = useContext(StringsContext);
  const { 404: { copy, header } = {} } = strings;
  return <>
    <Helmet>
      <title>{header}</title>
      <body className='page single-page page-404' />
    </Helmet>
    <PageHeader title={header} />
    <Container className="page-main">
      <Row className="justify-content-center">
        <Col sm="10">
          <section className="page-content">
            <SkipToMain />
            <p>{copy}</p>
          </section>
        </Col>
      </Row>
    </Container>
  </>
};

export default PageNotFound;
