/**
 * React Context for https://github.com/WICG/focus-visible
 */
import React from 'react';
import PropTypes from 'prop-types';

const inputTypesWhitelist = {
  text: true,
  search: true,
  url: true,
  tel: true,
  email: true,
  password: true,
  number: true,
  date: true,
  month: true,
  week: true,
  time: true,
  datetime: true,
  'datetime-local': true
};

const FocusVisibleContext = React.createContext({
  hadKeyboardEvent: false,
  isReady: false
});
FocusVisibleContext.displayName = 'FocusVisible';
const { Provider, Consumer } = FocusVisibleContext;

export const FocusVisiblePropType = PropTypes.shape({
  isReady: PropTypes.bool.isRequired,
  hadKeyboardEvent: PropTypes.bool.isRequired,
})

// eslint-disable-next-line react/display-name
export const withFocusVisible = Component => props => {
  const Wrapped = (focusVisible) => <Component {...props} focusVisible={focusVisible} />;
  Wrapped.displayName = `withFocusVisible(${Component.displayName})`;
  return <Consumer>{Wrapped}</Consumer>;
};

export class FocusVisibleManager extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      /** Assume mouse modality on load. */
      hadKeyboardEvent: false,
    }

    this.hadFocusVisibleRecently = false;
    this.hadFocusVisibleRecentlyTimeout = null;

    // Bind event listeneres to `this` BEFORE creating event listener arrays.
    ['onBlur', 'onKeyDown', 'onPointerDown', 'onVisibilityChange', 'onInitialPointerMove', 'onFocus', 'onInitialPointerMove']
      .forEach(cbName => {
        this[cbName] = this[cbName].bind(this);
      });

    this.eventListeners = [
      { type: 'keydown', callback: this.onKeyDown, useCapture: true, },
      { type: 'mousedown', callback: this.onPointerDown, useCapture: true, },
      { type: 'pointerdown', callback: this.onPointerDown, useCapture: true, },
      { type: 'touchstart', callback: this.onPointerDown, useCapture: true, },
      { type: 'visibilitychange', callback: this.onVisibilityChange, useCapture: true, },
    ];

    this.initialPointerListeners = [
      { type: 'mousemove', callback: this.onInitialPointerMove, },
      { type: 'mousedown', callback: this.onInitialPointerMove, },
      { type: 'mouseup', callback: this.onInitialPointerMove, },
      { type: 'pointermove', callback: this.onInitialPointerMove, },
      { type: 'pointerdown', callback: this.onInitialPointerMove, },
      { type: 'pointerup', callback: this.onInitialPointerMove, },
      { type: 'touchmove', callback: this.onInitialPointerMove, },
      { type: 'touchstart', callback: this.onInitialPointerMove, },
      { type: 'touchend', callback: this.onInitialPointerMove, },
    ];
  }

  componentDidMount() {
    // Make sure `scope` is a valid DOM node.
    if ( !this.props.scope.current || !this.props.scope.current.nodeName ) {
      return;
    }
    this.scope = this.props.scope.current;

    // For some kinds of state, we are interested in changes at the global scope
    // only. For example, global pointer input, global key presses and global
    // visibility change should affect the state at every scope:
    this.eventListeners.forEach(eventListener => {
      document.addEventListener(eventListener.type, eventListener.callback, eventListener.useCapture);
    });

    this.addInitialPointerMoveListeners();

    // For focus and blur, we specifically care about state changes in the local
    // scope. This is because focus / blur events that originate from within a
    // shadow root are not re-dispatched from the host element if it was already
    // the active element in its own scope:
    this.scope.addEventListener('focus', this.onFocus, true);
    this.scope.addEventListener('blur', this.onBlur, true);


    // We detect that a node is a ShadowRoot by ensuring that it is a
    // DocumentFragment and also has a host property. This check covers native
    // implementation and polyfill implementation transparently. If we only cared
    // about the native implementation, we could just check if the scope was
    // an instance of a ShadowRoot.
    if (this.scope.nodeType === Node.DOCUMENT_FRAGMENT_NODE && this.scope.host) {
      // Since a ShadowRoot is a special kind of DocumentFragment, it does not
      // have a root element to add a class to. So, we add this attribute to the
      // host element instead:
      this.scope.host.setAttribute('data-js-focus-visible', '');
    } else if (this.props.applyClasses && this.scope.nodeType === Node.DOCUMENT_NODE) {
      document.documentElement.classList.add('js-focus-visible');
    }
  }

  componentWillUnmount() {
    this.eventListeners.forEach(eventListener => {
      document.removeEventListener(eventListener.type, eventListener.callback, eventListener.useCapture);
    });
    this.removeInitialPointerMoveListeners();
    window.clearTimeout(this.hadFocusVisibleRecentlyTimeout);
  }

  /**
   * Helper function for legacy browsers and iframes which sometimes focus
   * elements like document, body, and non-interactive SVG.
   * @param {Element} el
   */
  isValidFocusTarget(el) {
    if (
      el &&
      el !== document &&
      el.nodeName !== 'HTML' &&
      el.nodeName !== 'BODY' &&
      'classList' in el &&
      'contains' in el.classList
    ) {
      return true;
    }
    return false;
  }

  /**
   * Computes whether the given element should automatically trigger the
   * `focus-visible` class being added, i.e. whether it should always match
   * `:focus-visible` when focused.
   * @param {Element} el
   * @return {boolean}
   */
  focusTriggersKeyboardModality(el) {
    var type = el.type;
    var tagName = el.tagName;

    if (tagName === 'INPUT' && inputTypesWhitelist[type] && !el.readOnly) {
      return true;
    }

    if (tagName === 'TEXTAREA' && !el.readOnly) {
      return true;
    }

    if (el.isContentEditable) {
      return true;
    }

    return false;
  }

  /**
   * Add the `focus-visible` class to the given element if it was not added by
   * the author.
   * @param {Element} el
   */
  addFocusVisibleClass(el) {
    if (el.classList.contains('focus-visible')) {
      return;
    }
    el.classList.add('focus-visible');
    el.setAttribute('data-focus-visible-added', '');
  }

  /**
   * Remove the `focus-visible` class from the given element if it was not
   * originally added by the author.
   * @param {Element} el
   */
  removeFocusVisibleClass(el) {
    if (!el.hasAttribute('data-focus-visible-added')) {
      return;
    }
    el.classList.remove('focus-visible');
    el.removeAttribute('data-focus-visible-added');
  }

  /**
   * If the most recent user interaction was via the keyboard;
   * and the key press did not include a meta, alt/option, or control key;
   * then the modality is keyboard. Otherwise, the modality is not keyboard.
   * Apply `focus-visible` to any current active element and keep track
   * of our keyboard modality state with `hadKeyboardEvent`.
   * @param {KeyboardEvent} e
   */
  onKeyDown(e) {
    if (e.metaKey || e.altKey || e.ctrlKey) {
      return;
    }

    if (this.isValidFocusTarget(this.scope.activeElement) && this.props.applyClasses) {
      this.addFocusVisibleClass(this.scope.activeElement);
    }
    
    this.setState({
      hadKeyboardEvent: true,
    });
  }

  /**
   * If at any point a user clicks with a pointing device, ensure that we change
   * the modality away from keyboard.
   * This avoids the situation where a user presses a key on an already focused
   * element, and then clicks on a different element, focusing it with a
   * pointing device, while we still think we're in keyboard modality.
   */
  onPointerDown() {
    this.setState({
      hadKeyboardEvent: false,
    });
  }

  /**
   * On `focus`, add the `focus-visible` class to the target if:
   * - the target received focus as a result of keyboard navigation, or
   * - the event target is an element that will likely require interaction
   *   via the keyboard (e.g. a text box)
   * @param {Event} e
   */
  onFocus(e) {
    const {hadKeyboardEvent} = this.state;
    // Prevent IE from focusing the document or HTML element.
    if (!this.isValidFocusTarget(e.target)) {
      return;
    }
    
    if (hadKeyboardEvent || this.focusTriggersKeyboardModality(e.target)) {
      if (this.props.applyClasses) this.addFocusVisibleClass(e.target);
    }
  }

  /**
   * On `blur`, remove the `focus-visible` class from the target.
   * @param {Event} e
   */
  onBlur(e) {
    if (!this.isValidFocusTarget(e.target)) {
      return;
    }

    if (
      e.target.classList.contains('focus-visible') ||
      e.target.hasAttribute('data-focus-visible-added')
    ) {
      // To detect a tab/window switch, we look for a blur event followed
      // rapidly by a visibility change.
      // If we don't see a visibility change within 100ms, it's probably a
      // regular focus change.
      this.hadFocusVisibleRecently = true;
      window.clearTimeout(this.hadFocusVisibleRecentlyTimeout);
      this.hadFocusVisibleRecentlyTimeout = window.setTimeout(function () {
        this.hadFocusVisibleRecently = false;
      }, 100);
      this.removeFocusVisibleClass(e.target);
    }
  }

  /**
   * If the user changes tabs, keep track of whether or not the previously
   * focused element had .focus-visible.
   */
  onVisibilityChange() {
    if (document.visibilityState === 'hidden') {
      // If the tab becomes active again, the browser will handle calling focus
      // on the element (Safari actually calls it twice).
      // If this tab change caused a blur on an element with focus-visible,
      // re-apply the class when the user switches back to the tab.
      if (this.hadFocusVisibleRecently) {
        this.setState({
          hadKeyboardEvent: true,
        })
      }
      this.addInitialPointerMoveListeners();
    }
  }

  /**
   * Add a group of listeners to detect usage of any pointing devices.
   * These listeners will be added when the polyfill first loads, and anytime
   * the window is blurred, so that they are active when the window regains
   * focus.
   */
  addInitialPointerMoveListeners() {
    this.initialPointerListeners.forEach(eventListener => {
      document.addEventListener(eventListener.type, eventListener.callback)
    });
  }

  removeInitialPointerMoveListeners() {
    this.initialPointerListeners.forEach(eventListener => {
      document.removeEventListener(eventListener.type, eventListener.callback)
    });
  }

  /**
   * When the polfyill first loads, assume the user is in keyboard modality.
   * If any event is received from a pointing device (e.g. mouse, pointer,
   * touch), turn off keyboard modality.
   * This accounts for situations where focus enters the page from the URL bar.
   * @param {Event} e
   */
  onInitialPointerMove(e) {
    // Work around a Safari quirk that fires a mousemove on <html> whenever the
    // window blurs, even if you're tabbing out of the page. ¯\_(ツ)_/¯
    if (e.target.nodeName && e.target.nodeName.toLowerCase() === 'html') {
      return;
    }

    this.setState({
      hadKeyboardEvent: false,
    });
    this.removeInitialPointerMoveListeners();
  }

  render() {
    const {hadKeyboardEvent} = this.state;
    return (
      <Provider value={{ hadKeyboardEvent, isReady: true }}>
        {this.props.children}
      </Provider>
    )
  }
}


FocusVisibleManager.defaultProps = {
  applyClasses: true,
  scope: { current: document }
}

FocusVisibleManager.propTypes = {
  applyClasses: PropTypes.bool,
  scope: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.instanceOf(Document),
    ]) })
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
}