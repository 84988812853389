import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import ErrorMessage from "../Common/ErrorMessage";
import FlexibleContent from "../ACF/FlexibleContent";
import SkipToMain from '../Common/SkipToMain';
import PageFooter from "../Common/PageFooter";
import { PagePropTypes } from "../../utils/misc";

class PageDefault extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      acf: {
        content,
        calls_to_action,
        related_posts_posts,
        related_posts_heading,
        related_posts_link,
      },
      error,
      location
    } = this.props;

    const homepage = location.pathname === "/";

    return <>
      <div className="page-main">
        <section className='page-content'>
          <SkipToMain />
          <Container className="layouts-container" fluid={homepage}>
            <ErrorMessage error={error} />
            <FlexibleContent content={content} />
          </Container>
        </section>
      </div>
      <PageFooter related_posts={{ posts: related_posts_posts, link: related_posts_link, heading: related_posts_heading }} calls_to_action={calls_to_action} />
    </>
  }
}

PageDefault.propTypes = {
  ...PagePropTypes,
  error: PropTypes.object,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
};

PageDefault.defaultProps = {
  title: {
    rendered: ""
  },
  section: '',
  acf: {
    content: [],
    calls_to_action: [],
    banner_image: {}
  },
  isLoading: false,
};

export default PageDefault;
