import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Fade from "react-reveal/Fade";
import { Container } from 'reactstrap';
import Img from './Img';
import { maybeClassName } from '../../utils/inputs';

const BackgroundWrapper = ({
  background,
  container: { className: containerCl, ...container },
  className,
  children,
  srcSet,
  size,
  tag,
  useContainer
}) => {
  const WrapperElement = tag;
  const hasBackground = Boolean(background && background.url);
  return (
    <WrapperElement
      className={classnames(
        'bg-wrapper',
        `bg-wrapper--${size ? size : 'original'}`,
        { 'bg-wrapper--no-image': !hasBackground },
        className
      )}
    >
      {hasBackground && <div className="bg-wrapper-image">
        <Fade duration={1750} fraction={.3} spy={hasBackground} appear={true}>
          <Img {...background} size={size} srcSet={srcSet} />
        </Fade>
      </div>}
      <Container {...container} className={`bg-wrapper-container${maybeClassName(containerCl)}`} fluid={!useContainer}>{children}</Container>
    </WrapperElement>
  );
};

BackgroundWrapper.propTypes = {
  background: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  className: PropTypes.string,
  children: PropTypes.node,
  /**
   * Default image size, used as the `<img>` tag's `src`.
   * Displays if `<picture>` tag isn't supported, or none of the `<source>`'s media queries apply.
   */
  size: PropTypes.string,
  /**
   * Determines `<source>` tags in `<picture>` element.
   * Key is an image size slug, value is the media query at which to display the image size.
   */
  srcSet: PropTypes.object,
  tag: PropTypes.string,
  /** Whether to use fixed instead of fluid container. */
  useContainer: PropTypes.bool,
  /** Props for <Container/> */
  container: PropTypes.object,
};

BackgroundWrapper.defaultProps = {
  background: { url: '' },
  className: 'wrapper',
  tag: 'div',
  useContainer: false,
  srcSet: {},
  container: {},
};

export default BackgroundWrapper;
