import React from 'react';
import PropTypes from 'prop-types';
import { fillTaxonomies, fetchFrom, PROMISE_STATUS } from '../../api';
import { Container, Row, Col } from "reactstrap";
import PageHeader from "../Common/PageHeader";
import ErrorMessage from "../Common/ErrorMessage";
import Loading from "../Common/Loading";
import SkipToMain from '../Common/SkipToMain';
import { Err, PagePropTypes } from '../../utils/misc';
import Moment from 'react-moment';
import { withStrings } from '../Contexts/StringsContext';
import { withSettings } from '../Contexts/SettingsContext';
import SocialShare from '../Common/SocialShare';
import { withTaxonomies, TaxonomiesProptype } from '../Contexts/TaxonomiesContext';
import { merge as _merge, truncate } from 'lodash';
import { AllHtmlEntities as Entities } from 'html-entities';
import { buildFwpParams, mapTaxonomyFacets } from '../../api/facetwp';
import { Link } from 'react-router-dom'
import { stringifyUrl } from 'query-string';
import FlexibleContent from '../ACF/FlexibleContent';
import PageFooter from '../Common/PageFooter';

const decode = (new Entities()).decode;
const SOCIAL_CHAR_LIMIT = 300;

class SinglePost extends React.Component {
  constructor(props) {
    super(props);
    const { settings: { posts_page: { link: posts_page } } } = props;
    this.taxonomies = ['media-type', 'categories', 'therapeutic-area'];

    try {
      this.posts_page = (new URL(posts_page)).pathname;
    } catch (error) {
      this.posts_page = undefined;
    }

    const { settings: { fwp: { facets } = {} } } = props;
    this.taxFacetMap = mapTaxonomyFacets(facets);

    this.state = {
      errors: [],
    };

  }

  fetchData = () => {
    const { id } = this.props;

    // Reset state
    this.setState({
      status_related: PROMISE_STATUS.PENDING,
      related_posts: undefined,
    })

    // Fetch related posts.
    const related_posts = fetchFrom('ptc', stringifyUrl({ url: `/posts/${id}`, query: { include: 'related_posts' } }))
      .then(({ data: { related_posts } }) => {
        this.setState({
          related_posts,
          status_related: PROMISE_STATUS.FULFILLED,
        })
      })
      .catch(({ error }) => {
        this.setState({
          errors: [error, ...this.state.errors]
        })
      })

    return Promise.all([related_posts])
  }

  componentDidMount() {
    const { taxonomies: [pTaxonomies = {}, setTaxonomies] = [] } = this.props;
    // Fetch missing taxonomies.
    this.setState({ status_taxes: PROMISE_STATUS.PENDING, })
    fillTaxonomies({
      'media-type': true,
      'category': true,
      'therapeutic-area': true,
    }, pTaxonomies)
      .then(taxonomies => {
        setTaxonomies(_merge(pTaxonomies, taxonomies))
        this.setState({
          status_taxes: PROMISE_STATUS.FULFILLED
        })
      })
  }

  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;

    // Single post newly loaded
    if (!isLoading && isLoading !== prevProps.isLoading) {
      this.fetchData()
    }
  }

  /**
   * Get content for the social share.
   *
   * @returns {Object} Share content.
   */
  socialShare() {
    const {
      seo: {
        title: seoTitle,
        description: seoContent
      } = {},
      title: { rendered: postTitle },
      content: { rendered: postContent },
      link
    } = this.props;

    let share = {
      title: decode(seoTitle ? seoTitle : postTitle),
      url: link
    };

    if (seoContent) {
      share.content = decode(seoContent);
    } else {
      // Extract text from post content.
      let contentNode = document.createElement('div');
      contentNode.innerHTML = postContent;

      share.content = truncate(contentNode.innerText, {
        separator: ' ',
        length: SOCIAL_CHAR_LIMIT
      });
    }

    return share;
  }

  render() {
    const { errors, related_posts, status_related, status_taxes } = this.state;
    const {
      location,
      taxonomies: [taxonomies],
      strings: { post: { byline_label, byline_heading, related_heading } },
      settings: { fwp: { prefix } },
      date,
      isLoading: dataLoading,
      title = '',
      section = '',
      link,
      acf: {
        banner_image,
        byline,
        attachment_file,
        attachment_cta,
        attachment_heading,
        attachment_caption,
        content
      } = {}
    } = this.props;

    const { avatar } = byline || {};
    const deps = [status_related, status_taxes];
    const isLoading = dataLoading || deps.some(status => status === undefined || status === PROMISE_STATUS.PENDING);
    const hasError = errors.length > 0;

    return <>
      <PageHeader
        title={title.rendered}
        location={location}
        section={section}
        image={banner_image}
        useContainer
      />
      <Container className='page-main'>
        <Row className="justify-content-center">
          <Col sm="10">
            {isLoading ?
              <Loading /> :
              hasError ?
                <section className="page-content">
                  <ErrorMessage {...Object.values(errors).flat()[0]} />
                </section> :
                <Row className="justify-content-between">
                  <Col xs="12" lg="8" className="pr-lg-4">
                    <section className='page-content'>
                      <SkipToMain />

                      <p className="single-post__byline">
                        <Moment format="LL">{date}</Moment>
                        {byline && <> | <span>{byline_label} {byline.info.name}</span></>}
                      </p>

                      <div className="layouts-container">
                        <FlexibleContent content={content} />
                      </div>

                      {byline && <>
                        <hr className="separator" />
                        <div className="single-post__byline byline">
                          <p className="byline__heading">{byline_heading}</p>
                          {avatar && <img src={avatar.sizes.thumbnail} alt={avatar.alt || undefined} className="byline__avatar avatar" />}
                          <p className="byline__name">{byline.info.name}</p>
                          {byline.info.caption && <p className="byline__caption">{byline.info.caption}</p>}
                        </div>
                      </>}
                    </section>
                  </Col>

                  <Col className="pl-lg-4">
                    <section className="page-sidebar">
                      <SocialShare url={link} {...this.socialShare()} />

                      {(attachment_file && attachment_cta) &&
                        <div className="attachment">
                          {attachment_heading && <p className="attachment__heading">{attachment_heading}</p>}
                          <a target="_blank" rel="noopener noreferrer" href={attachment_file.url} className="btn btn-gradient attachment__cta">
                            <i className="icon icon-download"></i><span>{attachment_cta}</span>
                          </a>
                          {attachment_caption && <p className="attachment__caption">{attachment_caption}</p>}
                        </div>
                      }

                      {['therapeutic-area', 'category'].filter(taxSlug => this.props[taxonomies[taxSlug]?.rest_base]).length > 0 &&
                        <div className="taxonomies">
                          {['therapeutic-area', 'category'].map(taxSlug => {
                            // Get taxonomy or bail.
                            const taxonomy = taxonomies[taxSlug];
                            if (!taxonomy) return null

                            const { mg_names: { plural } = {}, rest_base } = taxonomy
                            const termIds = this.props[rest_base];

                            const facetSlug = this.taxFacetMap[taxSlug];

                            return Boolean(termIds.length > 0 && facetSlug && this.posts_page) &&
                              <nav role="navigation" aria-labelledby={`sidebar-taxonomy-${taxSlug}-title`} key={taxSlug} className={`taxonomy taxonomy--${taxSlug}`}>
                                {plural && <p id={`sidebar-taxonomy-${taxSlug}-title`} className="taxonomy__title">{decode(plural)}</p>}

                                <ul className="taxonomy__terms">
                                  {termIds.map(termId => {
                                    // Get term object or bail.
                                    const term = taxonomies[taxSlug].terms.find(({ id }) => id === termId);
                                    if (!term) return null

                                    const { name, slug } = term

                                    return <li key={termId}>
                                      <Link to={`${this.posts_page}${buildFwpParams({ [facetSlug]: [slug] }, prefix)}`}
                                        className="taxonomy__term taxonomy__link"
                                      >
                                        {decode(name)}
                                      </Link>
                                    </li>
                                  })}
                                </ul>
                              </nav>
                          })}
                        </div>}
                    </section>
                  </Col>
                </Row>
            }
          </Col>
        </Row>
      </Container>
      <PageFooter {...!isLoading && !hasError && {
        related_posts: { posts: related_posts, heading: related_heading }
      }} />
    </>
  }
}

SinglePost.propTypes = {
  ...PagePropTypes,
  location: PropTypes.object,
  taxonomies: TaxonomiesProptype,
  strings: PropTypes.object.isRequired,
  settings: PropTypes.shape({
    fwp: PropTypes.shape({
      prefix: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Err)
      ]),
      facets: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
          source: PropTypes.string.isRequired,
        })),
        PropTypes.instanceOf(Err)
      ])
    }).isRequired,
    posts_page: PropTypes.shape({
      link: PropTypes.string.isRequired
    }).isRequired,
  }).isRequired,
};

export default withSettings(withTaxonomies(withStrings(SinglePost)));
