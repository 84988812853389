/**
 * PostsPage
 *
 * @package frontend-ui
 */
import React from 'react';
import Sticky from 'react-stickynode'
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import { fetchPosts, fillTaxonomies, PROMISE_STATUS } from '../../api';
import { Container, Row, Col, Collapse } from 'reactstrap';
import ErrorMessage from '../Common/ErrorMessage';
import Pagination from '../Common/Pagination';
import LoopPost from '../Common/LoopPost';
import { withSettings } from '../Contexts/SettingsContext';
import {
  isEqual as _isEqual,
  differenceBy as _differenceBy,
  some as _some,
  merge as _merge,
  values as _values,
  fromPairs as _fromPairs,
} from 'lodash';
import { FwpFaceter, reducer } from '../FacetWp/Faceter';
import ReactHtmlParser from "react-html-parser";
import { withStrings } from '../Contexts/StringsContext';
import FeaturedPosts from '../Common/FeaturedPosts';
import { BEM, breakpoints, Err, headerHeight, PagePropTypes } from '../../utils/misc';
import SkipToMain from '../Common/SkipToMain';
import { withTaxonomies, TaxonomiesProptype } from '../Contexts/TaxonomiesContext';
import PageHeader from '../Common/PageHeader';
import { extractFwpParams, mapTaxonomyFacets } from '../../api/facetwp';
import { omit } from 'reactstrap/lib/utils';
import classNames from 'classnames';
import RichText from '../ACF/Layouts/RichText';

const filter = BEM('filter');
const { FULFILLED, REJECTED, PENDING } = PROMISE_STATUS;
const stickAt = 'md';
const wrapAt = 'lg'
const queryLg = window.matchMedia(`(min-width: ${breakpoints[stickAt]}px)`)

/**
 * React Component PostsPage.
 */
class PostsPage extends React.Component {
  constructor(props) {
    super(props);

    ['handleFwpChange', 'handleFwpError', 'scrollToPosts', 'updateBreakpointLarge'].forEach(binding => {
      this[binding] = this[binding].bind(this);
    })

    const { settings: { fwp: { facets, prefix } = {} }, error } = props;

    const taxFacetMap = mapTaxonomyFacets(facets);

    // Extract facets from URL
    const { paged = 1, ...fields } = extractFwpParams(
      [...Object.values(taxFacetMap), 'paged'],
      window.location.search,
      prefix
    ) ?? {};

    // Initialize facets with defaults and URL parameters
    const initialFields = reducer({}, {
      type: 'set',
      payload: {
        // Defaults
        ...Object.values(taxFacetMap).reduce((obj, facet) => {
          obj[facet] = null
          return obj
        }, {}),
        // From URL
        ...fields
      }
    });

    /**
     * Map of taxonomy slug to corresponding facet slug
     * @property {Object}
     */
    this.taxFacetMap = taxFacetMap

    this.deps = ['mediaTypes', 'posts', 'featuredPosts'];

    const sticky = queryLg.matches

    this.state = {
      promises: _fromPairs(this.deps.map(prop => [prop, PENDING])),
      errors: {
        ..._fromPairs(this.deps.map(prop => [prop, []])),
        data: error,
      },
      posts: {},
      totalPosts: 0,
      filteredIds: undefined,
      mediaTypes: undefined,
      fields: initialFields,
      appliedFields: initialFields,
      page: paged ?? 1,
      // Whether we need to scroll posts into view
      queueScroll: false,
      freezeSticky: !sticky,
      enableSticky: sticky,
      sidebarOpen: false,
      isStuck: undefined,
      featuredPosts: undefined,
      isBreakpointLarge: queryLg.matches
    };

    this.postsRef = React.createRef();
    this.stickyRef = React.createRef();
    this.focusRef = React.createRef();

    this.timeouts = {};
  }

  /*-------------------------------------------------------------------
   * Lifecycle
   *-----------------------------------------------------------------*/
  componentDidMount() {
    const { taxonomies: [cTaxonomies, setTaxonomies] } = this.props;

    queryLg.addEventListener('change', this.updateBreakpointLarge)

    // Get media types
    fillTaxonomies({ 'media-type': true }, cTaxonomies)
      .then(taxonomies => {
        setTaxonomies(_merge(taxonomies, cTaxonomies));
        return taxonomies;
      })
      .catch(({ error }) => {
        this.setState({
          errors: { ...this.state.errors, mediaTypes: [error, ...this.state.errors.mediaTypes] },
          promises: { ...this.state.promises, mediaTypes: REJECTED }
        })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    const { promises = {}, queueScroll, isBreakpointLarge } = this.state;
    const { mediaTypes: pMediaTypes } = promises;
    const { isLoading, taxonomies: [{ 'media-type': { terms: mediaTypes } = {} }] } = this.props;

    // Got updated taxonomies from context
    if (pMediaTypes !== FULFILLED && mediaTypes && Array.isArray(mediaTypes)) {
      this.setState({
        promises: { ...this.state.promises, mediaTypes: FULFILLED }
      })
    }

    // Breakpoint changed
    if (prevState.isBreakpointLarge !== isBreakpointLarge) {
      if (!isBreakpointLarge) {
        // Went down, unsticking. Allow react-stickynode to update with disabled styles before deactivating updates.
        this.setState({ enableSticky: false })
        this.timeouts.sticky = setTimeout(() => {
          this.setState({ freezeSticky: true })
        }, 10);
      } else {
        // Went up, sticking. Activate react-stickynode updates before before getting enabled styles.
        this.setState({ freezeSticky: false })
        this.timeouts.sticky = setTimeout(() => {
          this.setState({ enableSticky: true })
        }, 10);
      }
    }

    // Posts loaded
    if (FULFILLED === promises.posts && promises.posts !== prevState.promises.posts) {
      if (queueScroll) {
        this.setState({ queueScroll: false })
        /* We only want to scroll when all <LoopPost>s have rendered.
         * Instead of lifting render state from each <LoopPost>, fudge this behavior by waiting a bit. */
        this.timeouts.scroll = setTimeout(() => {
          this.scrollToPosts();
          this.focusRef.current.focus({ preventScroll: true });
        }, 100);
      }
    }

    if (!isLoading && prevProps.isLoading) {
      // Get featured posts
      const featured_posts = this.props.acf.featured_posts
      if (featured_posts?.length) {
        fetchPosts('posts', { include: featured_posts })
          .then(({ data: posts }) => {
            this.setState({
              featuredPosts: posts,
              promises: {
                ...this.state.promises,
                featuredPosts: FULFILLED
              }
            })
          })
          .catch(({ error }) => {
            this.setState({
              promises: {
                ...this.state.promises,
                featuredPosts: REJECTED
              },
              errors: {
                ...this.state.errors,
                featuredPosts: error
              }
            })
          })
      } else {
        fetchPosts('posts')
          .then(() => {
            this.setState({
              featuredPosts: {},
              promises: {
                ...this.state.promises,
                featuredPosts: FULFILLED
              }
            })
          })
          .catch(({ error }) => {
            this.setState({
              promises: {
                ...this.state.promises,
                featuredPosts: REJECTED
              },
              errors: {
                ...this.state.errors,
                featuredPosts: error
              }
            })
          })
      }
    }
  }

  componentWillUnmount() {
    _values(this.timeouts).forEach(timeout => { clearTimeout(timeout) });
    queryLg.removeEventListener('change', this.updateBreakpointLarge)
  }

  updateBreakpointLarge(query) {
    this.setState({ isBreakpointLarge: query.matches })
  }

  scrollToPosts() {
    if (this.postsRef.current) {
      let scrollTo = window.pageYOffset + this.postsRef.current.getBoundingClientRect().top - headerHeight;
      if (this.state.isStuck && this.stickyRef?.current?.outerElement) {
        scrollTo -= this.stickyRef.current.outerElement.clientHeight
      }
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  }

  /*-------------------------------------------------------------------
   * Handlers
   *-----------------------------------------------------------------*/
  handleFwpError(error) {
    this.setState({
      promises: { ...this.state.promises, posts: REJECTED },
      errors: { ...this.state.errors, posts: [error, ...this.state.errors.posts] },
    })
  }

  handleFwpChange({ results: newFilteredIds, pager: { total_rows: totalPosts, page } }) {
    const { filteredIds, posts } = this.state;

    // New filter results for this page.
    if (!_isEqual(newFilteredIds, filteredIds)) {
      // Fetch new posts
      this.setState({ promises: { ...this.state.promises, posts: PENDING } })
      this.fetchNewPosts(newFilteredIds, Object.keys(posts))
        .then(newPosts => {
          this.setState({
            filteredIds: newFilteredIds,
            posts: { ...this.state.posts, ...newPosts },
            totalPosts,
            promises: {
              ...this.state.promises,
              posts: FULFILLED
            },
            page,
          })
        })
    } else {
      // No new posts for *this* page, but maybe for other pages.
      this.setState({
        totalPosts,
        promises: {
          ...this.state.promises,
          posts: FULFILLED
        },
        page,
      });
    }
  }

  /*-------------------------------------------------------------------
   * Helpers
   *-----------------------------------------------------------------*/
  /**
   * Fetch any posts that haven't already been fetched.
   * @param {Array} ids IDs of required posts.
   * @param {Array} prevIds IDS of possessed posts.
   * @returns {Promise} Object of newly fetched post objects keyed by post ID.
   */
  fetchNewPosts(ids, prevIds) {
    const newIds = _differenceBy(ids, prevIds, parseInt);

    if (newIds.length) {
      // Need to fetch new posts
      return fetchPosts('posts', { include: newIds })
        .then(({ data: newPosts }) => {
          // Convert Object[] newPosts to Object where posts are keyed by id.
          return newPosts.reduce((posts, post) => { posts[post.id] = post; return posts }, {});
        });
    } else {
      // No new posts
      return Promise.resolve({});
    }
  }

  render() {
    const { errors, promises, posts, filteredIds, featuredPosts } = this.state;
    const {
      strings: {
        filters: {
          clear_all,
          no_results,
          apply,
          filters
        } = {},
      } = {},
      taxonomies: [{ 'media-type': { terms: mediaTypes } = {} }],
      location,
      title,
      acf: {
        banner_image,
        content,
        next_slide,
        previous_slide,
        slide_title,
        carousel_title,
        badge_label,
        sidebar: {
          title: sidebar_title
        } = {},
        categories: {
          label: categories_label
        } = {}
      } = {},
      isLoading,
    } = this.props;

    const {
      category: categoryFacet
    } = this.taxFacetMap;

    return (
      <>
        <PageHeader
          title={title && title.rendered}
          location={location}
          image={banner_image}
          useContainer
        />
        <Container className="page-main">
          <SkipToMain />
          <Row className="justify-content-center">
            <Col tag="section" className="py-4 page-content" sm="10">
              <FwpFaceter
                facets={this.state.appliedFields}
                page={this.state.page}
                onChange={this.handleFwpChange}
                onError={this.handleFwpError}
              >
                {({ facets, pager }) => {
                  // Bail if loading
                  if (
                    isLoading ||
                    PENDING === promises.mediaTypes ||
                    PENDING === promises.featuredPosts ||
                    (undefined === filteredIds)
                  ) return <Loading />;

                  // Bail if error
                  if (_some(promises, status => status === REJECTED)) return <ErrorMessage {...Object.values(errors).flat()[0]} />

                  const {
                    [categoryFacet]: category
                  } = facets;

                  return <>
                    {featuredPosts && featuredPosts.length &&
                      <FeaturedPosts
                        mediaTypes={mediaTypes}
                        posts={featuredPosts}
                        strings={{ next_slide, previous_slide, slide_title, carousel_title, badge_label }}
                      />
                    }
                    {content && <RichText className="mb-2" wysiwyg={content} />}
                    {this.taxFacetMap.category && category.choices?.length &&
                      <Sticky ref={this.stickyRef} innerClass="sticky-bar sticky-bar--col10" innerActiveClass="is-stuck" className='mb-2' onStateChange={({ status }) => this.setState({ isStuck: status })} innerZ={1} shouldFreeze={() => this.state.freezeSticky} enabled={this.state.enableSticky} top={headerHeight}>
                        <div className='sticky-bar__container py-2 d-flex'>
                          {/* Categories */}
                          <span className={`text-title d-none d-${stickAt}-block mr-3`}>{categories_label}</span>
                          <fieldset role="radiogroup" aria-labelledby={`filters-${category.name}-title`}>
                            <legend className="sr-only" id={`filters-${category.name}-title`}>{category.label}</legend>
                            <div className="pills">
                              <div className={`pills__flex justify-content-center justify-content-${stickAt}-start`}>
                                {category.choices.map(({ value, label }) =>
                                  <label
                                    key={value}
                                    className={classNames('pills__pill', { active: (category.selected?.[0] || null) === value })}
                                  >
                                    <input
                                      type="radio"
                                      name={category.label}
                                      value={value || ""}
                                      className='pills__radio sr-only'
                                      onChange={() => {
                                        const fields = reducer(this.state.fields, {
                                          type: 'set',
                                          payload: { [categoryFacet]: value }
                                        });
                                        this.setState({
                                          fields,
                                          appliedFields: fields,
                                          page: 1
                                        })
                                      }}
                                    />
                                    <span className='pills__text'>{ReactHtmlParser(label)}</span>
                                  </label>
                                )}
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </Sticky>}
                    <div className="row gx-2">
                      <div className={`col-${wrapAt}-3 mb-2 mb-${wrapAt}-0`}>
                        {/* Sidebar */}
                        <div className="sidebar sidebar--collapse">
                          <button className="sidebar__toggle" onClick={() => this.setState({ sidebarOpen: !this.state.sidebarOpen })} aria-expanded={this.state.sidebarOpen} aria-controls="sidebar-filters">{filters}</button>
                          <Collapse id="sidebar-filters" className="sidebar__collapse" isOpen={this.state.sidebarOpen}>
                            <div className="sidebar__body">
                              {/* Filters */}
                              <div className="sidebar__title d-none d-lg-block mb-2">{sidebar_title}</div>
                              <div className="filters">
                                {['media-type', 'therapeutic-area'].map((taxSlug) => {
                                  const facetSlug = this.taxFacetMap[taxSlug];
                                  if (!facetSlug) return;
                                  const labelId = `filter-${facetSlug}-title`;
                                  const {
                                    choices = [], label
                                  } = facets[facetSlug];
                                  const selected = this.state.fields[facetSlug]
                                  return <fieldset key={taxSlug} role="group" aria-labelledby={labelId} className={`filters__filter ${filter([null, 'checkboxes', taxSlug])}`}>
                                    <legend className="filter__title" id={labelId}><span className="sr-only">Filter By </span>{label}</legend>
                                    <div className="checkboxes">
                                      {choices.map(({ label, value, count }) => {
                                        const checked = selected && selected.has(value);
                                        /*
                                         * Only disable options...
                                         * * which will yield no results
                                         * * when there's any results
                                         */
                                        const disabled = 0 === count && filteredIds.length;
                                        return <label
                                          key={value}
                                          className={classNames("checkbox__wrapper", { 'js-filled': checked, 'js-disabled': disabled })}
                                        >
                                          <div className="checkbox__element">
                                            <input
                                              className="sr-only"
                                              type="checkbox"
                                              checked={checked}
                                              disabled={disabled}
                                              onChange={() => this.setState({
                                                fields: reducer(this.state.fields, {
                                                  type: checked ? 'delete' : 'insert',
                                                  payload: { [facetSlug]: value },
                                                })
                                              })}
                                            />
                                            <div className="checkbox__decorator icon-"></div>
                                          </div>
                                          <span className="checkbox__label">{label}</span>
                                        </label>
                                      })}
                                    </div>
                                  </fieldset>
                                })}
                              </div>
                              <div className="mt-2">
                                <div className="d-flex flex-wrap m-n1">
                                  {/* Apply filters */}
                                  <button
                                    className="m-1 btn btn-dark"
                                    onClick={() => this.setState({
                                      appliedFields: this.state.fields,
                                      page: 1
                                    })}
                                  >
                                    {apply}
                                  </button>
                                  {/* Clear filters */}
                                  <button
                                    className="m-1 btn btn-link-alt"
                                    onClick={() => {
                                      const fields = reducer(this.state.fields, {
                                        type: 'reset',
                                        payload: Object.values(omit(this.taxFacetMap, 'category'))
                                      });
                                      this.setState({
                                        fields,
                                        appliedFields: fields,
                                        page: 1
                                      })
                                    }}
                                  >
                                    {clear_all}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </div>

                      </div>
                      <div ref={this.postsRef} className={`col-${wrapAt}-9`}>
                        {/* Posts */}
                        {filteredIds.length < 1 ?
                          no_results && <p className="my-5 text-center">{no_results}</p>
                          :
                          <ul className="posts">
                            {filteredIds.map((id, i) =>
                              <li key={id}><LoopPost
                                mediaTypes={mediaTypes}
                                className="post--inner"
                                data={posts[id]}
                                ref={i === 0 ? this.focusRef : null}
                              /></li>)}
                          </ul>
                        }
                      </div>
                    </div>

                    {/* Pager */}
                    <Pagination
                      className="pagination--small mt-4"
                      current={pager.page}
                      totalRecords={parseInt(pager.total_rows)}
                      midSize={3}
                      onPageChanged={({ current }) => this.setState({ page: current, queueScroll: true })}
                    />
                  </>
                }}
              </FwpFaceter>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

PostsPage.propTypes = {
  ...PagePropTypes,
  /** URL current location from Router. */
  location: PropTypes.object.isRequired,
  /** URL history from Router. */
  history: PropTypes.object.isRequired,
  /** Global strings from StringsContext */
  strings: PropTypes.object.isRequired,
  /** Taxonomies from TaxonomiesContext */
  taxonomies: TaxonomiesProptype,
  /** Settings from SettingsContext */
  settings: PropTypes.shape({
    fwp: PropTypes.shape({
      facets: PropTypes.oneOfType([
        PropTypes.instanceOf(Err),
        PropTypes.arrayOf(PropTypes.object)
      ])
    })
  }),
  error: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default withTaxonomies(withStrings(withSettings(PostsPage)));
