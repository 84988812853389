import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Modal, ModalBody, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import Wysiwyg from './components/Common/ParsedWysiwyg';
import FooterContainer from './components/Common/FooterContent';
import HeaderNav from './components/Navigation/HeaderNav';
import UtilityNav from './components/Navigation/UtilityNav';
import AlertBanner from "./components/Navigation/AlertBanner";
import Page from './components/Common/Page';

import { StringsProvider } from "./components/Contexts/StringsContext";
import { SettingsProvider } from "./components/Contexts/SettingsContext";
import { TaxonomiesProvider } from "./components/Contexts/TaxonomiesContext";
import { FocusVisibleManager } from './components/Contexts/FocusVisibleManager';

import { pick as _pick, reduce } from 'lodash';
import { isExternal, Err, headerHeight } from './utils/misc';
import { fetchSettings, fetchStrings, fetchMenu, fetchPtcSettings, fetchCountries, fetchFrom, fetchTerms } from './api';
import { fetchFwpSettings } from './api/facetwp';
import getPath from './api/relativePath';
import { createBrowserHistory } from 'history';

import './style/App.css';

const history = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);

    const bindings = ['closeModal', 'getTrustedSites', 'handleExternal', 'smoothScroll', 'continueExternal'];
    bindings.forEach(methodName => {
      this[methodName] = this[methodName].bind(this);
    });

    this.state = {
      polyfilled: true,
      externalModalOpen: false,
      externalLink: '',
      externalLinkTarget: '_self',
      isLoading: true,
      settings: {
        name: 'PTC Therapeutics',
        language: process.env.DEFAULT_LANGCODE,
        fwp: {}
      },
      taxonomies: {},
      strings: {
        general: {
          gtm_id: '',
          exit_modal_enabled: false,
          exit_modal_copy: ''
        }
      },
      menus: null,
    };
    this.menuLocations = {
      'header': [],
      'utility': [],
      'footer-primary': [],
      'footer-primary-1': [],
      'footer-primary-2': [],
      'footer-tertiary': [],
    };
  }

  smoothScroll = evt => {
    if (evt.target && ['A', 'AREA'].includes(evt.target.nodeName)) {
      if (evt.target.matches("[href^='#']:not([href='#']):not([data-scroll-prevent])")) {
        evt.preventDefault();
        evt.stopPropagation();

        const hash = evt.target.getAttribute('href');
        const target = document.querySelector(hash);

        if (location.hash !== hash) history.push(`${location.pathname}${hash}`);

        const offsetTop = target.getBoundingClientRect().top + window.pageYOffset;
        const navbar_height_scrolling = headerHeight;

        const offset = evt.target.dataset.scrollOffsetTop ?? (navbar_height_scrolling + 10)

        target.focus({ preventScroll: true });
        window.scroll({ top: offsetTop - offset, behavior: "smooth" });
      }
    }
  }

  // Build regex for trusted hostnames, if applicable.
  getTrustedSites = trustedSites => (trustedSites && trustedSites.length) ?
    trustedSites.reduce((arr, { trusted_site }) => {
      let url;
      try { url = new URL(`http://${trusted_site}`) }
      catch { return arr }

      // If hostname only includes domain and top-level domain provided, hostname can optionally include leading `www.`
      // Otherwise, provided hostname must match exactly.
      arr.push(...(url.hostname.split('.').length > 2 ? [trusted_site] : [trusted_site, `www.${trusted_site}`]))
      return arr;
    }, []) :
    [];

  async componentDidMount() {
    document.addEventListener('click', this.smoothScroll);
    window.addEventListener('OneTrustGroupsUpdated', this.onOneTrustGroupsUpdated);

    // Get site settings
    const siteSettings = fetchSettings()
      .then(settings => this.setState({
        settings: {
          ...this.state.settings,
          ..._pick(settings, ['name', 'description', 'url', 'home', 'gmt_offset', 'timezone_string'])
        }
      }));

    // Get site menus
    const menus = Promise.all(
      Object.keys(this.menuLocations).map(location => {
        return fetchMenu(location).then(({ data }) => {
          const items = this.getMenuItemRoutes(data);
          return { location, items };
        });
      })
    ).then(data => {
      let menus = {};
      data.map(menu => {
        menus[menu.location] = menu.items;
      });
      this.setState({ menus });
    });

    const ptcSettings = fetchPtcSettings(['posts_page', 'posts_per_page', 'permalink_structure', 'event_slug', 'event_breadcrumb', 'events_per_page'])
      .then(settings => {

        // Defaults, round 1.
        let newSettings = {
          posts_per_page: 12,
          event_slug: 'event',
          // Filter out falsy values.
          ...reduce(settings, (obj, val, key) => {
            if (val) {
              obj[key] = val
            }
            return obj
          }, {})
        }

        // Defaults, round 2.
        newSettings = {
          events_per_page: newSettings.posts_per_page,
          ...newSettings
        }

        this.setState({ settings: { ...this.state.settings, ...newSettings } })
      });

    const fwpSettings = fetchFwpSettings(["prefix", "facets"])
      .then(rs => {
        const { data } = rs;
        this.setState({
          settings: { ...this.state.settings, fwp: { ...this.state.settings.fwp, ...data } }
        })
        return rs;
      })
      .catch(({ error }) => {
        const err = new Err({ name: 'PluginError', ...error });
        this.setState({
          settings: { ...this.state.settings, fwp: { ...this.state.settings.fwp, prefix: err, facets: err } }
        })
        return err;
      })

    const options = fetchFrom('ptc', '/options?_groups=page_types')
      .then(({ data: page_types }) => {
        this.setState({ settings: { ...this.state.settings, page_types } })
      })

    const strings = fetchStrings().then(({ data: strings }) => {
      if (strings.general.exit_modal_enabled) {
        document.addEventListener("click", this.handleExternal);
        this.trustedSites = this.getTrustedSites(strings.general.exit_modal_trusted_sites);
      }
      this.setState({
        strings
      });
    });

    const countries = fetchCountries().then(({ data: countries }) => {
      this.setState({
        countries
      });
    });

    const mediaTypes = fetchTerms('media-type')
      .then(({ data: terms }) => {
        this.setState({ taxonomies: { ...this.state.taxonomies, 'media-type': { ...this.state.taxonomies?.['media-type'], terms } } })
      })

    // Change loading state when all dependencies are fulfilled.
    Promise.all([menus, siteSettings, ptcSettings, strings, countries, fwpSettings, options, mediaTypes])
      .then(() => {
        this.setState({
          isLoading: false
        })
      })
  }

  /**
   * Push cookie groups active on page load to Google Tag Manager
   */
  onOneTrustGroupsUpdated() {
    window.removeEventListener('OneTrustGroupsUpdated', this.onOneTrustGroupsUpdated);
    window.dataLayer.push({
      "OnetrustActiveGroupsInitial": window.OnetrustActiveGroups
    })
  }

  componentWillUnmount() {
    // Remove event listeners.
    document.removeEventListener('click', this.smoothScroll);
    if (this.state.strings.general.exit_modal_enabled) {
      document.removeEventListener("click", this.handleExternal);
    }
    window.removeEventListener('OneTrustGroupsUpdated', this.onOneTrustGroupsUpdated);
  }

  handleExternal = (event) => {
    const anchor = event.target.closest('a');
    if (null !== anchor) {
      const eventUrl = new URL(anchor.href);
      const cmsUrl = new URL(process.env.BASE_URL);

      if (
        /* External */
        isExternal(anchor.href) &&
        /* Not a whitelisted protocols */
        !['mailto:', 'tel:'].includes(eventUrl.protocol) &&
        /* Not a link to cmsUrl */
        !eventUrl.hostname.match(cmsUrl.hostname) &&
        /* Trusted links weren't provided, or they were and destination isn't trusted. */
        !(this.trustedSites && this.trustedSites.includes(eventUrl.hostname))
      ) {
        event.preventDefault();
        this.setState({
          externalModalOpen: true,
          externalLink: anchor.href,
          externalLinkTarget: anchor.target || '_self'
        });
      }
    }
  };

  getMenuItemRoutes = (items, depth = 0) => {
    return items.map(item => {
      const path = getPath(item.url);
      return {
        ...item,
        depth,
        children: item.children ? this.getMenuItemRoutes(item.children, depth + 1) : [],
        ...path,
      };
    });
  };

  continueExternal() {
    this.setState({
      externalModalOpen: false
    });
    window.open(this.state.externalLink, this.state.externalLinkTarget);
  }

  closeModal() {
    this.setState({
      externalModalOpen: false
    });
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return null;
    }
    const { settings, strings, taxonomies } = this.state;
    const { header, utility, ...footerMenus } = { ...this.menuLocations, ...this.state.menus };
    const { countries } = this.state;
    return (
      <SettingsProvider value={settings}>
        <TaxonomiesProvider value={[taxonomies, taxonomies => this.setState({ taxonomies })]}>
          <StringsProvider value={strings}>
            <FocusVisibleManager>
              <Router>
                <div className="app-wrapper">
                  <Helmet titleTemplate={`${settings.name} - %s`} defaultTitle={settings.name}>
                    <meta name="og:title" content={process.env.META_TITLE} />
                    <meta name="og:type" content="website" />
                    <meta name="og:description" content={process.env.META_DESCRIPTION} />
                    <meta name="og:image" content={process.env.META_IMAGE_URL} />
                    <meta name="twitter:title" content={process.env.META_TITLE} />
                    <meta name="twitter:description" content={process.env.META_DESCRIPTION} />
                    <meta name="twitter:image" content={process.env.META_IMAGE_URL} />
                    <html lang={settings.language} />
                    <body className='page' />
                    <script>
                      {/* Runs when user active cookie groups are initialized and saved */}
                      {`function OptanonWrapper() {
                          var event;
                          var eventName = 'ptc.OneTrustGroupsDidUpdate';
                          try {
                            event = new Event(eventName);
                          } catch (e) {
                            event = document.createEvent('Event');
                            event.initEvent(eventName, true, true);
                          }
                          window.dispatchEvent( event );
                      }`}
                    </script>
                    <script>
                      {/**
                        * Ensure `dataLayer` is available on page load.
                        * `dataLayer` initialization must precede GTM snippet, see {@link https://developers.google.com/tag-manager/devguide#correct Google docs}.
                        */
                      }
                      {`window.dataLayer=[]`}
                    </script>
                    <script>
                      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${strings.general.gtm_id}');
                      // END GTM`}
                    </script>
                    {settings.language != 'de-DE' && settings.language != 'de-AT' && settings.language != 'de-CH' &&
                      <script src="/resources/scripts/youtube.js" />
                    }
                  </Helmet>
                  <noscript>
                    {/* GTM, LinkedIn, Facebook (noscript) */}
                    <iframe
                      src={`https://www.googletagmanager.com/ns.html?id=${strings.general.gtm_id}`}
                      height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}
                    />
                  </noscript>
                  {this.state.polyfilled && <>
                    {(this.state.externalModalOpen !== false && strings.general.exit_modal_enabled) &&
                      <Modal isOpen={this.state.externalModalOpen !== false}
                        size='lg'
                        className='external-modal modal-dialog-bottom justify-content-center'
                      >
                        <ModalBody>
                          <Row className='align-items-center g-3'>
                            <Col xs="auto">
                              <button className='modal-close' aria-label={strings.ui.cancel} onClick={this.closeModal} />
                            </Col>
                            <Col>
                              <Wysiwyg htmlString={strings.general.exit_modal_copy} />
                            </Col>
                            <Col xs='12' sm='auto'>
                              <Row className='g-3 justify-content-center justify-content-sm-end'>
                                <Col xs='auto'><button className='btn btn-gradient-alt btn-arrow' onClick={this.continueExternal}>{strings.ui.continue}</button></Col>
                              </Row>
                            </Col>
                          </Row>
                        </ModalBody>
                      </Modal>
                    }
                    <AlertBanner />
                    <UtilityNav menu={utility} countries={countries} />
                    <div className="main-wrapper">
                      {header ? <HeaderNav menu={header} siteName={settings.name} utilityMenu={utility} /> : null}
                      <main className="site-main">
                        <Page />
                      </main>
                    </div>
                    {footerMenus ? <FooterContainer menus={footerMenus} /> : null}
                  </>}
                </div>
              </Router>
            </FocusVisibleManager>
          </StringsProvider>
        </TaxonomiesProvider>
      </SettingsProvider>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
  seo: PropTypes.object,
  social: PropTypes.object,
};

export default App;
