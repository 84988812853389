/**
 * Table of Contents page template.
 *
 * @package frontend-ui
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import kebabCase from 'lodash.kebabcase';
import Img from '../Common/Img';
import SkipToMain from '../Common/SkipToMain';
import { Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import ErrorMessage from '../Common/ErrorMessage';
import PageFooter from '../Common/PageFooter';
import { StringsContext } from '../Contexts/StringsContext';
import Col from 'reactstrap/lib/Col';
import Sticky from 'react-stickynode';
import { headerHeight } from '../../utils/misc';
import classNames from 'classnames';
import ParsedWysiwyg from '../Common/ParsedWysiwyg';
import { Waypoint } from 'react-waypoint';
import debounce from 'lodash.debounce';

const tocHeight = 86;

function TocTemplate(props) {
  let {
    acf: {
      introduction,
      sections,
      calls_to_action,
      related_posts_posts,
      related_posts_heading,
      related_posts_link,
    },
    error
  } = props;

  const {
    ui: {
      jump_nav
    }
  } = React.useContext(StringsContext);

  const tocStickyRef = React.useRef()
  const [tocStickyState, setTocStickyState] = useState()
  const [tocCurrent, setTocCurrent] = useState(introduction.heading ?? '')
  const [tocOpen, setTocOpen] = useState(false)
  const [isJumping, setIsJumping] = useState(false)
  const [queuedTocCurrent, queueTocCurrent] = useState()

  const onTocItemClick = React.useCallback((e, { heading, anchor }) => {
    setIsJumping(true)
    queueTocCurrent({ heading, anchor })
  }, [setIsJumping, queueTocCurrent])

  /** Unset page jump state when we're done transitioning sections */
  const unsetIsJumping = React.useCallback(debounce(() => {
    setIsJumping(false)
  }, 100), [setIsJumping])

  /** Callback when a heading enters the viewing area */
  const onEnter = React.useCallback(({ previousPosition }, { heading }) => {
    if (isJumping) {
      // If we're performing a page jump, don't update the label
      return
    }
    if (Waypoint.above === previousPosition) {
      setTocCurrent(heading)
    }
  }, [setTocCurrent, isJumping])

  /** Callback when a heading leaves the viewing area */
  const onLeave = React.useCallback(({ currentPosition }, { heading }) => {
    if (isJumping) {
      // If we're performing a page jump, don't update the label
      return
    }
    if (Waypoint.above === currentPosition) {
      setTocCurrent(heading)
    }
  }, [setTocCurrent, isJumping])

  /**
   * Walker for tree of sections. Recursively, simultaneously builds elements for TOC and sections, so we only iterate
   * section config array once.
   *
   * @param {Object[]} sections
   * @returns {Object}
   */
  const walkHeadings = React.useCallback(
    function recurseHeadings(sections, level = 0, path) {
      //# Common
      let toc_nodes = []
      let section_nodes = []

      //# Section
      /** Whether to pull level-1 section copy to the right */
      let pullRight = true;

      sections.forEach((section, index) => {
        //# Common
        const { sections: subsections, ...forwardProps } = section
        const { heading, anchor, image } = section

        const anchor_id = anchor
          ? anchor
          : kebabCase(heading)

        const key = level
          ? `${path}.${index}`
          : index

        let subtoc_nodes = null;
        let subsection_nodes = [];

        // Recurse down
        if (subsections && subsections.length) {
          const subnodes = recurseHeadings(subsections, level + 1, key)

          subtoc_nodes = subnodes.toc
          subsection_nodes = subnodes.sections
        }

        //# TOC
        toc_nodes.push(
          <TocItem
            data-scroll-offset-top={headerHeight + tocHeight + 20}
            onClick={onTocItemClick}
            heading={heading}
            anchor={anchor_id}
            key={key}
            level={level}
          >
            {subtoc_nodes &&
              <Toc level={level + 1}>
                {subtoc_nodes}
              </Toc>
            }
          </TocItem>
        )

        //# Sections
        switch (level) {
          case 1:
            pullRight = image
              ? !pullRight
              : false
            break;

          default:
            break;
        }

        section_nodes.push(
          <Section
            {...forwardProps}
            key={key}
            pullRight={1 === level && pullRight}
            level={level}
            anchor={anchor_id}
            onEnter={onEnter}
            onLeave={onLeave}
          />,
          ...subsection_nodes
        )
      });

      return {
        sections: section_nodes,
        toc: toc_nodes
      }
    },
    [onTocItemClick, onEnter, onLeave]
  )

  function onStickyStateChange(e) {
    // Use height of TOC before collapse for placeholder element's height to prevent page content jump
    if (tocStickyRef.current) {
      const innerRect = tocStickyRef.current.innerElement.getBoundingClientRect();
      const innerHeight = innerRect.height || innerRect.bottom - innerRect.top;
      tocStickyRef.current.outerElement.style.setProperty('--sticky-bar-height', `${innerHeight}px`)
    }
    setTocStickyState(e.status)
  }

  useEffect(() => {
    window.addEventListener('scroll', unsetIsJumping)
    return () => {
      window.removeEventListener('scroll', unsetIsJumping)
    }
  }, [unsetIsJumping])

  useEffect(() => {
    if (!isJumping && queuedTocCurrent) {
      setTocCurrent(queuedTocCurrent.heading)
      document.getElementById(queuedTocCurrent.anchor).focus()
      queueTocCurrent(undefined)
    }
  }, [isJumping])

  useEffect(() => {
    if (Sticky.STATUS_FIXED !== tocStickyState) {
      setTocOpen(false)
    }
  }, [tocStickyState])

  const intro_anchor = introduction ? kebabCase(introduction.heading) : null;
  const isTocStuck = Sticky.STATUS_FIXED === tocStickyState
  const { sections: section_nodes, toc: toc_nodes } = walkHeadings(sections)

  return <>
    <Container className="page-main mb-7">
      <section className='page-content'>
        <SkipToMain />
        <Row className='justify-content-center'>
          <Col sm={10}>
            <ErrorMessage error={error} />
            <Row className="justify-space-between">
              <Col className='mb-5 mb-xl-0' xl={3}>
                <Sticky
                  className={'sticky-outer-wrapper--stick-height'}
                  ref={tocStickyRef}
                  innerClass={classNames('sticky-bar sticky-bar--col10', { 'is-stuck': isTocStuck })}
                  top={headerHeight}
                  innerZ={2}
                  onStateChange={onStickyStateChange}
                >
                  <div className={classNames("sticky-bar__container", { 'py-2': isTocStuck })}>
                    <nav
                      className={classNames('jump-nav', { 'is-mini': isTocStuck })}
                    >
                      {jump_nav?.heading &&
                        <p id="jump-nav-heading" className="jump-nav__heading">{jump_nav.heading}:</p>
                      }
                      <Dropdown
                        className="jump-nav__dropdown"
                        disabled={!isTocStuck}
                        isOpen={isTocStuck && tocOpen}
                        toggle={() => { setTocOpen(!tocOpen) }}
                      >
                        <DropdownToggle
                          cssModule={{ 'btn': ' ', 'btn-secondary': ' ' }}
                          className='jump-nav__toggle'
                          id='jump-nav-toggle'
                          aria-labelledby={classNames({ 'jump-nav-heading': jump_nav.heading }, 'jump-nav-toggle')}
                        >
                          {tocCurrent}
                        </DropdownToggle>
                        <DropdownMenu
                          className="jump-nav__menu"
                          cssModule={{ 'dropdown-menu': ' ' }}
                        >
                          <Toc>
                            {introduction &&
                              <TocItem data-scroll-offset-top={headerHeight + tocHeight + 20} anchor={intro_anchor} onClick={onTocItemClick} heading={introduction.heading} />
                            }
                            {toc_nodes}
                          </Toc>
                        </DropdownMenu>
                      </Dropdown>
                      {isTocStuck && jump_nav.scroll_to_top &&
                        <a className="ml-auto" href="#" onClick={(e) => {
                          e.preventDefault();
                          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                        }}>
                          {jump_nav.scroll_to_top}
                        </a>
                      }
                    </nav>
                  </div>
                </Sticky>
              </Col>
              {introduction &&
                <Col xl={8}>
                  <div className="wysiwyg-content">
                    <Waypoint
                      topOffset={headerHeight + tocHeight}
                      scrollableAncestor={window}
                      onEnter={waypoint => {
                        onEnter(waypoint, { heading: introduction.heading, anchor: intro_anchor, level: 0 })
                      }}
                      onLeave={waypoint => {
                        onLeave(waypoint, { heading: introduction.heading, anchor: intro_anchor, level: 0 })
                      }}
                    />
                    <h2 tabIndex="-1" id={intro_anchor} className='sr-only'>{introduction.heading}</h2>
                    <ParsedWysiwyg htmlString={introduction.content} />
                  </div>
                </Col>
              }
            </Row>
            <div className="jump-sections wysiwyg-content mt-7">
              {section_nodes}
            </div>
          </Col>
        </Row>
      </section>
    </Container>
    <PageFooter related_posts={{ posts: related_posts_posts, link: related_posts_link, heading: related_posts_heading }} className="mt-7" calls_to_action={calls_to_action} />
  </>
}

TocTemplate.propTypes = {
  acf: PropTypes.object.isRequired,
  error: PropTypes.object,
}

/**
 * Table of Contents
 * @param {Object} props 
 */
const Toc = React.forwardRef((props, ref) => {
  const {
    children,
    level = 0,
    className = '',
    ...forwardProps
  } = props;

  return <ol {...forwardProps} ref={ref} className={`jump-nav__list jump-nav__list--l${level} ${className}`}>
    {children}
  </ol>
})

Toc.displayName = 'Toc'

Toc.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  level: PropTypes.number
}

/**
 * Table of Contents item
 * @param {Object} props 
 */
const TocItem = React.forwardRef((props, ref) => {
  const {
    level = 0,
    anchor,
    heading,
    children,
    className = '',
    onClick,
    ...forwardProps
  } = props;

  const anchor_id = anchor
    ? anchor
    : kebabCase(heading)

  return <li ref={ref} className={`jump-nav__item jump-nav__item--l${level} ${className}`}>
    <DropdownItem
      {...forwardProps}
      tag="a"
      className='jump-nav__link'
      cssModule={{ 'dropdown-item': ' ' }}
      onClick={e => { onClick && onClick(e, { anchor, heading, level }) }}
      href={`#${anchor_id}`}
    >
      {heading}
    </DropdownItem>
    {children}
  </li>
})

TocItem.displayName = 'TocItem'

TocItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  level: PropTypes.number,
  anchor: PropTypes.string,
  heading: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
}

/**
 * A single section, associated with a heading/anchor.
 *
 * @param {Object} props 
 */
function Section(props) {
  const {
    // Common
    level = 0,
    heading,
    content,
    anchor,
    // Waypoint
    onEnter,
    onLeave,
    onPositionChange,
    // Level 1
    image,
    pullRight = false,
  } = props;

  const blockClass = classNames(`jump-section jump-section--l${level}`, { 'is-contentless': !content })

  const waypointProps = {
    topOffset: headerHeight + tocHeight,
    onEnter: onEnter && (args => {
      onEnter(args, { anchor, heading, level })
    }),
    onLeave: onLeave && (args => {
      onLeave(args, { anchor, heading, level })
    }),
    onPositionChange: onPositionChange && (args => {
      onPositionChange(args, { anchor, heading, level })
    }),
    scrollableAncestor: window,
  }

  switch (level) {
    case 0:
      return <Row className={blockClass}>
        <Col xs={12}>
          <Waypoint {...waypointProps} />
          <h2 tabIndex="-1" className='jump-section__heading' id={anchor}>{heading}</h2>
          <ParsedWysiwyg className='jump-section__content' htmlString={content} />
        </Col>
      </Row>

    case 1:
      return <Row className={`${blockClass} align-items-center justify-content-between`}>
        <Col lg={{
          size: image ? 6 : 12,
          order: pullRight ? 1 : undefined
        }}>
          <Waypoint {...waypointProps} />
          <h3 tabIndex="-1" className='jump-section__heading' id={anchor}>{heading}</h3>
          <ParsedWysiwyg className='jump-section__content' htmlString={content} />
        </Col>
        {image &&
          <Col lg={6}>
            <Img className="jump-section__image" size='medium_large' {...image} />
          </Col>
        }
      </Row>


    default:
      return null
  }
}

Section.propTypes = {
  className: PropTypes.string,
  pullRight: PropTypes.bool,
  level: PropTypes.number,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  anchor: PropTypes.string.isRequired,
  image: PropTypes.object,
  /** Callback for Waypoint's onEnter */
  onEnter: PropTypes.func,
  /** Callback for Waypoint's onLeave */
  onLeave: PropTypes.func,
  /** Callback for Waypoint's onPositionChange */
  onPositionChange: PropTypes.func,
}

export default TocTemplate;