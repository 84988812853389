import React, { useState, forwardRef, } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import { handleRef } from '../../utils/misc';

export const Pop = forwardRef((props, ref) => {
  const { children, tag: Tag, ...rest } = props;

  const [,setReferenceElement] = useState(null);

  return <Tag {...rest} ref={el => { setReferenceElement(el); handleRef(ref, el); }}>
    {children}
  </Tag>
})

Pop.displayName = 'Pop';

Pop.propTypes = {
  tag: PropTypes.elementType,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

Pop.defaultProps = {
  tag: 'button',
}


export const Popped = forwardRef((props, ref) => {
  const { referenceElement, children, options, style, tag: Tag, ...rest } = props;

  const [popperElement, setPopperElement] = useState(null);
  const instance = usePopper(referenceElement, popperElement, options);
  const { styles, attributes } = instance;

  return <Tag {...rest} ref={el => {setPopperElement(el); handleRef(ref, el)}} style={{...styles.popper, ...style}} {...attributes.popper}>
      {children}
    </Tag>
});

Popped.displayName = 'Popped';

Popped.propTypes = {
  /** Tag to use. Default `<div>`. */
  tag: PropTypes.elementType,
  /** Ref to the popper's reference element. */
  referenceElement: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /** Popper options, see {@link https://popper.js.org/docs/v2/constructors/#options Popper docs}. */
  options: PropTypes.object,
  style: PropTypes.object,
}

Popped.defaultProps = {
  tag: 'div'
}