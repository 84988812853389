import * as React from "react";
import PropTypes from "prop-types";
import { ExternalFundingQualificationForm } from "./ExternalFundingQualificationForm";
import { withStrings } from "../../Contexts/StringsContext";
import Loading from "../../Common/Loading";
import RichText from "./RichText";
import { ExternalFundingForm } from "./ExternalFundingForm";
import { maybeClassName } from "../../../utils/inputs";

class ExternalFundingFormWrapperBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRequestForm: false,
      showFundingTypes: window.location.hash === "#funding_types",
    }
  }

  componentDidMount() {
    this.forceUpdate();
  }

  onCompleteQuestionnaire = success => {
    if (success) {
      this.setState({ showRequestForm: true });
    }
  };

  toggleFundingTypes = e => {
    const { target } = e.nativeEvent;
    if (target instanceof HTMLAnchorElement && target.hash === "#funding_types") {
      e.preventDefault();
      this.setState({ showFundingTypes: !this.state.showFundingTypes })
    }
  };

  render() {
    const { method, url, strings } = this.props;
    const { external_funding_form } = this.props.strings;

    const fundingTypesRegionId = `funding_types-region`;

    if (!external_funding_form) {
      return <Loading />;
    }
    const { showRequestForm, showFundingTypes } = this.state;
    return <div className="external-funding-form">
      <div className="eff__preface-text">
        <RichText wysiwyg={external_funding_form.form_text.preface_text} />
      </div>
      <div className="eff__funding-types">
        <div className="eff__funding-types__preface" data-show={!showFundingTypes} onClick={this.toggleFundingTypes}>
          <RichText
            preprocessNode={node => {
              if ('a' === node.name && '#funding_types' == node.attribs.href) {
                node.attribs = {
                  ...node.attribs,
                  role: 'button',
                  'aria-label': 'Toggle funding types',
                  'aria-expanded': showFundingTypes,
                  'aria-controls': fundingTypesRegionId,
                }
              }
              return node;
            }}
            wysiwyg={external_funding_form.form_text.funding_types_show_text}
          />
        </div>
        <div className="eff__funding-types__preface" data-show={showFundingTypes}>
          <RichText wysiwyg={external_funding_form.form_text.funding_types_preface_text} />
        </div>
        <div className="eff__funding-types__content-wrapper">
          <div className="eff__funding-types__close-wrapper centered">
            <button
              className={`eff__funding-types__close btn${maybeClassName(!showFundingTypes && 'sr-only')}`}
              aria-controls={fundingTypesRegionId}
              aria-expanded={showFundingTypes}
              onClick={() => this.setState({ showFundingTypes: !showFundingTypes })}><span className="sr-only">Toggle funding types</span><span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="eff__funding-types__content" id={fundingTypesRegionId} role="region" aria-label="Funding Types" data-show={showFundingTypes}>
            {Object.entries(external_funding_form.funding_activity_types).map(([name, strings]) => (
              <div key={name} className="eff__funding-types__type">
                <div className="eff__funding-types__type__title centered">{strings.title}</div>
                <RichText wysiwyg={strings.description} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ExternalFundingQualificationForm
        strings={this.props.strings}
        onComplete={this.onCompleteQuestionnaire}
      />
      {showRequestForm &&
        <div className="centered">
          <RichText wysiwyg={external_funding_form.form_text.login_text} />
          <ExternalFundingForm {...{ method, url, strings }} />
        </div>
      }
    </div>
  }
}

ExternalFundingFormWrapperBase.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
}

export const ExternalFundingFormWrapper = withStrings(ExternalFundingFormWrapperBase);
ExternalFundingFormWrapper.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
