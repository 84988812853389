import React from 'react';
import PropTypes from 'prop-types';
import { ariaAttributes } from '../../utils/a11y';
import { htmlAttributes } from '../../utils/misc';
import { pick as _pick } from 'lodash';

const Headline = ({ children, tag, ...props }) => {
  if (!children) {
    return null;
  }
  let filteredProps = _pick( props, ariaAttributes, htmlAttributes );
  const HeadingElement = tag;
  return <HeadingElement {...filteredProps}>{children}</HeadingElement>
};

Headline.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};

Headline.defaultProps = {
  tag: 'h2',
  children: '',
  className: 'headline',
};

export default Headline;
