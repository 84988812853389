import React from "react";
import PropTypes from "prop-types";
import S from "string";
import Cta from "./Layouts/Cta";
import Gallery from "./Layouts/Gallery";
import Image from "./Layouts/Image";
import Accordion from "./Layouts/Accordion";
import Lightbox from "./Layouts/Lightbox";
import Quote from "./Layouts/Quote";
import RichText from "./Layouts/RichText";
import RichTextHTML from "./Layouts/RichTextHTML";
import Video from "./Layouts/Video";
import FormLayout from "./Layouts/Form";
import Columns from "./Layouts/Columns";
import ColumnsCallout from "./Layouts/ColumnsCallout";
import Locations2 from "./Layouts/Locations/Locations-v2.0.0";
import Locations1 from './Layouts/Locations/Locations-v1.0.0'
import Grid from "./Layouts/Grid";
import Alert from './Layouts/Alert'
import Timeline from "./Layouts/Timeline";
import { isPlainObject } from "lodash";
import Callout from "./Layouts/Callout";
import PdfGrid from "./Layouts/PdfGrid"

const components = {
  alert: Alert,
  cta: Cta,
  gallery: Gallery,
  image: Image,
  accordion: Accordion,
  quote: Quote,
  wysiwyg: RichText,
  html: RichTextHTML,
  video: Video,
  lightbox: Lightbox,
  form: FormLayout,
  columns: Columns,
  'locations-v2.0.0': { class: 'locations', component: Locations2 },
  'locations': { class: 'locations-v1', component: Locations1},
  columns_callout: ColumnsCallout,
  grid: Grid,
  "timeline-v1.0.0": {component: Timeline, class: 'timeline'},
  "callout-v1.0.0": {component: Callout, class: 'callout-1'},
  pdf_grid: PdfGrid,
};

const Layout = props => {
  const { acf_fc_layout, ...layout } = props;
  if (!components[acf_fc_layout]) {
    return null;
  }

  /**
   * Layout options object
   * 
   * @type {Object}
   * @property {String} class Top level class
   * @property {*} component React component
   */
  let opts;

  // Standardize options object
  if (isPlainObject(components[acf_fc_layout]) && components[acf_fc_layout].component) {
    opts = components[acf_fc_layout]
  } else {
    opts = { component: components[acf_fc_layout] }
  }

  const Component = opts.component
  opts.class = opts.class ?? S(acf_fc_layout).slugify().s;

  return (
    <div className={`acf-layout acf-layout--${opts.class} ${opts.class}`}>
      <Component id={acf_fc_layout} {...layout} />
    </div>
  );
};

Layout.propTypes = {
  acf_fc_layout: PropTypes.string,
  layout: PropTypes.any
};

Layout.defaultProps = {
  content: []
};

export default Layout;
