/**
 * Helper to determine when to disable or freeze sticky node styles.
 *
 * @package ptc-therapeutic
 */

import { useEffect, useState } from "react";

/**
 * Helper to determine when to disable or freeze sticky node styles.
 * 
 * To get correct sticky styles, you must disable before freezing, and unfreeze before enabling.
 * 
 * @param {Boolean} active Whether sticky node is unfrozen/enabled.
 */
function useStickyFreeze(active, ref) {
  const [enabled, setEnabled] = useState(active)
  const [shouldFreeze, setFrozen] = useState(!active)

  // First state change
  useEffect(() => {
    if (active) {
      setFrozen(false)
    } else {
      setEnabled(false)
    }
  }, [active])

  // Deactivating: second state change
  useEffect(() => {
    if (!enabled) {
      if (!ref.current.outerElement.getAttribute('style')) {
        // Immediate update
        setFrozen(true)
      } else {
        // Deferred update
        const observer = new MutationObserver(([{ target }], observer) => {
          if (!target.getAttribute('style')) {
            observer.disconnect()
            setFrozen(true)
          }
        })

        observer.observe(ref.current.outerElement, {
          attributeFilter: ['style']
        })

        return () => {
          observer.disconnect()
        }
      }
    }
  }, [enabled])

  // Activating: second state change
  useEffect(() => {
    if (!shouldFreeze) {
      setEnabled(true)
    }
  }, [shouldFreeze])

  return {
    enabled,
    shouldFreeze
  }
}

export default useStickyFreeze;