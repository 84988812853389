import React from 'react';
import PropTypes from 'prop-types';
import Cta from './Layouts/Cta';

const CallsToAction = ({ items }) => {
  if (items.filter(item => item.background || item.headline || item.copy || item.button ).length) {
    return <div className="cta__container">{items.map((cta, key) => <Cta key={key} cta={cta} />)}</div>;
  }

  return null;
};

CallsToAction.propTypes = {
  items: PropTypes.array,
};

CallsToAction.defaultProps = {
  items: [],
};

export default CallsToAction;
