/**
 * Page.
 * 
 * Implements standard code for all pages: 
 * - fetch post data on load
 * - scroll to top on load
 * - assign body classes
 * - add page-specifc meta tags
 *
 * @package ptc-therapeutic
 */

import React from 'react';
import PropTypes from 'prop-types';
import PageNotFound from '../Templates/NotFound';
import PostsPage from '../Templates/Posts';
import { fetchPostByUrl } from '../../api';
import MainContent from './MainContent';
import { findKey, get } from 'lodash';
import { withSettings } from '../Contexts/SettingsContext';
import PageWrapper from './PageWrapper';
import { templateMap } from '../../utils/templates';
import Search from '../Templates/Search';
import SinglePost from '../Templates/SinglePost';
import SingleEvent from '../Templates/SingleEvent';
import { Switch, Route, matchPath, withRouter } from 'react-router-dom';
import { permalinkStructureRegex } from '../../utils/misc';
import Error from '../Templates/Error';

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loaded: false,
      data: {},
      error: {},
      queuePageview: false,
      location: props.location?.pathname ?? '',
      hasError: false,
    };

    const { settings: { posts_page, permalink_structure, event_slug } = {} } = props;

    let archive_posts, single_post, single_event;

    /**
     * Routes, from most to least specific, with path, request, and component.
     */
    this.rewrite = [
      {
        path: '/search(\\?.*)?',
        component: Search,
        request({ url }) {
          return fetchPostByUrl(url)
        }
      }
    ];

    if (posts_page?.link) {
      try {
        archive_posts = (new URL(posts_page.link)).pathname;
      } catch (error) {
        // Silent fail
      }
    }

    if (archive_posts) {
      this.rewrite.push({
        path: (new URL(posts_page.link)).pathname,
        component: PostsPage,
        request({ url }) {
          return fetchPostByUrl(url)
        },
      });
    }

    single_event = event_slug && permalinkStructureRegex(`/${event_slug}/%postname%`);

    if (single_event) {
      this.rewrite.push({
        path: single_event,
        component: SingleEvent,
        request({ url }) {
          return fetchPostByUrl(url, 'event')
        }
      });
    }

    single_post = permalink_structure && permalinkStructureRegex(permalink_structure);

    if (single_post) {
      this.rewrite.push({
        path: single_post,
        component: SinglePost,
        request({ url }) {
          return fetchPostByUrl(url, 'posts');
        }
      });
    }

    this.rewrite.push({
      path: '*',
      component: PageWrapper,
      request({ url }) {
        return fetchPostByUrl(url)
      }
    });

    ['onTagRendered'].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  updatePage = () => {
    const { location: { pathname } = {} } = this.props;

    this.setState({ data: {}, loaded: false, isLoading: true, location: pathname ?? '' }, () => window.scroll({ top: 0, left: 0 }));

    let match;
    let post;

    for (const { path, request } of this.rewrite) {
      match = matchPath(pathname, { path });
      if (match) {
        post = request(match);
        break;
      }
    }

    return post.then(({ data, error }) => {
      const { page_types } = this.props.settings;
      const { id } = data;

      this.page_type = findKey(page_types, page_type_id => page_type_id == id);

      this.setState(
        {
          loaded: true,
          isLoading: false,
          data,
          error,
        },
      );
      return { data, error };
    });
  };

  componentDidMount() {
    this.updatePage();
  }

  componentDidUpdate(prevProps, prevState) {
    const isNewPage = this.props.location.pathname !== prevProps.location.pathname;
    const isNewlyLoaded = prevState.isLoading === true && this.state.isLoading === false;
    if (isNewlyLoaded) {
      this.setState({
        queuePageview: true
      })
    }

    if (isNewPage) {
      this.setState({ hasError: false })
      this.updatePage()
    }
  }

  /**
   * Manually send pageview event to analytics based on location changes.
   *
   * @see {@link https://reactrouter.com/web/api/Hooks/uselocation React Router documentation} 
   */
  sendPageview() {
    // Google Tag Manager syntax
    if (window.dataLayer !== undefined) {
      window.dataLayer.push({
        "event": "pageview"
      })
    }
  }

  /**
   * Callback for head tag re-render
   *
   * @param {import('./MainContent').NewState} newState 
   * @param {import('./MainContent').AddedTags} addedTags 
   */
  onTagRendered(newState) {
    const { queuePageview, } = this.state;

    if (queuePageview) {
      const title = this.state.data.title?.rendered

      // Determine based on whether page title is now correct.
      const areTagsReady = -1 !== newState.title.indexOf(title);
      if (areTagsReady) {
        this.setState({
          queuePageview: false
        })
        this.sendPageview();
      }
    }
  }

  render() {
    const { loaded, data = {}, isLoading, hasError, error } = this.state;
    const { template, id, title = {}, acf = {}, type } = data;
    let { seo = {}, link } = data;
    const { banner_image, content, calls_to_action } = acf;
    const {
      location
    } = this.props;

    const homepage = location.pathname === "/";
    if (link && !seo.url) seo = { url: `${window.location.origin}${(new URL(link)).pathname}`, ...seo };

    const bodyClasses = [
      "page",
      get(templateMap, `type.${this.page_type}.class`),
      template // Post template
        ? template.replace(/\.[a-z\d]+$/i, '')
        : (type
          ? `${type}-template-default`
          : ''
        ),
      {
        [`single-${type}`]: type, // Post type
        "page-homepage": homepage,
        "has-acf-content": content && 'page_for_cookies' !== this.page_type,
        "has-acf-ctas": calls_to_action,
        "has-header-image": banner_image && banner_image.url,
        "no-page-header": homepage
      }
    ];

    if (loaded && !id) {
      return <PageNotFound />;
    }

    return <MainContent
      title={title.rendered}
      bodyClasses={bodyClasses}
      isLoading={isLoading}
      seo={seo}
      tag="article"
      onRendered={this.onTagRendered}
    >
      <Switch>
        {this.rewrite.map(({ path, component: Component }) => {
          return <Route key={path} path={path} render={routeProps => {
            return hasError
              ? <Error />
              : <Component
                {...routeProps}
                {...data}
                page_type={this.page_type}
                error={error}
                isLoading={isLoading}
                loaded={loaded}
              />
          }} />
        })}
      </Switch>
    </MainContent>
  }
}

Page.propTypes = {
  location: PropTypes.object,
  settings: PropTypes.object,
};

/**
 * We need to reset the isLoading state when the location.pathname prop changes, but before render, to show to proper page template.
 * @link https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
 */
export default withRouter(withSettings(Page))
