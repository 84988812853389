/**
 * PageFooter
 *
 * @package ptc-therapeutic
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { maybeClassName } from '../../utils/inputs';
import CallsToAction from '../ACF/CallsToAction';
import { Container, Row, Col } from 'reactstrap';
import SocialShare from './SocialShare';
import LoopPost from './LoopPost';
import { StringsContext } from '../Contexts/StringsContext';
import ButtonLink from './ButtonLink';

/**
 * React Component PageFooter.
 */
const PageFooter = props => {
  const { className, calls_to_action, related_posts = {} } = props;
  const { heading, posts, link } = related_posts;

  const strings = useContext(StringsContext);
  const { social: { platforms } = {} } = strings;

  const children = ['social_share', 'related_posts', 'calls_to_action'].map(name => {
    if (!props[name]) return false;

    let children;
    let container = {};
    let col = { sm: 10 };
    let row = { className: "page-footer__row justify-content-center" };

    switch (name) {
      case 'social_share':
        children = (platforms && platforms.length) && <SocialShare />;
        break;

      case 'related_posts':
        children = (posts && posts.length > 0) && <div className="related-posts">
          <h2 className="related-posts__heading">{heading}</h2>
          <ul className="related-posts__posts posts posts--cols-4">
            {posts.map((post => <li key={post.id}><LoopPost className="post--inner" data={post} /></li>))}
          </ul>
          {link && <ButtonLink type={['gradient', 'rounded', 'arrow']} {...link} />}
        </div>
        break;

      case 'calls_to_action':
        children = <CallsToAction items={calls_to_action} />
        break;
      default:
        break;
    }

    return children && <div key={name} className="page-footer__wrapper">
      <Container {...container}>
        <Row {...row}>
          <Col {...col}>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  });

  return <footer className={`page-footer${maybeClassName(className)}`} >
    {children}
  </footer>
}

PageFooter.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  calls_to_action: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool,
  ]),
  related_posts: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.object),
    heading: PropTypes.string,
  }),
}

export default PageFooter;