import React, { forwardRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { StringsContext } from '../Contexts/StringsContext';
import classNames from 'classnames';

/**
 * Get screen reader text for links
 *
 * @param {Object} opts Options
 * @param {Boolean} opts.external Whether the link is external. Default `false`.
 * @param {Boolean} opts.blank Whether the link opens in a new browsing context (i.e. tab or window). Default `false`.
 * @param {String} opts.target The link's target attribute. If the `blank` option is omitted, `target` determines
 * whether the link opens in a new browsing context (i.e. tab or window).
 *
 * @returns {String}
 */
export function useLinkSr(opts) {
  const {
    external = false,
    blank,
    target,
  } = opts;

  const { ui: { links: { external: externalLabel, new_context } = {} } } = useContext(StringsContext);

  const isBlank = blank ?? (
    undefined !== target
      ? '_blank' === target
      : false
  )

  // Build string
  let copy = []

  if (external && externalLabel) copy.push(externalLabel)
  if (isBlank && new_context) copy.push(new_context)

  return copy.join(' ')
}

/**
 * Component to simplify rendering of screen-reader-only text for links.
 */
const LinkSr = forwardRef((props, ref) => {
  const {
    external,
    blank,
    target,
    as: As = 'span',
    className,
    ...forwardProps
  } = props;

  const copy = useLinkSr({ external, blank, target });

  if (0 === copy.length) return null;

  return <As {...forwardProps} ref={ref} className={classNames(className, "sr-only")}>{copy}</As>
});

LinkSr.propTypes = {
  /** Whether the link is external. Default `false`. */
  external: PropTypes.bool,
  /** Whether the link opens in a new browsing context (i.e. tab or window). Default `false`. */
  blank: PropTypes.bool,
  /** 
   * The link's target attribute. If the `blank` prop is omitted, `target` determines whether the link opens in a new
   * browsing context (i.e. tab or window).
   */
  target: PropTypes.oneOf([
    '_blank',
    '_parent',
    '_self',
    '_top',
    ''
  ]),
  /** Element/component to render this component */
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType
  ]),
  className: PropTypes.string,
}

LinkSr.displayName = 'LinkSr'

export default LinkSr;