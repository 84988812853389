import React from 'react';
import PropTypes from 'prop-types';
import ParsedWysiwyg from '../../../Common/ParsedWysiwyg';

const Locations = ({ groups }) => {
  if (!groups) {
    return null;
	}

  return (
    <div className="locations-v1__container centered rich-text">
			{groups.map(( group, index ) => {
				return(
					<div className="locations-v1__group" key={index}>
						<h3 className="locations-v1__title">{group.title}</h3>
						{group.locations.map(( location, index ) => {
							return(
								<div className={`locations-v1__location ${location.video ? "locations-v1__location--has-video" : ""}`} key={index}>
									<div className="locations-v1__description">
										<div className="locations-v1__image">
											<img alt={location.image.alt} src={location.image.sizes.medium} />
										</div>
									
										<div className="locations-v1__address wysiwyg-content">
											<strong>{location.name}</strong>
											<ParsedWysiwyg htmlString={location.address} />
										</div>
									</div>

									{location.video &&
										<div className="locations-v1__video embed-responsive embed-responsive-16by9">
											<ParsedWysiwyg htmlString={location.video.embed} />
										</div>
									}
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
  );
};

Locations.propTypes = {
	groups: PropTypes.array,
};

export default Locations;
