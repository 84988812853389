import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { breakpoints, srcsetStr } from '../../../utils/misc';
import ParsedWysiwyg from '../../Common/ParsedWysiwyg';
import * as Icons from '../../Svg/Menu'

const Event = forwardRef((props, ref) => {
  const {
    className,
    date,
    description,
    image,
    icon
  } = props;

  if (!(date && description)) return null;
  
  // eslint-disable-next-line import/namespace
  const Icon = icon?.title && Icons[icon.title.replace(/ /g, '')];

  return <li ref={ref} className={classNames('timeline-event', className)}>
    {image &&
      <div className="timeline-event__image-outer">
        <img
          className="timeline-event__image"
          src={image.sizes.medium_large}
          srcSet={srcsetStr('medium_large', image.sizes)}
          sizes={`(min-width: ${breakpoints.lg}px) 50vw, 100vw`}
          alt={image.alt}
        />
      </div>
    }
    <div className="timeline-event__copy">
      <h3 className="timeline-event__date">{date}</h3>
      <div className="timeline-event__description"><ParsedWysiwyg htmlString={description} /></div>
    </div>
    <div className="timeline-event__line">{Icon && <Icon aria-hidden="true" className='timeline-event__icon' />}</div>
  </li>
})

Event.displayName = 'Event'

const EventPropTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object,
  icon: PropTypes.string,
}

Event.propTypes = {
  className: PropTypes.string,
  ...EventPropTypes
}

const Timeline = forwardRef((props, ref) => {
  const {
    events = [],
    className
  } = props

  if (!events.length) return null;

  return <ol ref={ref} className={classNames('timeline__inner centered', className)}>
    {events.map(event => <Event
      key={JSON.stringify({ date: event.date, icon: event.icon?.ID, image: event.image?.ID })}
      className='timeline__event'
      {...event}
    />)}
  </ol>
})

Timeline.propTypes = {
  className: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.shape(EventPropTypes))
}

Timeline.displayName = 'Timeline'

export default Timeline;