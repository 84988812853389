import * as React from "react";
import PropTypes from "prop-types";
import { Checkbox, InputWrapper, TextInput } from "../../Common/Inputs";
import {
  EMAIL_REGEX,
  format_phone_number,
  PHONE_PATTERN,
  EMAIL_PATTERN, COUNTRIES, US_STATES, CANADIAN_PROVINCES,
  maybeClassName
} from "../../../utils/inputs";
import Loading from "../../Common/Loading";
import { postFundingRequestForm } from "../../../api";
import { withFocusVisible, FocusVisiblePropType } from '../../Contexts/FocusVisibleManager';
import Listbox from "../../Common/Listbox";

class ExternalFundingFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data: {
        AddressCountry: COUNTRIES[0].value,
      },
      attestation: false,
      TermsOfUse: false,
      funding_activity_options:
        Object.values(props.strings.external_funding_form.funding_activity_types)
          .map((t, i) => ({ value: t.title || `${i}`, label: t.title || `${i}` })),
      /*countries: props.strings.external_funding_form.fields.AddressCountry.options.split("\n")
        .map((c, i) => ({ value: c ? c.trim() : `${i}`, label: c ? c.trim() : `${i}`})),*/
      pending: false,
      result: undefined,
      error: undefined,
      invalid_fields: [],
      test: undefined,
    };

    this.focusable = {
      first: React.createRef(),
    }
  }

  componentDidMount() {
    const { hadKeyboardEvent } = this.props.focusVisible;
    if (hadKeyboardEvent && this.focusable.first.current) this.focusable.first.current.focus();
  }

  /**
   * Returns handler for a form field's change event, that lifts field value to this component's state.
   * @param {string} name Name of the field.
   * @param {function} get_value Function to get the value from the event object.
   */
  onChange = (name, get_value = (e => e.target.value)) => event => {
    this.setState({
      form_data: {
        ...this.state.form_data,
        [name]: get_value(event),
        ...(name === "AddressCountry" ? { AddressState: undefined } : {})
      }
    });
  };

  onSelect = name => opt => this.onChange(name, opt => opt.value)(opt);

  validateForm = () => {
    const { fields } = this.props.strings.external_funding_form;
    const { attestation, form_data, TermsOfUse } = this.state;
    const phone = format_phone_number(form_data.Phone);

    if (!attestation || !TermsOfUse) {
      return false;
    }

    const invalid_fields = [];
    Object.entries(fields).forEach(([name, field]) => {
      let valid = true;
      if (field.required && !form_data[name]) {
        valid = false;
      } else if (form_data[name]) {
        if (name === "Email") {
          valid = valid && EMAIL_REGEX.test(form_data[name]);
        } else if (name === "Phone") {
          valid = valid && !!phone;
        } else {
          valid = valid && form_data[name].length > 1
        }
      }
      if (!valid) {
        invalid_fields.push(name);
      }
    });
    if (invalid_fields.length > 0) {
      this.setState({ invalid_fields });
      return false;
    }

    return {
      ...form_data,
      Phone: phone,
    };
  };

  onSubmit = e => {
    e.preventDefault();
    const data = this.validateForm();
    if (!data) {
      return false;
    }
    this.setState({ pending: true }, () =>
      postFundingRequestForm(data)
        .then(this.handleRequestResponse)
    )
  };

  handleRequestResponse = res => {
    const { message, invalid_fields = [] } = this.getResponseError(res);
    this.setState({
      pending: false,
      result: res,
      error: message,
      invalid_fields,
    });
  };

  getResponseError = (result = this.state.result) => {
    if (!result || result.status === 200) {
      return {};
    }
    const { error } = result;
    const { strings } = this.props;
    if (!error) {
      return {
        message: strings.form.errors.generic
      };
    }
    const { code, message } = error;
    switch (code) {
      case "invalid_fields":
        return {
          invalid_fields: message,
          message: strings.form.errors.invalid_fields,
        };
      case "invalid_request":
        return {
          message
        };
      default:
        return {
          message: strings.form.errors.generic
        }
    }
  };

  // set attributes for the <input> based on the field name and strings
  make_field = ([name, field], inputProps = {}) => {
    if (name === "attestation" || name === "TermsOfUse") {
      return null;
    }

    const { invalid_fields } = this.state;
    const invalid = invalid_fields.includes(name);
    /**
     * When an invalid field's value changes, remove it from `state.invalid_fields`.
     *
     * @param {function} func Event handler.
     */
    const with_invalid = func => invalid
      ? (...args) => {
        this.setState({
          invalid_fields: invalid_fields.filter(f => f !== name),
        });
        return func(...args);
      }
      : func;

    let Component = TextInput;
    const instructionsId = field.instructions ? `${name}_instructions` : undefined;
    const titleProps = {
      id: `eff_field_${name}-title`
    }
    let props = {
      name,
      placeholder: field.placeholder || undefined,
      required: field.required,
      value: this.state.form_data[name],
      onChange: with_invalid(this.onChange(name)),
      'aria-labelledby': titleProps.id + maybeClassName(instructionsId),
      'aria-required': field.required || undefined,
    };
    let title = field.title;
    const { AddressCountry } = this.state.form_data;


    switch (name) {
      case "Phone":
        props = {
          ...props,
          pattern: PHONE_PATTERN,
          validate: v => !v || !!format_phone_number(v),
          type: "tel"
        };
        break;
      case "Email":
        props = {
          ...props,
          pattern: EMAIL_PATTERN,
          validate: v => EMAIL_REGEX.test(v),
          type: "email"
        };
        break;
      case "AddressCountry":
        Component = Listbox;
        props = {
          ...props,
          disabled: this.state.pending,
          onChange: with_invalid(this.onSelect(name)),
          options: COUNTRIES,
        };
        break;
      case "AddressState":
        if (AddressCountry === "United States" || AddressCountry === "Canada") {
          Component = Listbox;
          props = {
            ...props,
            disabled: this.state.pending,
            onChange: with_invalid(this.onSelect(name)),
            options: AddressCountry === "United States" ? US_STATES : CANADIAN_PROVINCES,
          };
          title = AddressCountry === "United States" ? field.title.us : field.title.canada;
        } else {
          title = field.title.other
        }
        break;
      case "FundingActivityType":
        Component = Listbox;
        props = {
          ...props,
          disabled: this.state.pending,
          onChange: with_invalid(this.onSelect(name)),
          options: this.state.funding_activity_options,
        };
    }

    return (
      <InputWrapper key={name} title={title} required={field.required} titleProps={titleProps} className="eff__field" invalid={invalid}>
        {instructionsId && <span className="sr-only" id={instructionsId}>{field.instructions}</span>}
        <Component {...inputProps} {...props} />
      </InputWrapper>
    )
  };

  render() {
    const { method, url, strings } = this.props;
    const { external_funding_form, form } = strings;
    const { attestation, pending, result, error, TermsOfUse } = this.state;
    const success = !pending && result && result.status === 200;
    const fields = Object.entries(external_funding_form.fields).map(this.make_field).filter(f => !!f);
    const firstFocusable = fields.findIndex(field => !field.props.disabled);
    if (-1 !== firstFocusable) fields[firstFocusable] = this.make_field(Object.entries(external_funding_form.fields)[firstFocusable], { innerRef: this.focusable.first });
    const half = Math.ceil(fields.length / 2);
    return <div className="external-funding-form">
      <form method={method} action={url} className="eff__form" onSubmit={this.onSubmit}>
        <div className="wysiwyg-content centered eff__instructions">
          <p>{form.required_instructions}</p>
        </div>
        <fieldset disabled={pending || success}>
          <div className="eff__fields" data-collapse={success}>
            <div className="eff__column">
              {fields.slice(0, half)}
            </div>
            <div className="eff__column">
              {fields.slice(half)}
            </div>
            <InputWrapper title={external_funding_form.fields.attestation.title} required={true}
              className="eff__field eff__field--full-width" titleProps={{ id: 'eff_field_attestation-title' }}
            >
              <Checkbox
                label={external_funding_form.fields.attestation.label}
                labelProps={{id: 'eff_field_attestation-label'}}
                name="attestation"
                aria-labelledby="eff_field_attestation-title"
                aria-describedby='eff_field_attestation-label'
                required={true}
                value={attestation}
                onChange={e => this.setState({ attestation: e.target.checked })}
              />
            </InputWrapper>
            <InputWrapper title={external_funding_form.fields.TermsOfUse.title} required={true}
              className="eff__field eff__field--full-width" titleProps={{ id: 'eff_field_tos-title' }}
            >
              <Checkbox
                label={external_funding_form.fields.TermsOfUse.label}
                labelProps={{id: 'eff_field_tos-label'}}
                name="TermsOfUse"
                required={true}
                aria-labelledby="eff_field_tos-title"
                aria-describedby='eff_field_tos-label'
                value={TermsOfUse}
                onChange={e => this.setState({ TermsOfUse: e.target.checked })}
              />
            </InputWrapper>
            <div className="eff__error-msg">
              {error}
            </div>
          </div>
        </fieldset>
        <div className="eff__submit-bar eff__field--full-width">
          <button data-pending={pending} data-success={!!success} className="eff__submit-btn btn btn-secondary" type="submit" disabled={!attestation || !TermsOfUse}>
            <div data-part="text">{form.components.submit}</div>
            <div data-part="pending"><Loading /></div>
            <div data-part="success">{form.components.success}</div>
          </button>
        </div>
      </form>
    </div>
  }
}

ExternalFundingFormBase.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  focusVisible: FocusVisiblePropType.isRequired,
};

export const ExternalFundingForm = withFocusVisible(ExternalFundingFormBase);

ExternalFundingForm.propTypes = {
  method: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
};

/*
`
Educational Grants
Grants to support an independent scientific, educational or professional educational program, meeting or event for Healthcare Professionals (HCPs) that is organized, controlled, and conducted by an independent third party, including but not limited to, accredited and non-accredited continuing medical education (CME and non-CME), disease awareness, or medical conferences.
Charitable Donations
Contributions to support various projects, initiatives or non-profit organizations with bona-fine charitable missions with an affinity to PTC’s mission in communities where PTC has a presence (note: can include both healthcare-related and non-healthcare related charitable contributions).
Academic Research / Public Policy
Grants to support research activities regarding healthcare public policies and legislature.
Fellowships / Scholarships
Grants to provide funding of an individual (e.g., medical, pharmacy and/or nursing students, residents or fellows) to defray or cover reasonable costs of attending accredited continuing education (CE) activities (e.g., educational program, symposia, or conference) for academic or career advancement.
Patient Advocacy / Consumer Education
Grants to Patient Associations / Organizations to increase disease-state education/awareness or to provide support during and after diagnosis and/or treatment without PTC receiving any tangible benefit, such as; an annual membership in a professional society, an opportunity to set up an exhibit, display table or booth at the event, seats at a gala or a PTC speaking opportunity.
`*/
