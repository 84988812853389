import React from "react";
import PropTypes from 'prop-types'

const TaxonomiesContext = React.createContext({});
TaxonomiesContext.displayName = 'Taxonomies';
const { Provider: TaxonomiesProvider, Consumer: TaxonomiesConsumer } = TaxonomiesContext;

// eslint-disable-next-line react/display-name
const withTaxonomies = Component => props => {
  const Wrapped = Taxonomies => <Component {...props} taxonomies={Taxonomies}/>;
  Wrapped.displayName = `withTaxonomies(${Component.displayName})`;
  return <TaxonomiesConsumer>{Wrapped}</TaxonomiesConsumer>;
};

/**
 * Proptypes for Taxonomies context
 */
const TaxonomiesProptype = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.objectOf(PropTypes.shape({
      rest_base: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      mg_names: PropTypes.oneOfType([
        PropTypes.shape({
          plural: PropTypes.string,
          singular: PropTypes.string,
          slug: PropTypes.string,
        }),
        PropTypes.bool
      ]),
      terms: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
      }))
    })),
  ])
)

export { TaxonomiesContext, TaxonomiesProvider, TaxonomiesProptype, withTaxonomies, TaxonomiesConsumer };
