/**
 * Callout block
 *
 * @package frontend-ui
 */

/**
 * Callout
 *
 * @package frontend-ui
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import * as Icons from '../../Svg'
import { isEmpty, camelCase, upperFirst } from 'lodash';
import ParsedWysiwyg from '../../Common/ParsedWysiwyg';

/**
 * React Component Callout.
 */
const Callout = forwardRef((props, ref) => {
  const {
    className,
    icon,
    title,
    title_level,
    content,
  } = props;

  let Icon;

  if (!isEmpty(icon)) {
    const componentName = upperFirst(camelCase(icon.ID.substr(1 + icon.ID.indexOf('-'))));
    // eslint-disable-next-line import/namespace
    Icon = Icons[componentName];
  }

  const TitleElement = title_level ? `h${title_level}` : 'p';

  return <div ref={ref} className={classNames('callout-1__inner centered', className)}>
    {(Icon || title) &&
      <div className="callout-1__header d-flex align-items-center">
        {Icon && <Icon aria-hodden="true" className='callout-1__icon' />}
        {title && <TitleElement className="callout-1__title">{title}</TitleElement>}
      </div>
    }
    <div className="callout-1__content wysiwyg-content"><ParsedWysiwyg htmlString={content} /></div>
  </div>
})

Callout.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  title_level: PropTypes.number,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
}

Callout.displayName = 'Callout'

export default Callout;