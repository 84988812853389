import React from "react";
import PropTypes from "prop-types";
import ToggleContent from "./ToggleContent";

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {};
  }

  toggle(index) {
    this.setState({ collapse: this.state.collapse === index ? null : index });
  }
  render() {
    const accordion = this.props.accordion;
    return (
      <div className='accordion__content centered'>
        <h2 className='accordion__title section-title'>
            {this.props.section_title}
        </h2>
        {accordion.map((content, index) =>
          <ToggleContent
            key={index}
            contentId={this.props.contentId}
            index={index}
            isOpen={this.state.collapse === index}
            toggle={this.toggle} {...content} />
        )}
      </div>
    );
  }
}

Accordion.propTypes = {
  accordion: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  contentId: PropTypes.string,
  section_title: PropTypes.string
};

Accordion.defaultProps = {
  accordion: [],
  contentId: '',
  section_title: ""
};

export default Accordion;
