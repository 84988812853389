// Set up your application entry point here...
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import moment from 'moment';
import Moment from 'react-moment';

import * as Sentry from '@sentry/browser';

if (process.env.sentryEnabled) {
  Sentry.init({
    dsn: process.env.sentryDSN,
    environment: process.env.sentryEnvironment,
    release: process.env.sentryRelease
  });
}

/*
 * Configure moment.js
 */

// https://momentjs.com/docs/#/i18n/changing-locale/
moment.locale(process.env.DEFAULT_LANGCODE);

// https://github.com/headzoo/react-moment#global-config
Moment.globalMoment = moment

ReactDOM.render(<App />, document.getElementById('app'));
