import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import getPath from '../../api/relativePath';
import { Link } from 'react-router-dom';
import { parseCookieCategoryClass, useCookieCategories } from '../../utils/cookies';
import { StringsContext } from '../Contexts/StringsContext';
import Linkify from 'linkifyjs/react';
import * as linkifyjs from 'linkifyjs';
import hashtag from 'linkifyjs/plugins/hashtag';
import LinkSr from './LinkSr';
import classNames from 'classnames';
import ScriptTag from './ScriptTag';
import { difference } from 'lodash';
import { isTrustedEmbed } from '../../utils/misc';

hashtag(linkifyjs);

const ParsedWysiwyg = ({ className, htmlString, linkify, preprocessNode }) => {
  if (!htmlString) {
    return null;
  }

  const cookieCats = useCookieCategories();
  const strings = useContext(StringsContext);
  const { cookies: { embed_disabled } } = strings;

  let html = ReactHtmlParser(htmlString, {
    transform: (node, index) => {
      if (preprocessNode) node = preprocessNode(node, index);

      // Intercept embeds for trusted source
      if ('script' === node.type && 'script' === node.name) {
        if (!isTrustedEmbed(node.attribs.src, strings.embed?.trusted_sources ?? [])) return null
      }

      // Early intercept embeds for cookie compliance.
      if (
        ('tag' === node.type && 'iframe' === node.name) ||
        ('script' === node.type && 'script' === node.name)
      ) {
        const { attribs } = node;
        const { 'class': className } = attribs;

        const nodeCookieCats = parseCookieCategoryClass(className);
        if (nodeCookieCats) {
          // If element has unaccepted cookie categories.
          if (difference(nodeCookieCats, cookieCats).length) {
            return embed_disabled && <span key={index} className="embed-disabled">{ReactHtmlParser(embed_disabled)}</span>
          }
        }
      }

      if ('tag' === node.type) {
        if ('a' === node.name) {
          const { attribs: { href } } = node;

          let pathinfo = getPath(href);

          // Attempt to fix URLs without protocol by assuming http protocol
          if (undefined === pathinfo && !/^https?:\/\//i.test(href)) {
            pathinfo = getPath(`http://${href}`)
          }

          // We were able to parse the path, proceed to modify the node
          if (undefined !== pathinfo) {
            const { external, path } = pathinfo;

            node.attribs.href = path; // Use corrected path

            let element = convertNodeToElement(node, index);

            const children = React.Children.toArray([
              ...element.props.children,
              React.createElement(
                LinkSr,
                { external, target: node.attribs.target }
              )
            ])

            // Convert internal, non-fragment anchor tags to Router Links
            if (!external && path.charAt(0) !== '#') {
              return <Link {...element.props} href={undefined} to={path} key={index}>{children}</Link>
            }

            return React.cloneElement(
              element,
              {},
              children
            )
          }
        } else if ('iframe' === node.name) {
          const dataSrc = node.attribs?.['data-src']
          if (dataSrc) node.attribs.src = dataSrc;
          return convertNodeToElement(node, index)
        }
      } else if ('script' === node.type) {
        if ('script' === node.name) {
          return <ScriptTag key={index} {...node.attribs} />
        }
      }
    }
  });

  // Used to turn plain text into operable links
  if (linkify) {
    const linkifyOptions = {
      formatHref: function (href, type) {
        if (type === 'hashtag') {
          href = 'https://twitter.com/hashtag/' + href.substring(1);
        }
        return href;
      },
      attributes: {
        rel: 'noopener noreferrer',
        target: '_blank'
      }
    }

    html = <Linkify options={linkifyOptions}>{html}</Linkify>
  }

  return (
    <span className={classNames("rich-text", className)}>{html}</span>
  )
};

ParsedWysiwyg.propTypes = {
  htmlString: PropTypes.string,
  linkify: PropTypes.bool,
  preprocessNode: PropTypes.func,
  className: PropTypes.string,
};

ParsedWysiwyg.defaultProps = {
  htmlString: '',
  linkify: false
};

export default ParsedWysiwyg;
