import { COOKIE_CATEGORIES } from "./misc";
import { debounce, trim } from "lodash";
import { useState, useEffect } from "react";

/**
 * Get the `src` to use for an embed that depends on active cookie categories.
 *
 * @param {string} src URL of the embed.
 * @param {string} category ID of cookie category that must be active to enable this embed.
 * @param {string[]} categories Active categories.
 *
 * @returns {undefined|string} Undefined if you shouldn't embed, URL for `src` if you should.
 */
export const getSrc = (src, category, categories) => {
  // Bail if no `src`.
  if (!src) return undefined;

  if (!(categories && categories.includes(category))) {
    return undefined;
  } else {
    return src;
  }
}

/**
 * Hook providing active CookiePro cookie categories.
 *
 * @returns {undefined|string[]} Active cookie category IDs.
 */
export const useCookieCategories = () => {
  const [categories, setCategories] = useState(window.OnetrustActiveGroups ?
    window.OnetrustActiveGroups.replace(/^,+|,+$/g, '').split(',') :
    undefined
  );

  useEffect(() => {
    const handler = [
      'consent.onetrust',
      handleCategoriesUpdated
    ];

    function handleCategoriesUpdated() {
      setCategories(window.OnetrustActiveGroups.replace(/^,+|,+$/g, '').split(','))
    }

    window.addEventListener(...handler);

    return () => window.removeEventListener(...handler);
  }, [])

  return categories;
}

/**
 * Parse CookiePro category IDs from classname.
 *
 * @see https://community.cookiepro.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca CookiePro docs on class-based blocking
 *
 * @param {String} className The classname.
 * @returns {String[]}
 */
export function parseCookieCategoryClass(className) {
  if ('string' !== typeof className) return

  let cookieClasses = className && className.matchAll(/optanon-category((?:-\w+)+)/g);
  if (!cookieClasses) return

  let nodeCookieCats = new Set([...cookieClasses].map(([, nodeCookieClass]) => trim(nodeCookieClass, '-').split('-')).flat())
  return Array.from(nodeCookieCats)
}

/**
 * Hook providing the `src` to use for an embed that depends on active cookie categories.
 *
 * @param {string} src URL of the embed.
 * @param {string} category ID of cookie category that must be active to enable this embed.
 *
 * @returns {undefined|string} Undefined if you shouldn't embed, URL for `src` if you should.
 */
export const useSrc = (src, category) => {
  const categories = useCookieCategories();
  return getSrc(src, category, categories);
}

/**
 * Object with static, aptly named event handlers and iframe loaders for each cookie category,
 * so we can remove listeners and debounce loaders.
 *
 * @deprecated Currently using components and custom hooks to update the source, keeping code in case we need to revert
 * to document manipulation.
 */
const HELPERS = Object.values(COOKIE_CATEGORIES).reduce(
  (o, category) => {
    o[`handle${category}`] = () => {
      window.removeEventListener('OneTrustGroupsUpdated', HELPERS[`handle${category}`]);
      HELPERS[`load${category}`]();
    };
    o[`load${category}`] = debounce(() => loadIframes(category), 250);

    return o;
  }, {})

/**
 * Enable iFrames belonging to a given cookie category if that category is active.
 *
 * @deprecated Currently using components and custom hooks to update the source, keeping code in case we need to revert
 * to document manipulation.
 *
 * Implements functionality described in CookiePro's
 * {@link https://community.cookiepro.com/s/article/UUID-e7fecfdc-7be1-dc9d-a8f7-f7318e746d15 Blocking Iframe Tags}
 * article.
 *
 * @param {string} category ID of cookie category to load, e.g. `C0001`.
 */
export const initIframes = category => {
  if (!window.OptanonActiveGroups) {
    window.addEventListener('OneTrustGroupsUpdated', HELPERS[`handle${category}`])
  } else {
    HELPERS[`load${category}`]()
  }
}

/**
 * Enable iFrames belonging to a given cookie category if that category is active.
 *
 * @deprecated Currently using components and custom hooks to update the source, keeping code in case we need to revert
 * to document manipulation.
 *
 * @param {string} category  ID of cookie category to load, e.g. `C0001`.
 *
 * @returns {Node[]|boolean} False if group is inactive, otherwise array of enabled iFrames.
 */
const loadIframes = category => {
  const activeGroups = window.OptanonActiveGroups && window.OptanonActiveGroups.replace(/^,+|,+$/, '').split(',');

  const iframes = document.querySelectorAll(`iframe[class*="optanon-category-${category}"]`);
  if (!iframes.length) return [];

  return Array.from(iframes).filter(iframe => {
    const src = getSrc(iframe.dataset.src, category, activeGroups)

    if (src) {
      iframe.setAttribute('src', src);
      return true;
    } else {
      return false;
    }
  })
}