/**
 * Script
 *
 * @package ptc-therapeutic
 */
import React from 'react';
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet';

/**
 * Render a `<script>` tag.
 */
function ScriptTag(props) {
  return <Helmet><script {...props}></script></Helmet>
}

ScriptTag.propTypes = {
  src: PropTypes.string
}

export default ScriptTag;